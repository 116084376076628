import config from './config.json';

const appConfig = {
  title: '',
  apiEndpoint: config.api.baseUrl,
  links: config.links,
  googleClientId: config.googleClientId,
};

export default appConfig;
