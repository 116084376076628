export const GlobalDebug = (function () {
  const savedConsole = console;
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function (debugOn?: boolean, suppressAll?: boolean) {
    const suppress = suppressAll || false;
    /* eslint-disable  no-global-assign */
    console = console as Console;
    if (debugOn === false) {
      // supress the default console functionality

      console.log = function () {};
      // supress all type of consoles
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();

export const validInRange = (x: number, min: number, max: number) => {
  return x >= min && x <= max;
};
