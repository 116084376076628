/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { Select } from '../../components/Select';
import { DateRangePicker } from '../../components/DateRangePicker';
import { FORMAT_DATE_FILTER, FORMAT_DATE_FILTER_START } from '../../constants';
import { IFilterNFT, NFTStatus } from '../../types/MerchantNFT';
import _ from 'lodash';
import TabItem from '../../components/TabItem';
import MerchantNFTService from '../../services/MerchantNFTService';
import { Trans, t } from '@lingui/macro';
import ReactSelect from 'react-select';
import TransString, { StringTypes } from '../../components/TransString';
import MerchantDistributorService from '../../services/MerchantDistributorService';
import { Alert, ellipseAddress } from '../../helpers';
import NftOverview from '../../components/nft/NftOverview';
import { NftList } from '../../components/nft/NftList';
import { useQuery } from '@tanstack/react-query';
import { FetchStateComponent } from '../../components/FetchStateComponent';
import { DateRange } from '../../components/Date/DateRangePicker';
import { formatDateRangeFilter } from '../../helpers/UtilsHelpers';
const PageNfts: React.FC = () => {
  const [filter, setFilter] = useState<IFilterNFT>({
    fromDate: moment().subtract(6, 'days').startOf('day').format(FORMAT_DATE_FILTER_START),
    toDate: moment().endOf('day').format(FORMAT_DATE_FILTER),
  });
  const numOfDay = 7;

  const handleChange = (params: IFilterNFT) => {
    const p = params;
    if (!params.page) {
      p.page = 1;
    }
    setFilter((prev) => ({
      ...prev,
      ...p,
    }));
  };
  const handleChangeDate = (dateRange: DateRange) => {
    setFilter({ ...filter, page: 1, ...formatDateRangeFilter(dateRange) });
  };
  // const handleChangeDate = (params: { startDate?: Date; endDate?: Date; key: string; active: string }) => {
  //   setFilter((prev) => ({
  //     ...prev,
  //     fromDate: params.active === 'all' ? undefined : moment(params.startDate).format(FORMAT_DATE_FILTER_START),
  //     toDate: params.active === 'all' ? undefined : moment(params.endDate).format(FORMAT_DATE_FILTER),
  //     page: 1,
  //   }));
  // };

  const onChangeValue = (e: any) => {
    handleChange({ tokenId: e.target.value, page: 1 });
  };
  const debounceFn = useMemo(() => _.debounce(onChangeValue, 1000), []);

  const exportExcel = async () => {
    try {
      await MerchantNFTService.exportExcel(filter);
    } catch (e: any) {
      Alert.error(t`Your download priority is currently limited within ${numOfDay} days`);
    }
  };
  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['list-nfts'],
    queryFn: async () => {
      const response = await MerchantNFTService.getIssuedNFTs(filter);
      return response;
    },
  });
  useEffect(() => {
    refetch();
  }, [filter]);

  return (
    <section>
      <NftOverview chainCode={filter.chainCode} handleChange={handleChange} />
      <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
        <NftList data={data} handleChange={handleChange}>
          <div className="row align-items-end mb-7">
            <div className="col">
              <label className="form-label">
                <Trans>Status</Trans>
              </label>
              <StatusSelect handleChange={handleChange} status={filter.status || ''} />
            </div>
            <div className="col-3">
              <label className="form-label">
                <Trans>NFT Recipient address</Trans>
              </label>
              <RecipientSelect chainCode={filter.chainCode} handleChange={handleChange} />
            </div>
            <div className="col">
              <label className="form-label">
                <Trans>NFT code</Trans>
              </label>
              <input className="form-control" onChange={debounceFn} />
            </div>
            <div className="col-auto">
              <DateRangePicker
                onChangeDate={handleChangeDate}
                extraActions={
                  <div onClick={exportExcel}>
                    <TabItem label={<i className="fas fa-download text-dark" />} onClick={() => {}} />
                  </div>
                }
              />
            </div>
          </div>
        </NftList>
      </FetchStateComponent>
    </section>
  );
};

export default PageNfts;

const StatusSelect = ({ status, handleChange }: { status: string; handleChange: any }) => {
  const filterStatus = Object.keys(NFTStatus).map((value, j) => ({
    label: <TransString key={j} value={value} type={StringTypes.NFT_STATUS} />,
    value: value === 'ALL' ? '' : value,
  }));

  return <Select options={filterStatus} name={status} onChange={(value) => handleChange({ status: value, page: 1 })} />;
};

const RecipientSelect = ({ chainCode, handleChange }: { chainCode?: string; handleChange?: any }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ label: string; image: string; value: string }[]>([]);

  useEffect(() => {
    getData();
  }, [chainCode]);

  const getData = async () => {
    setLoading(true);
    setData([]);
    const res = await MerchantDistributorService.getDistributorsByChainCode({
      chainCode: chainCode || '',
    });
    if (res) {
      const collectedData: any = [];
      res.forEach((item) => {
        item.addresses.forEach((address) => {
          collectedData.push({
            name: item.name,
            address: address.address,
            chainCode: address.chain.code,
          });
        });
      });
      setData(
        collectedData.map((item: any) => ({
          label: `${item.name}|${item.address}`,
          image: `img/${item.chainCode.toLowerCase()}.svg`,
          value: `${item.address}|${item.chainCode}`,
        }))
      );
      setLoading(false);
    }
  };

  return (
    <ReactSelect
      options={data}
      isLoading={loading}
      isClearable
      placeholder={<Trans>Input address</Trans>}
      onChange={(item) => {
        const [, address] = item ? item.label.split('|') : [null, null];
        const [, chainCode] = item ? item.value.split('|') : [null, null];
        handleChange({ distributorAddress: address, chain_Code: chainCode, page: 1 });
      }}
      formatOptionLabel={(option: any) => {
        const [name, address] = option.label.split('|');
        return (
          <div className="d-flex gap-3 align-items-center ">
            <img src={option.image} height={20} alt={name} />
            <span>
              {name} - {ellipseAddress(address)}
            </span>
          </div>
        );
      }}
    />
  );
};
