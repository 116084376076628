export const LoadingPending = () => {
  return (
    <div className="card card-flush  h-xl-100 pt-5" aria-hidden="true">
      <div className="content image placeholder"></div>
      <div className="card-body mt-4">
        <h5 className="card-title placeholder-glow">
          <span className="placeholder col-6"></span>
        </h5>
        <p className="card-text placeholder-glow">
          <span className="placeholder col-7"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-6"></span>
          <span className="placeholder col-8"></span>
        </p>
        <a href="#" className="btn btn-primary disabled placeholder col-6"></a>
      </div>
    </div>
  );
};
