import { useEffect, useState } from 'react';
import { formatCurrency, getExplorerLink } from '../../helpers';

import { CurrencySymbols } from '../../types/CurrencyBalance';
import { getCashFlowDirections } from '../../types/Transaction';

import { Address } from '../token/Address';
import LogEvent from './LogEvent';
import MerchantService from '../../services/MerchantService';
import { MerchantType, MertchantTx } from '../../types/Merchant';
import { Loading } from '../common/Loading';
import { Trans } from '@lingui/macro';
import { DetailTabs, Tabs } from './DetailTabs';

import { useQuery } from 'react-query';
import { FetchStateComponent } from '../FetchStateComponent';
import { useAuth } from '../../store/auth';
import { InforDetail } from './InforDetail';

const DepositDetails = ({ transactionId }: { transactionId: string }) => {
  const [currentTab, setCurrentTab] = useState(Tabs.DETAILS.toString());
  const [details, setDetails] = useState<MertchantTx>();

  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['merchant-transactions-details'],
    queryFn: () => MerchantService.getTxDetails(transactionId),
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setDetails(data);
  }, [data]);

  if (isLoading || !details) {
    return (
      <div className="modal-body ">
        <div style={{ background: '#F4F5F7' }} className="p-5 rounded-4 mb-5">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="modal-body ">
      <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
        <InforDetail details={details} />

        <DetailTabs {...{ currentTab, setCurrentTab }} />

        {currentTab === Tabs.DETAILS.toString() && (
          <div>
            <Details details={details} />
            <CurrencyDetails details={details} />
          </div>
        )}

        {currentTab === Tabs.LOG.toString() && <LogEvent details={details} />}
      </FetchStateComponent>
    </div>
  );
};

export default DepositDetails;

const Details = ({ details }: { details: MertchantTx }) => {
  return (
    <div className="p-5 border rounded-4 mb-3">
      <div className="row mb-3">
        <div className="col fw-bold ">
          <Trans>Partner information</Trans>
        </div>
        <div className="col d-flex justify-content-end "></div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <Trans>Reference code</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <Address address={details.partnerInfo.paymentId} />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          {/* Ví thanh toán
        tieng anh : Payment wallet
        */}
          <Trans>Payment wallet</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <Address
            address={details.partnerInfo.senderAddress}
            link={getExplorerLink(details.chainCode, details.partnerInfo.senderAddress, 'address')}
          />
        </div>
      </div>
    </div>
  );
};

const CurrencyDetails = ({ details }: { details: MertchantTx }) => {
  const { currentUser } = useAuth();
  return (
    <div className="p-5 border rounded-4 mb-3">
      <div className="row mb-3">
        <div className="col fw-bold ">
          {/* 
          Chi tiết dòng tiền
          // tieng anh : Money flow details
          */}

          <Trans>Money flow details</Trans>
        </div>
        <div className="col d-flex justify-content-end "></div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <Trans>Transaction value</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.txCashFlowInfo.txValue.value, details.txCashFlowInfo.txValue.currency)}
        </div>
      </div>

      {details.txCashFlowInfo.txValue.currency === 'USDT' && currentUser?.type === MerchantType.VIC_BASED && (
        <div className="row mb-3">
          <div className="col">
            {/* Quy đổi theo VIC 
          // tieng anh: Converted to VIC
          */}
            <Trans>Converted to VIC</Trans>
          </div>
          <div className="col d-flex justify-content-end ">
            {formatCurrency(details.txCashFlowInfo.amountInBaseToken, CurrencySymbols.VIC)}
          </div>
        </div>
      )}

      <div className="row mb-3">
        {/* Phí dịch vụ
      tieng anh : Service fee
      */}
        <div className="col">
          <Trans>Service fee</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.txCashFlowInfo.serviceCostInBaseToken, details.baseToken)}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <Trans>Gas fee</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.txCashFlowInfo.gasCostInBaseToken, details.baseToken)}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {/* Biến động số dư
        // tieng anh: Balance change
        */}
          <Trans>Balance change</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {getCashFlowDirections(details.txCashFlowInfo.cashFlowDirection)}{' '}
          {formatCurrency(details.txCashFlowInfo.actualTxAmountAfterCostInBaseToken, details.baseToken)}
        </div>
      </div>
    </div>
  );
};
