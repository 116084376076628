interface Props {
  message: string;
}

const ErrorMessage: React.FC<Props> = ({ message }) => {
  return (
    <div className="d-flex mb-4">
      <img src="/img/red-circle-xmark.svg" alt="" />
      <span className="ms-4 text-danger">{message}</span>
    </div>
  );
};

export default ErrorMessage;
