import { useEffect, useState } from 'react';
import { Pagination } from '@cxptek/core';
import { useQuery } from '@tanstack/react-query';
import { Trans, t } from '@lingui/macro';
import DateTime from '../common/DateTime';
import { FetchStateComponent } from '../FetchStateComponent';
import DailySnapshotService from '../../services/DailySnapshotService';
import { ISnapshot } from '../../types/Snapshot';
import ReconciliationFilter from './ReconciliationFilter';
import { DateRange } from '../Date/DateRangePicker';
import { formatDateRangeFilter } from '../../helpers/UtilsHelpers';
import { IFilterTransaction } from '../../store/transaction/types';
import Button from '../common/Button';
import { currencyFormat } from '../../helpers/FormatHelpers';
import WithTooltip from '../WithTooltip';
import ReconciliationDetail from './ReconciliationDetail';
import ButtonDowload from './ButtonDowload';
import { NoData } from '../NoData';

const DailySnapshotList = () => {
  const [filter, setFilter] = useState<IFilterTransaction>({
    size: 10,
    page: 1,
    sortBy: 'createdAt',
    sortDirection: 'DESC',
  });
  const [detailsDisplayed, showDetails] = useState(false);
  const [detailsId, setDetailId] = useState('');

  const handleChangePage = (page: number) => {
    setFilter({ ...filter, page });
  };
  const handleChangeDate = (dateRange: DateRange) => {
    setFilter({ ...filter, page: 1, ...formatDateRangeFilter(dateRange) });
  };
  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['list-daily-snapshot'],
    queryFn: async () => {
      const response = await new DailySnapshotService().getAll(filter);
      return response.data;
    },
  });

  useEffect(() => {
    refetch();
  }, [filter]);

  const SnapshotStatusNames: any = {
    PENDING: t`Unverified`, //Chưa xác thực
    VERIFIED: t`Verified`, //Đã xác thực
  };

  return (
    <>
      <ReconciliationFilter onSelect={handleChangeDate} isAlignRight />
      <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>
                <Trans>Reconciliation record</Trans>
              </th>
              <th>
                <Trans>Created date</Trans>
              </th>

              <th className="text-end">
                <Trans>Total transaction</Trans>
              </th>
              <th className="text-end">
                <Trans>Status</Trans>
              </th>
              <th className="text-end">
                <Trans>Actions</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {!data || !data.items.length ? (
              <tr>
                <td colSpan={5}>
                  <NoData />
                </td>
              </tr>
            ) : (
              data.items?.map((item: ISnapshot) => {
                return (
                  <>
                    <tr>
                      <td className="mw-400px ">
                        <WithTooltip id="view" tooltip={item.name} className=" d-inline-block  text-truncate mw-400px ">
                          {item.name}
                        </WithTooltip>
                      </td>
                      <td>
                        <DateTime value={item.reportedAt} />
                      </td>
                      <td className="text-end">{currencyFormat(item.totalTransaction)}</td>
                      <td className="text-end">
                        {item.verifyStatus === 'PENDING' && (
                          <span className="badge badge-light-warning text-warning">
                            {SnapshotStatusNames[item.verifyStatus]}
                          </span>
                        )}
                        {item.verifyStatus === 'VERIFIED' && (
                          <span className="badge badge-light-success text-success">
                            {SnapshotStatusNames[item.verifyStatus]}
                          </span>
                        )}
                      </td>
                      <td className="text-end">
                        {item.exportUrl ? (
                          <ButtonDowload item={item} />
                        ) : (
                          <WithTooltip id="download" tooltip={<Trans>File Not available</Trans>}>
                            <ButtonDowload item={item} />
                          </WithTooltip>
                        )}

                        {detailsDisplayed && detailsId === item.id ? (
                          <Button
                            backgroundColor="light"
                            className="btn btn-light btn-icon  me-3"
                            data-repeater-delete
                            onClick={() => {
                              showDetails(false);
                              setDetailId(item.id);
                            }}
                          >
                            <i className="la la-angle-up fs-3"></i>
                          </Button>
                        ) : (
                          <Button
                            backgroundColor="light"
                            className="btn btn-light btn-icon text-white"
                            onClick={() => {
                              showDetails(true);
                              setDetailId(item.id);
                            }}
                          >
                            <i className="la la-angle-down fs-3"></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                    {detailsDisplayed && item.id === detailsId && (
                      <tr className="border border-1" style={{ background: '#e7eaeb' }}>
                        <td colSpan={6}>
                          <ReconciliationDetail loadData={refetch} snapshot={item} />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            )}
          </tbody>
        </table>
      </FetchStateComponent>

      {data?.totalPages > 1 && (
        <Pagination
          totalPages={data.totalPages ?? 0}
          currentPage={data.page === 0 ? 1 : data.page}
          onChangePage={handleChangePage}
        />
      )}
    </>
  );
};
export default DailySnapshotList;
