import { RootState } from '../setup';
import { getChainsSelector } from '../store/chain/selectors';
import { getCurrenciesSelector } from '../store/currency/selectors';
import { getTokensSelector } from '../store/token/selectors';
import { IChain, Currency, TokenModel, ITokenChain } from '../types';
// import * as Store from '../store/Store';

export function getExplorerLink(chainId: number | string | undefined, data: string, type: string): string {
  if (!chainId) {
    return '';
  }
  const chain = getChain(chainId);
  if (!chain) {
    return '';
  }
  const res = getExplorerUrl(chain.explorer, type, data, chain.code);
  return res;
}

export function getExplorerUrl(explorer: string, type: string, data: string, chainName?: string): string {
  let out = '';
  switch (type) {
    case 'transaction':
      out = chainName === 'TRON' ? `${explorer}/#/transaction/${data}` : `${explorer}/tx/${data}`;
      break;
    case 'block':
      out = `${explorer}/block/${data}`;
      break;
    case 'token':
      out = `${explorer}/token/${data}`;
      break;
    case 'address':
      out = `${explorer}/address/${data}`;
      break;
    default:
      break;
  }
  return out;
}

export function getChain(chainId: number | string): IChain | ITokenChain | undefined | null {
  const store = getReduxState();
  const chains = getChainsSelector(store);
  if (!chains.length) {
    return null;
  }
  return chains.find((item) => (typeof chainId === 'number' ? item.id === chainId : item.code === chainId));
}
const getChainImage = (chainId: number) => {
  let imageName = '';
  switch (chainId) {
    case 97:
    case 56:
      imageName = 'binance.svg';
      break;
    case 80001:
    case 137:
      imageName = 'polygon.svg';
      break;
    case 728126428:
    case 3448148188:
      imageName = 'tron.svg';
      break;
    case 14000:
    case 16500:
      imageName = 'vchain.svg';
      break;
    default:
      imageName = `${chainId}.svg`;
      break;
  }
  return `${window.location.origin}/img/${imageName}`;
};
export function getTokenByChain(chainId: number | undefined): TokenModel | undefined {
  if (!chainId) {
    return undefined;
  }
  const store = getReduxState();
  const tokens = getTokensSelector(store);
  if (!tokens.length) {
    return undefined;
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const token: any = tokens.filter((item: { chainId: number }) => item.chainId === chainId);
  // console.log("getTokenByChain",token)
  return token;
}

export function getToken(chainId: number | undefined, address: string): TokenModel | undefined {
  if (!chainId || !address) {
    return undefined;
  }
  const store = getReduxState();
  const tokens = getTokensSelector(store);
  if (!tokens.length) {
    return undefined;
  }
  // console.log("getToken",address,tokens)
  const token = tokens.find((item: { chainId: number; address: string }) => {
    if (item.chainId === chainId && item.address.toLowerCase() === address.toLocaleLowerCase()) {
      return item;
    } else {
      // console.log('test token find', item.chainId, chainId, item.address.toLowerCase(), address.toLocaleLowerCase());
      return false;
    }
  });

  return token;
}

export function getCurrency(currencyId: string): Currency | undefined {
  if (!currencyId) {
    return undefined;
  }
  const store = getReduxState();
  const items = getCurrenciesSelector(store);
  if (!items.length) {
    return undefined;
  }
  const res = items.find((item: { id: string }) => {
    if (item.id === currencyId) {
      return item;
    } else {
      // console.log('test token find', item.chainId, chainId, item.address.toLowerCase(), address.toLocaleLowerCase());
      return false;
    }
  });

  return res;
}

// export const getReduxState: () => RootState = () => Store.default.getState();
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const getReduxState: () => RootState = () => require('../store/Store').default.getState();

const BlockchainHelper = { getChainImage };

export default BlockchainHelper;
