import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Routes } from 'react-router-dom';
import * as auth from '../../store/auth/AuthRedux';

export function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(auth.actions.logout());
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    document.location.reload();
  }, [dispatch]);

  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  );
}
