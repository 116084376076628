import * as Yup from 'yup';
import { t } from '@lingui/macro';
export const YupI18n = function () {
  Yup.setLocale({
    mixed: {
      default: t`Data is not valid`,
      required: () => {
        // return intl.formatMessage(
        //   { id: 'FORM.validation.required' },
        //   { field: intl.formatMessage({ id: `FORM.${props.path}` }) }
        // );
        // console.log('props', props);
        return t`This field is required.`;
      },
    },
    string: {
      min: ({ min }) => t`Minimum ${min} characters`,
      max: ({ max }) => t`Maximum ${max} characters`,
      length: ({ length }) => t`Request input ${length} characters`,
      email: t`Invalid email format`,
    },
    number: {
      min: ({ min }) => t`Number must be greater than ${min}`,
      max: ({ max }) => t`Number must be less than ${max}`,
      moreThan: ({ more }) => t`Number must be greater than ${more}`,
      lessThan: ({ less }) => t`Number must be less than ${less}`,
    },
  });
};
