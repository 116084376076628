import { AiOutlineExclamationCircle } from 'react-icons/ai';
import WithTooltip from './WithTooltip';
import { LoadingIcon } from './common/LoadingIcon';
// import { Trans } from '@lingui/macro';

const CardDashboard = ({
  title,
  number = -1,
  total,

  tooltip,
}: {
  title: React.ReactNode;
  number: number | string;
  total: string;

  tooltip?: string;
}) => {
  return (
    <div className="card card-body">
      <div className="d-flex align-items-center  gap-2 mb-3">
        {title}

        {tooltip && (
          <WithTooltip id="tooltip-2" tooltip={tooltip}>
            <AiOutlineExclamationCircle />
          </WithTooltip>
        )}
      </div>
      <div className="fw-bold" style={{ fontSize: 25 }}>
        {number == -1 ? <LoadingIcon fontSize={15} zoom={1.4} /> : number}
      </div>
      <div className="">
        {/* <span className="me-2">
          <Trans>Value</Trans>:
        </span> */}
        ~{total}
      </div>
    </div>
  );
};
export default CardDashboard;
