import clsx from 'clsx';
import { Modal } from 'react-bootstrap';
import Button from '../../components/common/Button';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

export default function NftPendingModal({ show, onHide }: { show: boolean; onHide: () => void }) {
  return (
    <Modal
      className={clsx('modal-sticky modal-sticky-lg modal-sticky-bottom-right')}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-md"
      onHide={onHide}
    >
      <div className="modal-body">
        <div className="position-relative w-100 mb-5">
          <h2>
            <Trans>Issue NFT</Trans>
          </h2>
        </div>

        <div className="row mb-5">
          <img src="/img/tx-loader.svg" className="w-50 mx-auto py-10" />
          <div className="fs-3 text-center mb-5">
            <Trans>
              {/* Giao dịch của bạn đang được xử lý. */}
              Your transaction is being processed
              <br />
              {/* Quá trình này có thể mất chút thời gian */}
              This may take some time.
            </Trans>
          </div>

          <div className="fs-3 text-center fw-bold ">
            <Trans>Track the status in</Trans>{' '}
            <Link to="/nft-history" className="text-primary">
              <Trans>Issue NFT</Trans>
            </Link>
          </div>
        </div>
      </div>

      <div className="modal-footer py-2">
        <Button
          textColor="white"
          isHover
          backgroundColor="primary"
          className="btn-outline-primary user-select-none py-3 rounded fw-semibold"
          onClick={onHide}
        >
          <Trans>Close</Trans>
        </Button>
      </div>
    </Modal>
  );
}
