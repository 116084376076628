import React, { useEffect, useState } from 'react';
import { IFilterTransaction } from '../../store/transaction/types';

import moment from 'moment';

import { Select } from '../../components/Select';
import { DateRangePicker } from '../../components/DateRangePicker';
import { FORMAT_DATE_FILTER, FORMAT_DATE_FILTER_START } from '../../constants';
import { NFTTxStatus } from '../../types/Transaction';
import { Trans } from '@lingui/macro';
import TransString from '../../components/TransString';
import { NftTransactions } from '../../components/nft/NftTransactions';
import MerchantNFTService from '../../services/MerchantNFTService';
import { useQuery } from '@tanstack/react-query';
import { FetchStateComponent } from '../../components/FetchStateComponent';
import { DateRange } from '../../components/Date/DateRangePicker';
import { formatDateRangeFilter } from '../../helpers/UtilsHelpers';
const PageNFTHistory: React.FC = () => {
  const [filter, setFilter] = useState<IFilterTransaction>({
    fromDate: moment().subtract(6, 'days').startOf('day').format(FORMAT_DATE_FILTER_START),
    toDate: moment().endOf('day').format(FORMAT_DATE_FILTER),
    page: 1,
  });

  const handleChange = (params: IFilterTransaction) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  const filterStatus = Object.keys(NFTTxStatus).map((key) => ({
    label: <TransString value={key} />,
    value: key === 'ALL' ? '' : key,
  }));

  const handleChangeDate = (dateRange: DateRange) => {
    setFilter({ ...filter, page: 1, ...formatDateRangeFilter(dateRange) });
  };
  // const handleChangeDate = (params: { startDate?: Date; endDate?: Date; key: string; active: string }) => {
  //   setFilter((prev) => ({
  //     ...prev,
  //     fromDate: params.active === 'all' ? undefined : moment(params.startDate).format(FORMAT_DATE_FILTER_START),
  //     toDate: params.active === 'all' ? undefined : moment(params.endDate).format(FORMAT_DATE_FILTER),
  //     page: 1,
  //   }));
  // };
  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['list-issues-history'],
    queryFn: async () => {
      const response = await MerchantNFTService.getIssueHistory(filter);
      return response;
    },
  });
  useEffect(() => {
    refetch();
  }, [filter]);

  return (
    <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
      <NftTransactions transactions={data} handleChange={handleChange}>
        <div className="row align-items-end mb-7">
          <div className="col-auto">
            <label className="form-label">
              <Trans>Transaction status</Trans>
            </label>
            <Select
              options={filterStatus}
              name={filter.status || ''}
              onChange={(value) => handleChange({ status: value, page: 1 })}
            />
          </div>
          <div className="col text-end">
            <div className="d-flex gap-3 align-items-center justify-content-end">
              <DateRangePicker onChangeDate={handleChangeDate} />
            </div>
          </div>
        </div>
      </NftTransactions>
    </FetchStateComponent>
  );
};

export default PageNFTHistory;
