import clsx from 'clsx';
import { KTSVG } from '../../../helpers';
import { useLayout } from '../../core';
import Button from '../../../components/common/Button';

const SidebarLogo = () => {
  const { config } = useLayout();
  const appSidebarDefaultMinimizeDesktopEnabled = config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled = config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : '';
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default;
  return (
    <div className="app-sidebar-logo px-6">
      <Button
        backgroundColor="transition"
        href="/dashboard"
        className="d-flex px-0"
        onClick={() => localStorage.setItem('menu-selected', 'dashboard')}
        style={{ padding: 0 }}
      >
        <img alt="Logo" src="/img/logo.svg" className="h-28px app-sidebar-logo-default" />
        <img alt="Logo" src="/img/logo-short.svg" className="h-28px app-sidebar-logo-minimize"></img>
      </Button>
      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id="kt_app_sidebar_toggle"
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-100 start-100 translate-middle rotate',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle="true"
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target="body"
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG path="/img/icons/duotune/arrows/arr079.svg" className="svg-icon-2 rotate-180" />
        </div>
      )}
    </div>
  );
};

export { SidebarLogo };
