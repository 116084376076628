import { useEffect, useState } from 'react';
import { Alert } from '../../helpers/AlertHelpers';
import DailySnapshotService from '../../services/DailySnapshotService';
import { ISnapshot, SnapshotStatus } from '../../types/Snapshot';

import { showDateTime } from '../../helpers';

import { Trans, t } from '@lingui/macro';
import AmmountStats from './AmmountStats';
import { useQuery } from '@tanstack/react-query';
import TransactionList from './TransactionList';

const ReconciliationDetail = ({ snapshot, loadData }: { snapshot: ISnapshot; loadData: any }) => {
  const [note, setNote] = useState('');
  // const [check, setChecked] = useState(false);
  const maxLength = 255; // Define your maximum length here
  useEffect(() => {
    if (snapshot) {
      setNote(snapshot.note);
    }
  }, [snapshot]);

  const { data: detail, refetch } = useQuery({
    queryKey: ['detail-snapshot'],
    queryFn: async () => {
      const response = await new DailySnapshotService().getById(snapshot?.id);
      return response.data;
    },
  });

  const verify = () => {
    // if (check) {
    new DailySnapshotService()
      .verifySnapshot(snapshot.id, note)
      .then((res) => {
        Alert.success(t`Reconciled successfully`);
        console.log(res);
        loadData();
        refetch();
      })
      .catch();
    //}
  };

  return (
    <div className="m-3">
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">
            <Trans>Overview</Trans>
          </h3>
        </div>
        <div className="card-body">
          {detail && detail.assetSummaries && <AmmountStats assetSummaries={detail && detail.assetSummaries} />}
        </div>
      </div>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">
            {snapshot.verifyStatus !== SnapshotStatus.VERIFIED ? (
              <Trans>Verify</Trans>
            ) : (
              <>
                <Trans>Verified</Trans>
                <i className="las la-check-circle fs-2 ms-1 text-success"></i>
              </>
            )}
          </h3>
        </div>
        <div className="card-body">
          <label className="form-label">
            <Trans>Note</Trans>
          </label>
          {snapshot.verifyStatus !== SnapshotStatus.VERIFIED ? (
            <div className="row">
              <div className="col">
                <textarea
                  className="form-control"
                  maxLength={maxLength}
                  rows={1}
                  onChange={(e) => {
                    if (e.target.value.length <= maxLength) {
                      setNote(e.target.value);
                    }
                  }}
                ></textarea>
              </div>
              <div className="col-auto">
                <button className="btn bg-success text-white" onClick={verify}>
                  <Trans>Verify</Trans>
                </button>
              </div>
            </div>
          ) : (
            <div className="border border-dashed rounded p-3 mb-6">{snapshot?.note ?? 'N/A'}</div>
          )}
          {snapshot.verifyStatus === SnapshotStatus.VERIFIED && (
            <div className=" fw-bold fs-6 text-primary">
              <Trans>Verified By </Trans> {detail !== undefined && detail.verifiedBy ? detail.verifiedBy.name : ''}{' '}
              <Trans>at</Trans> {detail != undefined ? showDateTime(detail.verifiedAt) : ''}
            </div>
          )}
        </div>
      </div>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">
            <Trans>Transaction</Trans>
          </h3>
        </div>
        <div className="card-body">
          <TransactionList snapshot={snapshot} />
        </div>
      </div>
    </div>
  );
};

export default ReconciliationDetail;
