import { Fragment } from 'react';
import { MertchantTx } from '../../types/Merchant';
import { logStatus } from '../../types/Transaction';
import DateTime from '../common/DateTime';
import TransString, { StringTypes } from '../TransString';
import { Trans } from '@lingui/macro';

const LogEvent = ({ details }: { details: MertchantTx }) => {
  return (
    <div>
      <div className="p-5 border rounded-4 mb-5">
        {details.txLogInfo.logs.map((log, i) => {
          return (
            <Fragment key={i}>
              <div className="row">
                <div className="col-8">
                  {logStatus.includes(log.status.toString()) ? (
                    <img src="/img/tx-time-failure-icon.svg" className="me-2" alt="time-failure" />
                  ) : (
                    <img src="/img/tx-time-icon.svg" className="me-2" alt="tx-time" />
                  )}
                  <TransString value={log.status} type={StringTypes.LOG} />
                </div>
                <div className="col py-3 d-flex justify-content-end ">
                  <DateTime value={log.date} isInline />
                </div>
              </div>
              {i < details.txLogInfo.logs.length - 1 && (
                <div className="py-2 px-6">
                  <img src="/img/tx-time-line.svg" alt="line" />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      {details.txLogInfo.errorMessage && (
        <div className="p-5 border rounded-4 mb-5 ">
          <div className="row mb-5">
            <div className="col fw-bold ">
              {/* Thông báo lỗi */}
              <Trans>Error</Trans>
            </div>
            <div className="col d-flex justify-content-end "></div>
          </div>
          <div className="text-break">{details.txLogInfo.errorMessage}</div>
        </div>
      )}
    </div>
  );
};

export default LogEvent;
