/* eslint-disable  @typescript-eslint/no-explicit-any */

import { ITokenChain } from '../types';
import EtherService from './blockchain/EtherService';

export interface IWeb3 {
  connect: (chain?: ITokenChain) => Promise<any>;
  switchChain: (chain: ITokenChain) => Promise<any>;
  checkAddress: (chainName: string) => any;
  approveToken: (spender: string, tokenAddress: string, amount: number, decimals: number) => Promise<any>;
  getAllowanceToken: (
    tokenAddress: string,
    ownerAddress: string,
    spenderAddress: string,
    decimals: number
  ) => Promise<any>;
  depositWallet: (
    sessionId: string,
    tokenAddress: string,
    spenderAddress: string,
    merchantAddress: string,
    amount: number,
    decimals: number,
    chainCode: string,
    gasPrice?: any
  ) => Promise<any>;
  getTokenBalance: (walletAddress: string, tokenAddress: string, decimals?: number) => Promise<any>;
}

class Web3 implements IWeb3 {
  service: IWeb3 | null;
  address: string | null;
  constructor() {
    this.service = null;
    this.address = null;
  }
  onNetwork(network: 'ETHEREUM' | 'TRON') {
    switch (network) {
      // case 'TRON':
      //   this.service = TronService;
      //   break;
      default:
        this.service = EtherService;
        break;
    }

    return this.service;
  }
  /* eslint-disable  no-useless-catch */
  async connect(chain?: ITokenChain) {
    const connector = await this.service?.connect(chain);
    this.address = connector.address;
    return connector;
  }

  async switchChain(chain: ITokenChain) {
    return this.service?.switchChain(chain);
  }

  checkAddress(chainName: string) {
    return this.service?.checkAddress(chainName);
  }

  async approveToken(spender: string, tokenAddress: string, amount: number, decimals: number) {
    return await this.service?.approveToken(spender, tokenAddress, amount, decimals);
  }

  async getAllowanceToken(tokenAddress: string, ownerAddress: string, spenderAddress: string, decimals: number) {
    return await this.service?.getAllowanceToken(tokenAddress, ownerAddress, spenderAddress, decimals);
  }

  async depositWallet(
    sessionId: string,
    tokenAddress: string,
    spenderAddress: string,
    merchantAddress: string,
    amount: number,
    decimals: number,
    chainCode: string,
    gasPrice?: any
  ) {
    return await this.service?.depositWallet(
      sessionId,
      tokenAddress,
      spenderAddress,
      merchantAddress,
      amount,
      decimals,
      chainCode,
      gasPrice
    );
  }

  async getTokenBalance(walletAddress: string, tokenAddress: string, decimals?: number) {
    return await this.service?.getTokenBalance(walletAddress, tokenAddress, decimals);
  }
}

const Web3Service = new Web3();

export default Web3Service;
