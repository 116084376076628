import { useSelector } from 'react-redux';
import { getChainsSelector } from '../store/chain/selectors';
import { ITokenChain } from '../types';

export const useTokenChain = () => {
  const chains = useSelector(getChainsSelector);

  const tokenChains: ITokenChain[] = [];

  chains.forEach(({ tokens, ...i }) =>
    Object.values(tokens).forEach((token) => {
      tokenChains.push({
        ...i,
        token,
      });
    })
  );

  const findChainById = (chainId?: number) => {
    const foundChain = chains.find((item) => item.id === chainId);
    return foundChain;
  };

  return {
    findChainById,
    tokenChains: tokenChains.filter((i) => i?.code.toUpperCase() !== 'TRON' && i?.token?.symbol !== 'VNFT'),
  };
};
