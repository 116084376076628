/* eslint-disable  @typescript-eslint/no-explicit-any */
import ProviderItem from './ProviderItem';
import { useConnect } from 'wagmi';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';
import { FaIcons } from '../../../helpers/FaIconHelper';
import { Trans } from '@lingui/macro';

interface Props {
  account: any;
  show: boolean;
  onSelect?: (provider: string) => void;
  onHide: () => void;
}

const ModalSelectProvider: React.FC<Props> = ({ account, show, onSelect, onHide }) => {
  const { connect, connectors } = useConnect();

  const handleSelectProvider = async (connector: any, provider: any) => {
    if (!account?.address) {
      connect({ connector: connectors[0] });
    }
    if (onSelect) onSelect(provider);
  };

  return (
    <Modal
      className="modal-sticky modal-sticky-lg modal-sticky-bottom-right"
      id="kt_mega_select_provider_modal"
      aria-hidden="true"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog mw-500px"
      show={show}
      onHide={onHide}
    >
      <div className="modal-header border-0 ">
        <h2>
          <Trans>
            {/* Chưa kết nối ví */}
            Not connected wallet.
          </Trans>
        </h2>
        <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
          <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
        </button>
      </div>
      <div className="modal-body py-0 mb-5">
        <p>
          {/* Vui lòng kết nối ví để có thể nạp token vào tài khoản */}
          <Trans>Please connect your wallet in order to deposit tokens into your account.</Trans>
        </p>
        <ProviderItem
          connector="METAMASK"
          provider={'ETHEREUM'}
          title={'MetaMask'}
          logo={'metamask.svg'}
          onSelect={handleSelectProvider}
          disabled={false}
          key={'METAMASK'}
        />
      </div>
    </Modal>
  );
};

export default ModalSelectProvider;
