import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../setup';
import { Error403 } from '../errors/Error403';
import { useAuth } from '../../store/auth';

const mapState = (state: RootState) => ({ user: state.auth.user, roles: state.auth.user?.roleNames });
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {
  types: string;
  children: React.ReactElement;
  showError?: boolean;
}

const CheckMerchantType: FC<Props> = (props) => {
  const { types, children, showError } = props;
  const { currentUser } = useAuth();

  const couldShow = currentUser?.type === types;

  return couldShow ? <>{children}</> : showError ? <Error403 /> : <></>;
};

export default connector(CheckMerchantType);
