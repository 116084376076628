import { t } from '@lingui/macro';
import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorWidget: FC<FallbackProps & { msg?: string; title?: string }> = ({
  msg = t`We are sorry. Something not right.`,
  title,
  error,
}) => {
  return (
    <div className="card">
      {title && (
        <div className="card-header">
          <h2 className="card-title">{title}</h2>
        </div>
      )}
      <div className="card-body">
        <div className="p-5 text-center">
          <div className="alert alert-danger">{error ?? msg}</div>
        </div>
      </div>
    </div>
  );
};

export default ErrorWidget;
