import clsx from 'clsx';
import { FC } from 'react';
import { languageData, useLanguageProvider } from '../../providers/LanguageProvider';

const LanguageSwitcher: FC = () => {
  const { language } = useLanguageProvider();
  const currentLanguage = languageData.find((x) => x.lang === language);

  return (
    <div className="text-center">
      {languageData.map((l, i) => (
        <a
          key={i}
          href={`?lng=${l.lang}`}
          className={clsx('menu-link px-3', {
            'active fw-bolder': l.lang === currentLanguage?.lang,
            'text-muted': l.lang !== currentLanguage?.lang,
          })}
        >
          <span className="symbol symbol-20px me-2">
            <img className="rounded-1" src={l.flag} alt="metronic" />
          </span>
          {l.name}
        </a>
      ))}
    </div>
  );
};
export default LanguageSwitcher;
