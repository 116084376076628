export const APP_NATIVE_ID = 2223;

export const FORMAT_DATE_FILTER_START = 'YYYY-MM-DDT00:00:00Z';
export const FORMAT_DATE_FILTER = 'YYYY-MM-DDT23:59:59Z'; //;

export const FILE_RULES = {
  size: 2,
  width: 2000,
  height: 2000,
};
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export const V_CHAIN = 'VCHAIN';
