import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from './auth/';

import currencySaga from './currency/sagas';
import currencyReducer from './currency/reducers';

import tokenSaga from './token/sagas';
import tokenReducer from './token/reducers';

import chainSaga from './chain/sagas';
import chainReducer from './chain/reducers';

import settingsReducer from './settings/reducers';
import settingsSaga from './settings/sagas';

import transactionReducer from './transaction/reducers';
import transactionSaga from './transaction/sagas';

import dashboardReducer from './dashboard/reducers';
import dashboardSaga from './dashboard/sagas';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  currency: currencyReducer,
  token: tokenReducer,
  chain: chainReducer,
  settings: settingsReducer,
  transaction: transactionReducer,
  dashboard: dashboardReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    auth.saga(),
    currencySaga(),
    tokenSaga(),
    chainSaga(),
    settingsSaga(),
    transactionSaga(),
    dashboardSaga(),
  ]);
}
