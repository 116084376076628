import { createSelector } from 'reselect';
import { RootState } from '../RootReducer';

const getTransaction = ({ transaction }: RootState) => transaction.data;
const getPending = ({ transaction }: RootState) => transaction.pending;
const getError = ({ transaction }: RootState) => transaction.error;

export const getTransactionSelector = createSelector(getTransaction, (transactions) => transactions);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
