import { call, put, takeEvery } from 'redux-saga/effects';
import ChainService from '../../services/ChainService';
import { IChain } from '../../types';
import { fetchChainFailure, fetchChainSuccess } from './actions';
import { GET_CHAINS_REQUEST } from './actionTypes';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* fetchChainSaga() {
  try {
    const chains: IChain[] = yield call(ChainService.getChainList);
    yield put(fetchChainSuccess({ chains })); // why
  } catch (e) {
    console.log('GET DATA ERROR', e);
    const error = e as Error;
    yield put(fetchChainFailure({ error: error.message }));
  }
}

// Starts fetchUser on each dispatched USER_FETCH_REQUESTED action
// Allows concurrent fetches of user
function* tokenSaga() {
  yield takeEvery(GET_CHAINS_REQUEST, fetchChainSaga);
}

export default tokenSaga;
