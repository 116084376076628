/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Table as TableBootstrap } from 'react-bootstrap';
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Loading } from '../common/Loading';
import { NoData } from '../NoData';
import { TListModel } from '../../types';
import { Pagination } from '@cxptek/core';

interface ColumnsType {
  align?: 'start' | 'end' | 'center';
  className?: string;
  dataIndex: string;
  title: ReactNode;
  width?: string | number;
  render?: (value: any, record: any) => React.ReactNode;
}

interface IProps {
  loading?: boolean;
  showHeader?: boolean;
  bordered?: boolean;
  borderless?: boolean;
  hover?: boolean;
  responsive?: boolean | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  columns: ColumnsType[];
  listModel?: TListModel<any>;
  handleChange?: any;
  data?: Record<string, any>[];
  hidePage?: boolean;
}

export const Table = (props: IProps) => {
  const { showHeader = true, columns, listModel, loading, handleChange, data, hidePage = true, ...rest } = props;
  const dataSource = listModel ? listModel.items : data;
  console.log(loading);
  return (
    <div className="position-relative">
      {/* {loading && (
        <div
          className="position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center"
          style={{ background: 'rgba(255, 255, 255, 0.7)' }}
        >
          <Loading className="text-primary fw-semibold" />
        </div>
      )} */}
      <TableBootstrap {...rest}>
        <colgroup>
          {columns.map(({ width }, l) => {
            if (!width) return null;
            return <col style={{ width }} key={l} />;
          })}
        </colgroup>
        {showHeader && (
          <thead>
            <tr>
              {columns.map(({ align = 'start', title, className }, j) => (
                <th className={clsx(className, `text-${align}`)} key={j}>
                  {title}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {dataSource ? (
            dataSource.length === 0 ? (
              <tr>
                <td className="py-10" colSpan={columns.length}>
                  <NoData />
                </td>
              </tr>
            ) : (
              dataSource.map((data, k) => (
                <tr key={k}>
                  {columns.map(({ align = 'start', dataIndex, className, render }, i) => (
                    <td className={clsx(className, `text-${align}`)} key={i}>
                      {render ? <>{data[dataIndex] && render(data[dataIndex], data)}</> : data[dataIndex]}
                    </td>
                  ))}
                </tr>
              ))
            )
          ) : (
            <tr>
              <td className="py-10" colSpan={columns.length}>
                <Loading />
              </td>
            </tr>
          )}
        </tbody>
      </TableBootstrap>

      {listModel && listModel.totalPages > 1 && hidePage && (
        <Pagination
          totalPages={listModel.totalPages}
          currentPage={listModel.page === 0 ? 1 : listModel.page}
          onChangePage={(page: number) => handleChange({ page })}
        />
      )}
    </div>
  );
};
