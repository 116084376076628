import { useEffect, useState } from 'react';

const useCountdown = (targetDate?: Date) => {
  const countDownDate = targetDate ? new Date(targetDate).getTime() : 0;

  const [countDown, setCountDown] = useState<number>();

  useEffect(() => {
    if (countDownDate > 0) {
      const interval = setInterval(() => {
        const newCountDown = countDownDate - new Date().getTime();
        if (newCountDown <= 0) {
          setCountDown(0);
          clearInterval(interval);
        } else {
          setCountDown(newCountDown);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDownDate]);

  return getReturnValues(countDown, countDownDate);
};

const formatTimeWithZero = (time: string) => {
  return Number(time) < 10 ? '0' + time : time;
};

const getReturnValues = (countDown?: number, targetDate?: number) => {
  // calculate time left
  const days = Math.floor(Number(countDown) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((Number(countDown) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = formatTimeWithZero(String(Math.floor((Number(countDown) % (1000 * 60 * 60)) / (1000 * 60))));
  const seconds = formatTimeWithZero(String(Math.floor((Number(countDown) % (1000 * 60)) / 1000)));
  const isExpired = Number(targetDate) > 0 && countDown === 0;
  return { days, hours, minutes, seconds, isExpired };
};

export { useCountdown };
