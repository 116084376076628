import AxiosInstance from './axios';
import { SettingsModel } from '../store/settings/types';
import { Chains } from '../types';

export const getSettings = async (chainCode = Chains.POLYGON.toString()): Promise<SettingsModel> => {
  try {
    const params = new URLSearchParams({ chainCode });
    const url = `/merchant/settings?${params}`;
    const { data }: { data: SettingsModel } = await AxiosInstance.get(url);
    return {
      ...data,
      merchant: {
        ...data.merchant,
        suspended: data.merchant.suspended,
      },
    };
  } catch (err) {
    console.error('[API] getSettings', err);
    throw err;
  }
};

export const getAdminSettings = async () => {
  const url = '/admin/settings';

  try {
    const data = await AxiosInstance.get(url).then((res) => res.data);
    return {
      status: true,
      msg: 'Success',
      data,
    };
  } catch (e) {
    console.error('[API] getAdminSettings', e);
  }
};
