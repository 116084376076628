import { getChain } from '../../helpers';
import { Address } from '../token/Address';

const ChainIconWithAddress = ({
  symbol,
  title,
  chainCode,
  logoUrl,
  size,
  address,
  link,
}: {
  symbol?: string;
  chainCode?: string;
  title?: string;
  logoUrl?: string;
  showBalance?: boolean;
  size?: string | number;
  address: string;
  link: string;
}) => {
  const chain = getChain(chainCode ?? '');
  const chainName = chain?.name || chainCode;
  const networkIcon = logoUrl ? logoUrl : chain?.logoUrl;

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <span className={`symbol symbol-${size ? size : '35'}px`}>
          <img src={networkIcon} className="mw-100 mh-100 p-0" alt="network" />
        </span>
        <div className="d-flex flex-wrap align-items-center">
          <div>
            <Address className="d-flex" address={address} link={link} />
            <div className="">
              <div className="text-gray-800 fw-bold text-hover-primary fs-6">{title ? title : symbol}</div>
              {chainName && <span className="text-gray-400 fw-semibold d-block fs-7">{chainName}</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChainIconWithAddress;
