import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { getSettingsSelector } from '../../store/settings/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useAccount, useNetwork, useFeeData } from 'wagmi';
import ModalSelectProvider from '../../layout/components/provider/ModalSelectProvider';
import clsx from 'clsx';
import { FaIcons } from '../../helpers/FaIconHelper';
import MerchantService from '../../services/MerchantService';
import { toast } from 'react-toastify';
import { showToastMessage } from '../messages/ToastError';
import Web3Service from '../../services/Web3Service';
import { Chains, ITokenChain } from '../../types';
import NumberInput from '../form/NumberInput';
import { TokenItem, CurrentTokenItem } from '../token/TokenItem';
import { useTokenChain } from '../../hooks/useTokenChain';
import WithTooltip from '../WithTooltip';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Alert, currencyFormat } from '../../helpers';
import { IDepositFee } from '../../store/transaction/types';
import DepositInformation from './DepositInformation';
import ErrorMessage from '../errors/ErrorMessage';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import ActionPendingModal, { Status } from './ActionPendingModal';
import { useAuth } from '../../store/auth';
import * as Yup from 'yup';
import { Trans, t } from '@lingui/macro';
import { useCountdown } from '../../hooks/useCountdown';
import { TypeErrorMessage } from '../../helpers/ErrorHelper';
import AuthService from '../../services/AuthService';
import { SETTINGS } from '../../store/settings/actions';
import { V_CHAIN } from '../../constants';

type Loading = {
  approve: boolean;
  allow: boolean;
  hasApprove: boolean;
  balance: boolean;
};

const DepositModal = ({ show, onHide }: { show: boolean; onHide: () => void }) => {
  const account = useAccount();
  const { chain: currentChain } = useNetwork();
  const settings = useSelector(getSettingsSelector);
  const { tokenChains } = useTokenChain();
  const [isConnectModal, setIsConnectModal] = useState(false);
  const [platform, setPlatform] = useState('ETHEREUM');
  const [walletBalance, setWalletBalance] = useState(0);
  const [allowanceAmount, setAllowanceAmount] = useState(0);
  const [isMinError, setIsMinError] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [errorCode, setErrorCode] = useState<string>('');
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const [tokenChain, setTokenChain] = useState<ITokenChain>(
    tokenChains.find((i) => i.id === currentChain?.id) ?? tokenChains[0]
  );
  const [loading, setLoading] = useState<Loading>({
    approve: false,
    allow: false,
    hasApprove: false,
    balance: false,
  });

  const wrongAccount =
    account.address?.toLocaleLowerCase() !== settings.merchant.depositAddress.toLocaleLowerCase() &&
    account.isConnected;

  const afterGetSession = async ({
    sessionId,
    values,
    setSubmitting,
  }: {
    sessionId: string;
    values: { amount: number };
    setSubmitting: (isSubmitting: boolean) => void;
  }) => {
    try {
      const response = await Web3Service.depositWallet(
        sessionId,
        String(tokenChain?.token?.address),
        String(addressSettings),
        settings?.merchant?.depositAddress,
        values.amount,
        tokenChain.token.decimals,
        tokenChain.code,
        tokenChain.code === Chains.POLYGON ? fee?.data?.gasPrice : ''
      );

      if (response) {
        onCloseModal();

        MerchantService.updateSessionDeposit(sessionId, response?.hash).then(async (res) => {
          if (res.status === 403 && res.code === 'MERCHANT_SUSPENDED') {
            Alert.error(
              t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
              true
            );
            setSubmitting(false);
          } else {
            if (response === '4001') {
              showToastMessage('The transaction was rejected by your Wallet.');
            } else {
              setOpenModal('pending');
              const txReceipt = await response.wait(3);
              if (txReceipt && txReceipt['transactionHash']) {
                formik.setFieldValue('amount', 0);
                getBalance();
                setSubmitting(false);
              }
            }
          }
        });
      }
      /* eslint-disable  @typescript-eslint/no-explicit-any */
    } catch (error: any) {
      console.log(error);
      setErrorCode(error?.response?.data?.code ?? TypeErrorMessage.PAYMENT_SESSION_REJECTED);
      onCloseModal();
      setOpenModal('failed');
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .required(t`You haven't entered the amount`)
        .max(walletBalance, t`Exceeds the available balance`),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      let userMerchant;
      if (auth) {
        userMerchant = await AuthService.getMe(auth.token);
        dispatch(SETTINGS.REQUEST());
      }

      if (userMerchant?.suspended) {
        //Tài khoản của bạn đang tạm khóa. Xin vui lòng liên hệ với bộ phận kinh doanh để biết thêm chi tiết hoặc hỗ trợ mở khóa
        Alert.error(
          t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
          true
        );
        return;
      }
      if (values.amount <= 0) {
        setIsMinError(true);
        return;
      }
      try {
        const paymentSession = await MerchantService.postSessionDeposit({
          amount: values.amount,
          chainCode: tokenChain.code,
          typeToken: tokenChain.token.symbol,
        });
        setCountDown(paymentSession?.expiredAt);
        await afterGetSession({ sessionId: String(paymentSession?.id), values, setSubmitting });
      } catch (e) {
        showToastMessage(t`Token deposit failed`);
      } finally {
        setSubmitting(false);
        setCountDown(0);
      }
    },
  });

  const [countDown, setCountDown] = useState<number>(0);
  const { minutes, seconds, isExpired } = useCountdown(new Date(countDown as number));

  const addressSettings = useMemo(
    () => settings.paymentGateways.find((i) => i.chainCode === tokenChain.code)?.address,
    [tokenChain, settings]
  );

  const isUnsupportedChain = !currentChain || tokenChain?.id !== currentChain?.id;

  const getAllowance = useCallback(async () => {
    if (!addressSettings || !tokenChain) {
      return;
    }
    if (tokenChain.code === V_CHAIN) {
      getBalance();
      return;
    }
    setLoading((prev) => ({ ...prev, allow: true }));

    try {
      const allowance = await Web3Service.getAllowanceToken(
        String(tokenChain?.token?.address),
        settings.merchant.depositAddress,
        String(addressSettings),
        tokenChain?.token?.decimals
      );
      setAllowanceAmount(allowance);
      setLoading((prev) => ({ ...prev, allow: false }));
      getBalance();
    } catch (e) {
      console.log('get Allowance error', e);
      setAllowanceAmount(0);
      setLoading((prev) => ({ ...prev, allow: false }));
    }
  }, [tokenChain, settings]);

  const getBalance = async () => {
    setLoading((prev) => ({ ...prev, balance: true }));

    const balance = await Web3Service.getTokenBalance(
      settings.merchant.depositAddress,
      tokenChain?.token?.address,
      tokenChain?.token?.decimals
    );
    setWalletBalance(balance);

    setLoading((prev) => ({ ...prev, balance: false }));
  };

  const approveMethod = async () => {
    setLoading((prev) => ({ ...prev, approve: true }));

    if (!addressSettings || !tokenChain) {
      return;
    }

    try {
      const txReceipt = await Web3Service.approveToken(
        String(addressSettings),
        String(tokenChain?.token?.address),
        Number(formik.values.amount),
        Number(tokenChain?.token?.decimals)
      );

      if (txReceipt) {
        getAllowance();
        if (allowanceAmount < Number(formik.values.amount)) {
          setLoading((prev) => ({ ...prev, hasApprove: true }));
          showToastMessage(t`Successful licensing`, true);
        } else {
          toast.error(
            <span>
              You must approve greater than or equal to {Number(formik.values.amount)} {tokenChain.token.symbol}
            </span>,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      }
      setLoading((prev) => ({ ...prev, approve: false }));
    } catch (e) {
      setLoading((prev) => ({ ...prev, approve: false, hasApprove: false }));
      showToastMessage(
        `Your Approval has failed on ${tokenChain?.name}. Rest assured that your funds are safe. You may try again later.`
      );
    }
  };

  useEffect(() => {
    if (account.address && account.isConnected) {
      setIsConnectModal(false);
      Web3Service.onNetwork(platform as 'ETHEREUM' | 'TRON');
    }
  }, [account]);

  const handleSwitchChain = async () => {
    await Web3Service.connect(tokenChain);

    getAllowance();
  };

  const fee = useFeeData({ chainId: tokenChain?.id });

  useEffect(() => {
    if (!tokenChain) {
      return;
    }
    if (account.isConnected && settings?.merchant?.depositAddress) {
      if (tokenChain?.id !== currentChain?.id) {
        handleSwitchChain();
      } else {
        getAllowance();
      }
    }
  }, [account.isConnected, tokenChain, settings]);

  useEffect(() => {
    if (tokenChain?.id !== currentChain?.id && currentChain) {
      formik.setFieldValue(
        'chain',
        tokenChains.find((i) => i.id === currentChain?.id)
      );
    }
  }, [currentChain]);

  const { isLoading: isLoadingFee, data: estimateFee } = useAbortRequest(
    (signal) =>
      MerchantService.calculateFee({
        amount: parseFloat((formik?.values?.amount ?? 0)?.toString()),
        chainCode: tokenChain?.code,
        token: tokenChain?.token?.symbol,
        type: 'deposit',
        signal,
      }),
    Number(formik?.values?.amount) > 0,
    [formik?.values?.amount]
  );

  useEffect(() => {
    if (Number(formik.values.amount) > 0) {
      getAllowance();
      setIsMinError(false);
    }
  }, [Number(formik.values.amount)]);

  const isRemainAllowance = allowanceAmount < Number(formik.values.amount);
  const insufficientBalance = Number(formik.values.amount) > walletBalance;
  const isTransferReady = isRemainAllowance && !insufficientBalance && loading.hasApprove;
  const canDeposit = !loading.approve && !loading.hasApprove && isTransferReady;

  const onCloseModal = () => {
    formik.resetForm();
    onHide();
    setCountDown(0);
  };

  return (
    <div>
      <Modal
        className={`deposit-modal modal-sticky modal-sticky-lg modal-sticky-bottom-right ${
          isConnectModal ? 'opacity-0' : 'opacity-1'
        }`}
        id="kt_mega_deposit_modal"
        aria-hidden="true"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={onCloseModal}
      >
        <div className="modal-header border-0">
          <h2>
            <Trans>Add funds to the wallet.</Trans>
          </h2>
          <button onClick={onCloseModal} className="btn btn-sm btn-icon btn-active-color-primary">
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="modal-body py-0">
            {wrongAccount && !isUnsupportedChain && (
              <ErrorMessage
                message={t`The connected wallet address does not match the registered wallet address. Please change your account from your wallet.`}
              />
            )}

            {account.isConnected && isUnsupportedChain && (
              <ErrorMessage
                message={t`The network you are currently using is not supported. Please switch to another network on your wallet to continue.`}
              />
            )}

            <div style={{ pointerEvents: wrongAccount ? 'none' : 'auto', opacity: wrongAccount ? 0.7 : 1 }}>
              <div>
                <label htmlFor="" className="form-label d-flex gap-1">
                  <span>
                    <Trans>The wallet address you have registered</Trans>:
                  </span>
                  <WithTooltip
                    id="tooltip-1"
                    placement="right"
                    tooltip={
                      <div className="text-start">
                        <Trans>The wallet address you registered when setting up the partner account.</Trans>
                      </div>
                    }
                  >
                    <AiOutlineExclamationCircle size={10} style={{ verticalAlign: 'text-top' }} />
                  </WithTooltip>
                </label>
                <div className="bg-secondary border rounded p-3">
                  <span className="mx-2 text-muted">{settings.merchant.depositAddress}</span>
                </div>
              </div>

              <div className="bg-white-smoke p-3 rounded my-4">
                <div className="mx-2">
                  <div className="d-flex justify-content-between text-size-xs mb-2 text-dark-electric-blue">
                    <span className="fw-semibold">
                      <Trans>Deposit amount</Trans>:
                    </span>
                    {!isUnsupportedChain && (
                      <div className="float-end d-flex gap-2 mb-2">
                        <Trans>Balance in the wallet</Trans>:
                        <b>
                          {loading.balance ? (
                            <span
                              className="spinner-border spinner-border-sm me-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            currencyFormat(walletBalance, tokenChain?.token?.symbol)
                          )}
                        </b>
                        <div
                          className="btn btn-primary btn-xs text-uppercase p-1 px-2 fs-9 rounded-1"
                          onClick={() => {
                            formik.setFieldValue(
                              'amount',
                              Number(
                                currencyFormat(walletBalance, tokenChain?.token?.symbol, undefined, {
                                  hideCurrency: true,
                                }).replaceAll(',', '')
                              )
                            );
                          }}
                        >
                          <Trans>Max</Trans>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="dropdown">
                      <button
                        className="border-0 bg-transparent p-0"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {tokenChain && <CurrentTokenItem chain={tokenChain} className="p-0" />}
                      </button>
                      <ul className="dropdown-menu">
                        {tokenChains.map((chain: ITokenChain, i: number) => (
                          <li key={`currency-item-${i}`}>
                            <TokenItem
                              chain={chain}
                              selectedChain={tokenChain}
                              onSelectChain={(e) => {
                                setTokenChain(e);
                                formik.setFieldValue('amount', 0);
                              }}
                              className="cursor-pointer"
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <NumberInput
                        id="currencyInput"
                        maxLength={15}
                        autoComplete="off"
                        placeholder="0"
                        onValueChange={(value) => {
                          formik.setFieldValue('amount', value);
                        }}
                        autoFocus
                        value={formik.values.amount ?? undefined}
                        allowDecimals={!tokenChain?.token?.symbol?.includes('VIC')}
                        disabled={isUnsupportedChain || wrongAccount || formik.isSubmitting}
                      />
                      <div className="text-end">
                        {formik.errors.amount && !isUnsupportedChain && (
                          <div className="fv-plugins-message-container invalid-feedback_error">
                            <div className="fv-help-block">{formik.errors.amount}</div>
                          </div>
                        )}
                        {isMinError && (
                          <div className="fv-plugins-message-container invalid-feedback_error">
                            <div className="fv-help-block">
                              <Trans>The amount must be greater than 0</Trans>
                            </div>
                          </div>
                        )}
                        {!formik.errors.amount && estimateFee?.amountAfterCost.amount <= 0 && formik.values.amount > 0 && (
                          <div className="fv-plugins-message-container invalid-feedback_error">
                            <div className="fv-help-block">
                              <Trans>The deposit amount is too small</Trans>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <DepositInformation
                isLoading={isLoadingFee}
                amount={formik.values.amount}
                symbol={tokenChain?.token?.symbol}
                isUnsupportedChain={isUnsupportedChain}
                estimateFee={estimateFee as IDepositFee}
              />

              {!isExpired && countDown > 0 && (Number(minutes) > 0 || Number(seconds) > 0) && (
                <p className="text-warning text-center">
                  <Trans>
                    The transaction will expire after {Number(minutes) > 0 ? minutes + ' ' + t`minutes` : ''}{' '}
                    {Number(minutes) > 0 || Number(seconds) > 0 ? seconds + ' ' + t`seconds` : ''}.
                    <br /> Please make the payment within the specified time.
                  </Trans>
                </p>
              )}
              {isExpired && Number(minutes) === 0 && Number(seconds) === 0 && (
                <p className="text-warning text-center">
                  <Trans>
                    Transaction expired. Please initiate a new payment and{' '}
                    <b>avoid making any payment on the previous transaction</b>.
                  </Trans>
                </p>
              )}
            </div>
            <div className="mb-5">
              {!account?.address && !account?.isConnected ? (
                <button className="btn btn-primary w-100 " onClick={() => setIsConnectModal(true)} type="button">
                  <Trans>Connect wallet</Trans>
                </button>
              ) : loading.allow ? (
                <button className="btn btn-primary w-100 " type="button" disabled>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Loading
                </button>
              ) : !canDeposit && isRemainAllowance && tokenChain.code !== V_CHAIN ? (
                <button
                  type="button"
                  onClick={approveMethod}
                  className="btn btn-primary w-100"
                  disabled={
                    loading.approve ||
                    loading.balance ||
                    isMinError ||
                    !!formik.errors.amount ||
                    estimateFee?.amountAfterCost.amount <= 0 ||
                    formik.values.amount <= 0 ||
                    settings.merchant.suspended
                  }
                >
                  {loading.approve && (
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  )}
                  <Trans>Grant permission to use</Trans> {tokenChain?.token?.symbol}
                </button>
              ) : (
                <>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={
                      (!isRemainAllowance && formik.isSubmitting) ||
                      loading.balance ||
                      !!formik.errors.amount ||
                      isMinError ||
                      isLoadingFee ||
                      estimateFee?.amountAfterCost.amount <= 0 ||
                      formik.values.amount <= 0 ||
                      settings.merchant.suspended
                    }
                  >
                    {formik.isSubmitting ||
                      (isLoadingFee && (
                        <span
                          className="spinner-border spinner-border-sm me-2 "
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ))}
                    <Trans>Deposit token</Trans>
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </Modal>

      <ActionPendingModal
        title={t`Confirm token deposit`}
        show={!!openModal}
        onHide={() => setOpenModal('')}
        status={openModal as Status}
        errorCode={errorCode}
      />

      {isConnectModal && (
        <ModalSelectProvider
          account={account}
          show={isConnectModal}
          onHide={() => setIsConnectModal(!isConnectModal)}
          onSelect={setPlatform}
        />
      )}
    </div>
  );
};

export default DepositModal;
