import { useAuth } from '../../../store/auth';
import { toAbsoluteUrl } from '../../../helpers';
import Button from '../../../components/common/Button';
import clsx from 'clsx';
import { Trans } from '@lingui/macro';
import { getMerchantType } from '../../../helpers/UtilsHelpers';
import WithTooltip from '../../../components/WithTooltip';

const CustomHeaderUserMenu = ({
  userAvatarClassName,
  className,
}: {
  userAvatarClassName?: string;
  className?: string;
}) => {
  const { currentUser, logout } = useAuth();

  return (
    <div className={clsx('app-navbar-item', className)}>
      <div
        className={clsx(
          'cursor-pointer symbol bg-white border border-2 rounded-circle overflow-hidden',
          userAvatarClassName
        )}
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <img src={toAbsoluteUrl('/img/avatars/MANAGER.png')} alt="" />
      </div>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-2 fs-6 w-275px"
        data-kt-menu="true"
      >
        <div className="menu-item px-2">
          <div
            className="p-2 d-flex justify-content-end"
            style={{
              background: 'url("/img/sidebar.svg") no-repeat',
              backgroundSize: '100%',
              width: '100%',
              height: '100px',
            }}
          >
            <div className="fw-bold fs-7 text-white bg-primary ps-2 h-25 rounded-4 d-flex gap-1 align-items-center">
              {currentUser && (
                <>
                  <span className="text-white">{currentUser.email}</span>
                </>
              )}
              <i className="fas fa-wallet text-white bg-dark bg-opacity-50 p-1 rounded-3"></i>
            </div>
          </div>

          <div className="menu-content px-3" style={{ marginTop: '-5rem' }}>
            <div className="symbol symbol-60px bg-white border border-2 rounded-circle overflow-hidden">
              <img alt="logo" className="" src={toAbsoluteUrl('/img/avatars/MANAGER.png')} />
            </div>

            <div className="">
              <div className="fw-bolder fs-5 mb-1 text-truncate mw-100px">
                <WithTooltip id="view" tooltip={currentUser?.name}>
                  {currentUser?.name}
                </WithTooltip>
              </div>
              <div className="badge badge-light-success fw-bolder fs-8 px-2 py-1">
                {getMerchantType(currentUser?.type.toString())}
              </div>
            </div>
          </div>
        </div>

        <div className="menu-item px-5">
          <Button
            textColor="white"
            isHover
            backgroundColor="danger"
            className="w-100"
            iconClassName="fa fa-sign-out text-white"
            onClick={() => {
              logout(); //
              window.location.reload();
            }}
          >
            <Trans>Sign out</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomHeaderUserMenu;
