import { Trans, t } from '@lingui/macro';

import TabItem from '../../components/TabItem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getChainsSelector } from '../../store/chain/selectors';
import MerchantNFTService from '../../services/MerchantNFTService';
import { INFTDashboard } from '../../types/MerchantNFT';
import { formatCurrency } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import CardDashboard from '../../components/CardDashboard';

const NftOverview = ({ chainCode, handleChange }: { chainCode?: string; handleChange: any }) => {
  const [, setLoading] = useState(false);
  const [data, setData] = useState<INFTDashboard>();
  useEffect(() => {
    loadData();
  }, [chainCode]);

  const loadData = async () => {
    setLoading(true);
    const data = await MerchantNFTService.dashboard({ chainCode });
    // console.log('data', data);
    setData(data);
    setLoading(false);
  };

  const changeNetwork = (chainCode: string) => {
    handleChange({ chainCode, page: 1 });
  };

  return (
    <div className="mb-6">
      <div className="row align-items-center mb-5">
        <div className="col-auto">
          <div className="fs-1 fw-bold border-start border-3 border-primary px-4">
            <Trans>Service overview</Trans>
          </div>
        </div>
        <div className="col-auto">
          <div className={'position-relative p-1 rounded d-inline-flex'}>
            <ChainTabs changeNetwork={changeNetwork} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3 ">
          <CardDashboard
            title={
              <>
                <img src="/img/issue-nft.svg" />
                {/* Đã phát hành */}
                <Trans>Issued</Trans>
              </>
            }
            number={formatCurrency(data?.issuedNftAmount || 0, CurrencySymbols.VIC)}
            total={formatCurrency(data?.issuedNft || 0, ' VNFT(s)')}
            tooltip={t`The total value and quantity of VNFTs you have ever issued`}
          />
        </div>
        <div className="col-3 ">
          <CardDashboard
            title={
              <>
                <img src="/img/tx-in-1.svg" />
                {/* Đang lưu thông */}
                <Trans>In circulation</Trans>
              </>
            }
            number={formatCurrency(data?.inCirculationNftAmount || 0, CurrencySymbols.VIC)}
            total={formatCurrency(data?.inCirculationNft || 0, ' VNFT(s)')}
            tooltip={t`The VNFTs you have issued are currently circulating as decentralized assets and are held in a decentralized wallet (DeFi wallet)`}
          />
        </div>
        <div className="col-3 ">
          <CardDashboard
            title={
              <>
                <img src="/img/consumed.svg" />
                {/* Đã được sử dụng */}
                <Trans>Consumed</Trans>
              </>
            }
            number={formatCurrency(data?.consumedNftAmount || 0, CurrencySymbols.VIC)}
            total={formatCurrency(data?.consumedNft || 0, ' VNFT(s)')}
            tooltip={t`The VNFTs you have issued have been used (burned) to make payments for integrated services through the payment gateway. This process represents the conversion from a DeFi asset into service payments`}
          />
        </div>
        <div className="col-3 ">
          <CardDashboard
            title={
              <>
                <img src="/img/inventory.svg" />
                {/* Đã chuyển đi */}
                <Trans>Transferred</Trans>
              </>
            }
            number={formatCurrency(data?.transferredNftAmount || 0, CurrencySymbols.VIC)}
            total={formatCurrency(data?.transferredNft || 0, ' VNFT(s)')}
            tooltip={t`The value and quantity of VNFTs that have been transferred using the Dapp provided by VicPay`}
          />
        </div>
      </div>
    </div>
  );
};

export default NftOverview;

const ChainTabs = ({ changeNetwork }: { changeNetwork: any }) => {
  const chain = useSelector(getChainsSelector);
  const [currentTab, setCurrentTab] = useState('');

  return (
    <div className="d-flex align-items-center gap-1 bg-white p-1 rounded ">
      <TabItem
        activeClass="bg-primary text-white"
        onClick={() => {
          changeNetwork('');
          setCurrentTab('');
        }}
        active={currentTab === ''}
        label={<Trans>All</Trans>}
      />
      {chain
        .filter((item) => item.code !== 'TRON')
        .map((item, k) => (
          <TabItem
            key={k}
            active={currentTab === item.code}
            activeClass="bg-primary text-white"
            onClick={() => {
              changeNetwork(item.code);
              setCurrentTab(item.code);
            }}
            label={item.name}
          />
        ))}
    </div>
  );
};
