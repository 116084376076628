import clsx from 'clsx';
import { Modal } from 'react-bootstrap';
import { FaIcons } from '../../helpers/FaIconHelper';
import Button from '../common/Button';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { getErrorMessage } from '../../helpers/ErrorHelper';

export type Status = 'success' | 'pending' | 'failed';

export default function ActionPendingModal({
  show,
  title,
  onHide,
  status,
  errorCode,
}: {
  show: boolean;
  title: string;
  onHide: () => void;
  status?: Status;
  errorCode?: string;
}) {
  const renderStatus = () => {
    switch (status) {
      case 'success':
        return (
          <>
            <img src="/img/tx-success.svg" className="w-50 mx-auto py-10" />
            <div className="fs-3 text-center mb-5">
              <Trans>
                Your transaction has been successful.
                {/* Giao dịch của bạn đã thành công. */}
              </Trans>
            </div>
          </>
        );

      case 'pending':
        return (
          <>
            <img src="/img/tx-loader.svg" className="w-50 mx-auto py-10" />
            <div className="fs-3 text-center mb-5">
              <Trans>
                {/* Giao dịch của bạn đang được xử lý. */}
                Your transaction is being processed
                <br />
                {/* Quá trình này có thể mất chút thời gian */}
                This may take some time.
              </Trans>
            </div>
          </>
        );
      case 'failed':
        return (
          <>
            <img src="/img/tx-failed.svg" className="w-50 mx-auto py-10" />
            <div className="fs-3 text-center mb-5">{getErrorMessage(String(errorCode))}</div>
          </>
        );
      default:
        return (
          <>
            <img src="/img/tx-loader.svg" className="w-50 mx-auto py-10" />
            <div className="fs-3 text-center mb-5">
              <Trans>
                {/* Giao dịch của bạn đang được xử lý. */}
                Your transaction is being processed
                <br />
                {/* Quá trình này có thể mất chút thời gian */}
                This may take some time.
              </Trans>
            </div>
          </>
        );
    }
  };

  return (
    <Modal
      className={clsx('modal-sticky modal-sticky-lg modal-sticky-bottom-right')}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-md"
      onHide={onHide}
    >
      <div className="modal-body">
        <div className="position-relative w-100 mb-5">
          <h2>{title}</h2>
          <button
            onClick={onHide}
            className="btn btn-sm btn-icon btn-active-color-primary position-absolute top-0 end-0"
          >
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>

        <div className="row mb-5">
          {renderStatus()}
          <div className="fs-3 text-center fw-bold ">
            <Trans>Track the status in</Trans>{' '}
            <Link to="/transaction-history" className="text-primary">
              <Trans>transaction history</Trans>
            </Link>
          </div>
        </div>
      </div>

      <div className="modal-footer py-2">
        <Button
          textColor="white"
          isHover
          backgroundColor="primary"
          className="btn-outline-primary user-select-none py-3 rounded fw-semibold"
          onClick={onHide}
        >
          <Trans>Go home</Trans>
          {/* Trở lại trang chủ */}
        </Button>
      </div>
    </Modal>
  );
}
