import { Trans } from '@lingui/macro';

import { MultiCurrency } from '../types/MerchantNFT';
import { formatCurrency } from '../helpers';

export const TokenCard = ({ exMultiCurrency }: { exMultiCurrency?: MultiCurrency[] | any }) => {
  return (
    <div className="card card-flush  h-xl-100 pt-3" key="exMultiCurrency">
      <div className="card-body">
        <table className="table gs-5 gx-5">
          <thead>
            <tr>
              <th>
                <Trans>Asset type</Trans>
              </th>
              <th>
                <Trans>Holding quantity</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {exMultiCurrency &&
              exMultiCurrency.map((item: MultiCurrency) => {
                return (
                  <tr key={item.code}>
                    <td className="fw-semibold">
                      <img src={`../img/${item.code}.svg`} width="24px" className="me-2" alt="Viecoin" />
                      {item.name}
                    </td>
                    <td className="col-xl-7">{formatCurrency(item.balance, item.code)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
