import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { formatCurrency } from '../../helpers';

const FormatTokenAmount: FC<
  PropsWithChildren<{ currency: string; amount?: number; direction?: string; maximumFractionDigits?: number }>
> = ({ currency, amount, direction, maximumFractionDigits, children }) => {
  return (
    <span className="format-currency ">
      {amount !== undefined && (
        <>
          <span className={clsx('text-end text-nowrap')}>
            {![0, null].includes(amount) && direction}

            {formatCurrency(amount, currency, true, maximumFractionDigits)}
          </span>
          <span className={clsx('ms-1', 'text-dark')}>{currency}</span>
        </>
      )}
      {children}
    </span>
  );
};

export default FormatTokenAmount;
