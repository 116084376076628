import React from 'react';
import { useSelector } from 'react-redux';
import { getPendingSelector } from '../../store/transaction/selectors';
import { IFilterTransaction } from '../../store/transaction/types';
import DateTime from '../../components/common/DateTime';
import { Table } from '../../components/table';
import { formatCurrency, getChain, getExplorerLink } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { Address } from '../../components/token/Address';
import { INftIssueHistory, NftIssueHistoryDataIndexes } from '../../types/MerchantNFT';

import { getStatusClass } from '../../types/Transaction';
import LabelStatus from '../../components/common/LabelStatus';

import { Trans } from '@lingui/macro';
import QueueTransactionHash from './QueueTransactionHash';
import NftIssueAction from './NftIssueAction';

export const NftTransactions = ({
  transactions,
  children,
  handleChange,
}: {
  className?: string;

  transactions: any;
  handleChange: (params: IFilterTransaction) => void;
  children: React.ReactChild | React.ReactNode;
}) => {
  const loading = useSelector(getPendingSelector);

  // const [transaction, setTransactions] = useState<TListModel<INftIssueHistory>>();

  const items = transactions?.items.map((item: INftIssueHistory) => ({
    ...item,
    txHashOnChain: {
      txHash: item.txHash,
      chainCode: item.chainCode,
      chainName: item.chainName,
    },
    nftAddressOnChain: {
      nftAddress: item.nftAddress,
      chainCode: item.chainCode,
    },
    distributorAddressOnChain: {
      distributorAddress: item.distributorAddress,
      chainCode: item.chainCode,
    },
  }));

  const listModel: any = transactions
    ? {
        page: transactions.page,
        perPage: transactions.perPage,
        totalItems: transactions.totalItems,
        totalPages: transactions.totalPages,
        items,
      }
    : undefined;

  const columns = [
    {
      title: <Trans>Created at</Trans>,
      dataIndex: NftIssueHistoryDataIndexes.createdAt,
      key: NftIssueHistoryDataIndexes.createdAt,
      render: (date: string) => <DateTime value={date} />,
    },
    {
      title: <Trans>Recipient wallet address</Trans>,
      dataIndex: NftIssueHistoryDataIndexes.distributorAddressOnChain,
      key: NftIssueHistoryDataIndexes.distributorAddressOnChain,
      render: (distributorAddressOnChain: { distributorAddress: string; chainName: string }) =>
        distributorAddressOnChain && (
          <div>
            <div className="d-flex align-items-center gap-2">
              <Address
                address={distributorAddressOnChain.distributorAddress}
                link={getExplorerLink(
                  distributorAddressOnChain.chainName,
                  distributorAddressOnChain.distributorAddress,
                  'address'
                )}
              />
            </div>
          </div>
        ),
    },
    {
      title: <Trans>Quantity</Trans>,
      dataIndex: NftIssueHistoryDataIndexes.quantity,
      key: NftIssueHistoryDataIndexes.quantity,
      className: 'text-end',
      render: (quantity: number) => <span>{quantity}</span>,
    },
    {
      // Giá trị 1 NFT
      title: (
        <>
          <Trans>1 NFT value</Trans> (VIC)
        </>
      ),
      dataIndex: NftIssueHistoryDataIndexes.denomination,
      key: NftIssueHistoryDataIndexes.denomination,
      className: 'text-end',
      render: (number: number) => <div className=" text-end">{formatCurrency(number, CurrencySymbols.VIC, true)}</div>,
    },
    {
      title: (
        <>
          <Trans>Service fee</Trans> (VIC)
        </>
      ),
      dataIndex: NftIssueHistoryDataIndexes.feeAmount,
      key: NftIssueHistoryDataIndexes.feeAmount,
      className: 'text-end',
      render: (number: number) => (
        <div className="text-end">{number ? formatCurrency(number, CurrencySymbols.VIC, true) : '0'}</div>
      ),
    },
    {
      title: (
        <>
          <Trans>Total</Trans> (VIC)
        </>
      ),
      dataIndex: NftIssueHistoryDataIndexes.totalAmount,
      key: NftIssueHistoryDataIndexes.totalAmount,
      className: 'text-end',
      render: (number: number) => (
        <div className="text-end">{number ? formatCurrency(number, CurrencySymbols.VIC, true) : '0'}</div>
      ),
    },
    {
      title: <Trans>Status</Trans>,
      dataIndex: 'status',
      key: 'status',
      className: 'text-center',
      render: (status: string) => {
        return <LabelStatus status={status} getStatusClass={getStatusClass} />;
      },
    },
    {
      title: <Trans>Transaction hash</Trans>,
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        const tx = items?.find((item: any) => item.id === id);
        const chain = getChain(tx.txHashOnChain.chainCode);
        return (
          <div>
            {tx && tx.txHashOnChain && (
              <div className="d-flex align-items-center gap-2">
                <span className="symbol symbol-25px">
                  <img src={chain?.logoUrl} className="mw-100 mh-100 p-0" alt="network" />
                </span>
                <div className="d-flex flex-wrap align-items-center">
                  <div>
                    <QueueTransactionHash tx={tx} />
                    <div className="">
                      <span className="text-gray-400 fw-semibold d-block fs-7">{tx.txHashOnChain.chainName}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          <Trans>Issue NFT</Trans>
        </h3>
        <div className="card-toolbar">
          <NftIssueAction />
        </div>
      </div>

      <div className="card-body">
        {children}
        <Table loading={loading} columns={columns} handleChange={handleChange} listModel={listModel} />
        {/* <Table {...{ handleChange, columns, loading, listModel: listModel }} /> */}
      </div>
    </div>
  );
};
