import React from 'react';
type Props = { fontSize?: number; inline?: boolean; zoom?: number };

const LoadingIcon: React.FC<Props> = ({ fontSize = 10, inline = true, zoom = 1 }) => {
  return (
    <span
      className="indicator-progress"
      style={{ display: inline ? 'inline' : 'block', fontWeight: '300', fontSize, zoom }}
    >
      <span className={'spinner-border spinner-border-sm align-middle ms-2'}></span>
    </span>
  );
};

export { LoadingIcon };
