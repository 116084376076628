/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import MerchantDistributorService from '../../services/MerchantDistributorService';
import { IDistributor } from '../../types/MerchantDistributor';
import { DistributorAddNewModal } from '../distributor/DistributorAddNewAction';
import Select from 'react-select';
import { ellipseAddress } from '../../helpers';
import { AiOutlinePlus } from 'react-icons/ai';
import { Trans, t } from '@lingui/macro';
import { Chains } from '../../types';

const FormikDistributorsSelect = ({
  formik,
  showDistributorAddNewModal,
  setShowDistributorAddNewModal,
}: {
  formik: any;
  showDistributorAddNewModal: boolean;
  setShowDistributorAddNewModal: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ label: string; image: string; value: string }[]>([]);

  useEffect(() => {
    getData();
  }, [formik.values.chainCode, showDistributorAddNewModal]);

  const getData = async () => {
    setLoading(true);
    const res: IDistributor[] = await MerchantDistributorService.getDistributorsByChainCode({
      chainCode: formik.values.chainCode,
    });
    if (res) {
      setData(
        res.map((item) => ({
          label: `${item.name}|${ellipseAddress(item.addresses[0].address)}`,
          image: `img/${item.addresses[0].chain.code.toLowerCase()}.svg`,
          value: item.addresses[0].address,
        }))
      );
    }
    setLoading(false);
  };
  const onHide = () => {
    setShowDistributorAddNewModal(!showDistributorAddNewModal);
  };

  const onAddItem = (items: { address: string; chainCode: Chains }[]) => {
    const item = items.find((i) => i.chainCode.toUpperCase() === formik.values?.chainCode.toUpperCase());
    formik.setFieldValue('distributorAddress', item?.address ?? '');
    getData();
    onHide();
  };

  if (loading) {
    return (
      <div className="mb-6">
        <label htmlFor="" className="form-label required">
          <Trans>NFT Recipient address</Trans>
        </label>
        <div className="mb-3 d-flex gap-3 position-relative" style={{ zIndex: 99 }}>
          <Select
            options={data}
            className="w-100"
            isClearable
            placeholder={<Trans>Input address</Trans>}
            isDisabled={true}
            noOptionsMessage={() => t`No options`}
            formatOptionLabel={(option) => {
              const [name, address] = option.label.split('|');
              return (
                <div className="d-flex gap-3 align-items-center ">
                  <img src={option.image} height={20} alt={name} />

                  <span>
                    {name} - {address}
                  </span>
                </div>
              );
            }}
          />
          <button className="btn btn-outline user-select-none py-2 rounded fw-semibold d-flex align-items-center  gap-2">
            <AiOutlinePlus className="fs-5" />
            <Trans>Add</Trans>
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      {!loading && (
        <div className="mb-6 position-relative" style={{ zIndex: 998 }}>
          <label htmlFor="" className="form-label required">
            <Trans>NFT Recipient address</Trans>
          </label>

          <div className="mb-3 d-flex gap-3 position-relative">
            <Select
              options={data}
              className="w-100"
              isClearable
              placeholder={<Trans>Input address</Trans>}
              noOptionsMessage={() => t`No options`}
              value={
                formik.values.distributorAddress &&
                data.find((i) => i.value === formik.values.distributorAddress.toLowerCase())
              }
              onChange={(item) => {
                formik.setFieldValue('distributorAddress', item?.value);
              }}
              formatOptionLabel={(option: any) => {
                const [name, address] = option.label.split('|');

                return (
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={option.image} height={20} alt={name} />
                    <span>
                      {name} - {address}
                    </span>
                  </div>
                );
              }}
            />

            <button
              className="btn btn-outline user-select-none py-2 rounded fw-semibold d-flex align-items-center gap-2"
              type="button"
              onClick={onHide}
            >
              <AiOutlinePlus />
              <Trans>Add</Trans>
            </button>
          </div>
          {formik.touched.distributorAddress && formik.errors.distributorAddress && (
            <div className="fv-plugins-message-container invalid-feedback_error">
              <div className="fv-help-block">{formik.errors.distributorAddress}</div>
            </div>
          )}
          {showDistributorAddNewModal && (
            <DistributorAddNewModal onAddItem={onAddItem} show={showDistributorAddNewModal} onHide={onHide} />
          )}
        </div>
      )}
    </>
  );
};

export default FormikDistributorsSelect;
