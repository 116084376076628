import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_TRANSACTION_REQUEST, TRANSACTION } from './actions';
import { getTransaction } from '../../services/TransactionService';
import { FetchTransaction, TransactionModel } from './types';

function* fetchTransaction({ payload }: FetchTransaction) {
  try {
    const response: TransactionModel = yield call(getTransaction, payload);
    yield put(TRANSACTION.SUCCESS(response));
  } catch (err) {
    const error: Error = err as Error;
    yield put(TRANSACTION.ERROR({ error: error.message }));
    console.log('Func: fetchTransactionSaga - PARAMS: err', err);
  }
}

function* settingsSaga() {
  yield takeEvery(GET_TRANSACTION_REQUEST, fetchTransaction);
}

export default settingsSaga;
