/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Trans } from '@lingui/macro';
import { IconFonts } from '../../../../helpers/IconFontHelpers';
import { MerchantType } from '../../../../types/Merchant';

// Thiết lập
const dashboard: Menu = {
  title: 'Tổng quan ví',
  translatedName: <Trans>Dashboard</Trans>,
  menus: [
    {
      name: 'Tổng quan ví',
      translatedName: <Trans>Dashboard</Trans>,
      id: 'Dashboard',
      to: '/dashboard',
      fontIcon: IconFonts.wallet,
    },
    {
      name: 'Lịch sử giao dịch',
      translatedName: <Trans>Transaction history</Trans>,
      id: 'transaction-history',
      to: '/transaction-history',
      fontIcon: 'las la-history' as IconFonts,
    },
    {
      name: 'Dịch vụ NFT',
      translatedName: <Trans>NFT service</Trans>,
      id: 'nft',

      iconImg: 'ico-nft',
      type: MerchantType.VIC_BASED,
      subMenus: [
        {
          name: 'Quản lý NFT đã phát hành',
          translatedName: <Trans>Issued NFT list</Trans>,
          id: 'list',
          to: '/list',
          type: MerchantType.VIC_BASED,
        },
        {
          name: 'Phát hành NFT mới',
          translatedName: <Trans>Issue new NFT</Trans>,
          id: 'issue-nft',
          to: '/issue-nft',
          type: MerchantType.VIC_BASED,
        },
        {
          name: 'Quản lý địa chỉ nhận NFT',
          translatedName: <Trans>Distributors management</Trans>,
          id: 'distributors',
          to: '/distributors',
          type: MerchantType.VIC_BASED,
        },
        {
          name: 'Lịch sử phát hành',
          translatedName: <Trans>NFT history</Trans>,
          id: 'nft-history',
          to: '/nft-history',
          type: MerchantType.VIC_BASED,
        },
      ],
    },
    {
      name: 'Đối soát', //'Đối soát',
      translatedName: <Trans>Reconciliation</Trans>,
      id: 'reportForreconciliation',
      to: '/reconciliation',
      fontIcon: IconFonts.reconcile,
    },
    {
      name: 'Cài đặt',
      translatedName: <Trans>Settings</Trans>,
      id: 'Settings',
      to: '/settings',
      fontIcon: 'las la-cog' as IconFonts,
    },
  ], //jackbereson
};

export const menuData: Menu[] = [dashboard];

export type MenuItem = {
  name: string;
  translatedName: any;
  id: string;
  requestId?: string;
  to?: string;
  type?: string;
  subMenus?: MenuItem[];
  fontIcon?: IconFonts;
  iconImg?: string;
  isShowNoti?: boolean;
};

type Menu = {
  title?: string;
  translatedName?: any;
  menus: Array<MenuItem>;
};
