import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Alert } from '../../helpers';
import clsx from 'clsx';
import { FaIcons } from '../../helpers/FaIconHelper';
import MerchantService from '../../services/MerchantService';
import { SETTINGS } from '../../store/settings/actions';
import { useDispatch } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import DataBox from '../form/DataBox';

function CallbackSetting({ callbackUrl }: { callbackUrl: string }) {
  const [openned, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!openned);
  };

  return (
    <div className="my-5">
      <label className="form-label d-flex gap-1 align-items-center ">
        <Trans>Callback URL</Trans>
      </label>
      <DataBox value={callbackUrl}>
        <Button variant="secondary" onClick={toggle}>
          <i className={clsx('cursor-pointer fs-4 mw-15px', FaIcons['far fa-edit'])} />
        </Button>
      </DataBox>
      {openned && <CallBackModal callBackUrl={callbackUrl} show={openned} onHide={toggle} />}
    </div>
  );
}

export default CallbackSetting;

const CallBackModal = ({ show, onHide, callBackUrl }: { show: boolean; onHide: () => void; callBackUrl: string }) => {
  const dispatch = useDispatch();
  const initialValues = {
    callBackUrl,
  };
  /* eslint-disable-next-line */
  const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  //Callback URL sai đinh dạng
  //Bạn chưa nhập callbackUrl
  const validationSchema = Yup.object().shape({
    callBackUrl: Yup.string()
      .matches(re, t`Callback URL is wrong format`)
      .required(t`Please input callback Url`),
  });

  const onSubmit = async (
    values: {
      callBackUrl: string;
    },
    { setSubmitting }: any
  ) => {
    try {
      await MerchantService.setCallbackUrl(values);
      Alert.success(t`Updated successfully`, true).then(() => {
        onHide();
        dispatch(SETTINGS.REQUEST());
      });
    } catch (error) {
      Alert.error(t`Updated unsuccessfully`, true);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      className={clsx('modal-sticky modal-sticky-lg modal-sticky-bottom-right')}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-dialog mw-450px"
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="modal-header border-0 ">
          <h2>
            <Trans>Callback URL</Trans>
          </h2>
          <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>
        <div className="modal-body py-0">
          <div className="mb-6">
            <label htmlFor="" className="form-label required">
              <Trans>Input callback url</Trans>
            </label>
            <input
              className="form-control"
              required
              type="url"
              {...formik.getFieldProps('callBackUrl')}
              autoComplete="off"
              placeholder={t`Input callback url`}
            />

            {formik.touched.callBackUrl && formik.errors.callBackUrl && (
              <div className="fv-plugins-message-container invalid-feedback_error">
                <div className="fv-help-block">{formik.errors.callBackUrl}</div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer border-0 pt-0">
          <button className="btn btn-outline" onClick={onHide}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn btn-primary">
            <Trans>Confirm</Trans>
          </button>
        </div>
      </form>
    </Modal>
  );
};
