import { useState } from 'react';
import DepositModal from './DepositModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsSelector } from '../../store/settings/selectors';
import { useAuth } from '../../store/auth';
import AuthService from '../../services/AuthService';
import { SETTINGS } from '../../store/settings/actions';
import { Alert } from '../../helpers';
import { Trans, t } from '@lingui/macro';

const DepositAction = () => {
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const onHide = () => setShow(!show);
  const settings = useSelector(getSettingsSelector);

  const toggle = async () => {
    let userMerchant;
    if (!show && auth) {
      userMerchant = await AuthService.getMe(auth.token);
      dispatch(SETTINGS.REQUEST());
    }

    if (userMerchant?.suspended) {
      Alert.error(
        t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
        true
      );
      return;
    }

    setShow(!show);
  };

  return (
    <div className="">
      <div className={settings.merchant.suspended ? 'opacity-75 ' : 'opacity-100'}>
        <button disabled={settings.merchant.suspended} className="btn btn-primary" onClick={toggle}>
          <Trans>Deposit Token</Trans>
        </button>
      </div>

      <DepositModal {...{ show, onHide }} />
    </div>
  );
};

export default DepositAction;
