/* eslint-disable  @typescript-eslint/no-explicit-any */

import { useEffect, useRef, useState } from 'react';
import { IDenomination, OTHER_DENOMINATION } from '../../types/MerchantNFT';
import MerchantNFTService from '../../services/MerchantNFTService';
import { formatCurrency } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';

import Select from 'react-select';
import { Trans, t } from '@lingui/macro';

interface Option {
  readonly label: string;
  readonly value: string;
}

const FormikNFTDenominationsSelect = ({
  formik,
  setDenomination,
  settings,
}: {
  formik: any;
  setDenomination: any;
  settings: {
    minCustomizedNFTValue: number;
    maxCustomizedNFTValue: number;
  };
}) => {
  const [loading, setLoading] = useState(false);
  const [denominations, setDenominations] = useState<IDenomination[]>([]);
  const [data, setData] = useState<Option[]>([]);
  const selectInputRef = useRef<any>();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (denominations.length > 0) {
      const filteredDenos = denominations.filter(
        (deno) => deno.value >= settings.minCustomizedNFTValue && deno.value <= settings.maxCustomizedNFTValue
      );

      const selectData = filteredDenos
        .sort((a, b) => a.value - b.value)
        .map((item) => ({
          label: `${formatCurrency(item.value || 0, CurrencySymbols.VIC)}`,
          value: item.id || '',
        }));
      setData([
        ...selectData,
        {
          label: t`Other`,
          value: `${OTHER_DENOMINATION}`,
        },
      ]);
      onClear();
    }
  }, [settings, denominations]);

  const onClear = () => {
    selectInputRef?.current?.clearValue();
  };

  const getData = async () => {
    setLoading(true);
    const res = await MerchantNFTService.getDenominations();
    if (res) {
      setDenominations(res);
    }
    setLoading(false);
  };

  return (
    <div className="mb-6">
      <label htmlFor="" className="form-label required">
        {/* Giá trị NFT */}
        <Trans>NFT value</Trans>
      </label>
      <div className="d-flex gap-3 flex-wrap position-relative" style={{ zIndex: 999 }}>
        <Select
          options={data}
          className="w-100"
          isClearable
          placeholder={<Trans>Input value</Trans>}
          ref={selectInputRef}
          noOptionsMessage={() => t`No options`}
          onChange={(item) => {
            formik.setFieldValue('value', 0);
            formik.setFieldValue('denominationId', item?.value);
            if (item?.value === OTHER_DENOMINATION) {
              setDenomination({
                id: OTHER_DENOMINATION,
                name: 'Khác',
              });
            } else {
              const selected = denominations.find((demon) => demon.id === item?.value);
              setDenomination(selected);
            }
          }}
          isLoading={loading}
        />
      </div>
      {formik.touched.denominationId && formik.errors.denominationId && (
        <div className="fv-plugins-message-container invalid-feedback_error">
          <div className="fv-help-block">{formik.errors.denominationId}</div>
        </div>
      )}
    </div>
  );
};

export default FormikNFTDenominationsSelect;
