import { createSelector } from 'reselect';

import { RootState } from '../RootReducer';

const getPending = (state: RootState) => state.token.pending;

const getTokens = (state: RootState) => state.token.tokens;

const getError = (state: RootState) => state.token.error;

export const getTokensSelector = createSelector(getTokens, (tokens) => tokens);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
