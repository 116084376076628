import { call, put, takeEvery } from 'redux-saga/effects';
import TokenService from '../../services/TokenService';
import { TokenModel } from '../../types';
import { fetchTokenFailure, fetchTokenSuccess } from './actions';
import { GET_TOKENS_REQUEST } from './actionTypes';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* fetchTokenSaga() {
  try {
    const tokenData: { page: number | object; items: TokenModel[] } = yield call(TokenService.getTokenList);
    yield put(fetchTokenSuccess({ tokens: tokenData.items })); // why
  } catch (e) {
    const error = e as Error;
    console.log('GET DATA ERROR', error);
    yield put(fetchTokenFailure({ error: error.message }));
  }
}

// Starts fetchUser on each dispatched USER_FETCH_REQUESTED action
// Allows concurrent fetches of user
function* tokenSaga() {
  yield takeEvery(GET_TOKENS_REQUEST, fetchTokenSaga);
}

export default tokenSaga;
