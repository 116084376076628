import { GET_TRANSACTION_REQUEST, GET_TRANSACTION_SUCCESS, GET_TRANSACTION_FAILED } from './actions';
import { TransactionActions, TransactionState } from './types';

const initialState: TransactionState = {
  pending: false,
  error: null,
  data: {
    page: 0,
    perPage: 0,
    totalPages: 0,
    totalItems: 0,
    items: [],
    export: [],
  },
};

const reducer = (state = initialState, action: TransactionActions) => {
  switch (action.type) {
    case GET_TRANSACTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
      };
    case GET_TRANSACTION_FAILED:
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
export default reducer;
