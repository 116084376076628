import clsx from 'clsx';
import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import { Color } from 'react-bootstrap/esm/types';
import { Link } from 'react-router-dom';

type Type = 'submit' | 'reset' | 'button';
export type Size = 'sm' | 'lg';

export interface Props extends ButtonProps {
  type?: Type;
  size?: Size;
  href?: string;
  target?: string;
  textColor?: Color;
  backgroundColor?: Color | string;
  isHover?: boolean;
  isOpacity?: boolean;
  isDropDown?: boolean;
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Button: React.FC<Props> = ({
  type,
  size,
  href,
  target,
  textColor,
  backgroundColor,
  isHover,
  isOpacity,
  isDropDown,
  className,
  iconClassName,
  onClick,
  children,
  ...innerProps
}) => {
  return href ? (
    <Link
      className={clsx(
        'btn',
        backgroundColor && isHover && !isOpacity ? `btn-hover-${backgroundColor}` : `btn-${backgroundColor}`,
        size && `btn-${size}`,
        textColor && `text-${textColor}`,
        {
          'btn-spacing': iconClassName && children,
          'dropdown-toggle': isDropDown,
          'btn-hover': isHover,
          'btn-opacity': isOpacity,
        },
        className
      )}
      to={href}
      target={target}
      onClick={onClick}
    >
      {iconClassName && <i className={iconClassName}></i>}
      {children}
    </Link>
  ) : (
    <button
      type={type}
      className={clsx(
        'btn',
        backgroundColor && isHover && !isOpacity ? `btn-hover-${backgroundColor}` : `btn-${backgroundColor}`,
        size && `btn-${size}`,
        textColor && `text-${textColor}`,
        {
          'btn-spacing': iconClassName && children,
          'dropdown-toggle': isDropDown,
          'btn-hover': isHover,
          'btn-opacity': isOpacity,
        },
        className
      )}
      data-bs-toggle={isDropDown ? 'dropdown' : ''}
      aria-expanded="false"
      onClick={onClick}
      {...innerProps}
    >
      {iconClassName && <i className={iconClassName}></i>}
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  backgroundColor: 'primary',
};

export default Button;
