import { PropsWithChildren } from 'react';
import { t } from '@lingui/macro';
import OverLayLoading from './common/OverlayLoading';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorWidget from '../errors/ErrorWidget';

export const FetchStateComponent: React.FC<PropsWithChildren<{ isLoading: boolean; error: any }>> = ({
  children,
  isLoading,
  error,
}): any => {
  if (error) {
    return <ErrorWidget error={t`Error fetching data`} resetErrorBoundary={() => {}} />;
  }

  return (
    <ErrorBoundary fallback={<ErrorWidget error={error} resetErrorBoundary={() => {}} />}>
      <div className={`overlay ${isLoading && 'overlay-block'} card-rounded p-0`}>
        {children}
        {isLoading && <OverLayLoading />}
      </div>
    </ErrorBoundary>
  );
};
