import { TListModel } from '../types';
import { IFilterSnapshot, ISnapshot } from '../types/Snapshot';
import AxiosInstance from './axios';

class DailySnapshotService {
  getAll = async (params: IFilterSnapshot) => {
    try {
      const url = '/merchant/daily-snapshots';

      const data: TListModel<ISnapshot> = await AxiosInstance.get(url, { params }).then((res) => res.data);

      return {
        status: true,
        msg: 'Success',
        data,
      };
    } catch (e: any) {
      console.log('[API] Can not get data', e);
      let msg = e.message;
      if (e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message;
      }
      return {
        status: false,
        msg,
        data: e?.response?.data,
      };
    }
  };

  verifySnapshot = async (snapshotId: string, note: string) => {
    const url = `/merchant/daily-snapshots/${snapshotId}/verify`;

    try {
      const result = await AxiosInstance.post(url, {
        note,
      }).then((res) => res.data);

      return { status: true, msg: result.message };
    } catch (e) {
      console.error('[API] Can not get exchange', e);
      return {
        status: false,
        msg: 'ERROR: Can not update data',
      };
    }
  };

  getById = async (snapshotId: string) => {
    const url = `/merchant/daily-snapshots/${snapshotId}`;
    try {
      const data: ISnapshot = await AxiosInstance.get(url).then((res) => res.data);
      return {
        status: true,
        msg: 'Success',
        data,
      };
    } catch (e: any) {
      console.log('[API] Can not get data', e);
      let msg = e.message;
      if (e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message;
      }
      return {
        status: false,
        msg,
        data: e?.response?.data,
      };
    }
  };
  getDownload = async (snapshotId: string) => {
    const url = `/merchant/daily-snapshots/${snapshotId}/export-link`;
    console.log('getById', url);
    try {
      const data: TListModel<ISnapshot> = await AxiosInstance.get(url).then((res) => res.data);
      return {
        status: true,
        msg: 'Success',
        data,
      };
    } catch (e: any) {
      console.log('[API] Can not get data', e);
      let msg = e.message;
      if (e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message;
      }
      return {
        status: false,
        msg,
        data: e?.response?.data,
      };
    }
  };

  getTransaction = async (snapshotId: string, params: IFilterSnapshot): Promise<TListModel<ISnapshot>> => {
    try {
      const url = `/merchant/daily-snapshots/${snapshotId}/transactions`;

      return await AxiosInstance.get(url, { params }).then((res) => res.data);
    } catch (e: any) {
      console.log('[API] Can not get data', e);
      // let msg = e.message;
      // if (e.response && e.response.data && e.response.data.message) {
      //   msg = e.response.data.message;
      // }
      throw e;
    }
  };
}

export default DailySnapshotService;
