import Button from '../../components/common/Button';
import { FaIcons } from '../../helpers/FaIconHelper';
import { getSettingsSelector } from '../../store/settings/selectors';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';

const NftIssueAction = () => {
  const settings = useSelector(getSettingsSelector);
  const nav = useNavigate();

  const onHide = () => {
    if (settings.merchant.suspended) {
      return;
    }
    nav('/issue-nft');
  };

  // console.log('show', show)
  return (
    <Button
      textColor="white"
      isHover
      backgroundColor="primary"
      className=""
      iconClassName={`${FaIcons['fas fa-plus']} text-white fs-3`}
      onClick={onHide}
      disabled={settings.merchant.suspended}
    >
      <Trans>Issue NFT</Trans>
    </Button>
  );
};

export default NftIssueAction;
