import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Select } from '../../components/Select';
import { DateRangePicker } from '../../components/DateRangePicker';
import { FORMAT_DATE_FILTER, FORMAT_DATE_FILTER_START } from '../../constants';
import { TxStatus, TxTypes, TxTypesByMulti } from '../../types/Transaction';
import { IFilterTransaction } from '../../store/transaction/types';
import TabItem from '../../components/TabItem';
import _ from 'lodash';

import { useAuth } from '../../store/auth';
import { Trans, t } from '@lingui/macro';
import TransString, { StringTypes } from '../../components/TransString';
import { MerchantType } from '../../types/Merchant';
import { Transactions } from '../../components/transaction/Transactions';
import MerchantService from '../../services/MerchantService';
import { Alert } from '../../helpers/AlertHelpers';
import { useQuery } from '@tanstack/react-query';
import { getTransaction } from '../../services/TransactionService';
import { FetchStateComponent } from '../../components/FetchStateComponent';
import { DateRange } from '../../components/Date/DateRangePicker';
import { formatDateRangeFilter } from '../../helpers/UtilsHelpers';

const PageTransactionHistory: React.FC = () => {
  const [filter, setFilter] = useState<IFilterTransaction>({
    fromDate: moment().subtract(6, 'days').startOf('day').format(FORMAT_DATE_FILTER_START),
    toDate: moment().endOf('day').format(FORMAT_DATE_FILTER),
  });
  // const [activeDate, setActiveData] = useState('');
  const { currentUser } = useAuth();

  const handleChange = (params: IFilterTransaction) => {
    const p = params;
    if (!params.page) {
      p.page = 1;
    }
    setFilter((prev) => ({
      ...prev,
      ...p,
    }));
  };

  const numOfDay = 7;
  const filterType =
    currentUser && currentUser.type === MerchantType.VIC_BASED
      ? Object.keys(TxTypes).map((key) => ({
          label: <TransString value={key} type={StringTypes.TYPE} />,
          value: key === 'ALL' ? '' : key,
        }))
      : TxTypesByMulti.map((key) => ({
          label: <TransString value={key} type={StringTypes.TYPE} />,
          value: key === 'ALL' ? '' : key,
        }));

  const filterStatus = Object.keys(TxStatus).map((key) => ({
    label: <TransString value={key} />,
    value: key === 'ALL' ? '' : key,
  }));
  const handleChangeDate = (dateRange: DateRange) => {
    setFilter({ ...filter, page: 1, ...formatDateRangeFilter(dateRange) });
  };

  const onChangeValue = (e: { target: { value: string } }) => {
    handleChange({ paymentId: e.target.value, page: 1 });
  };

  const debounceFn = useMemo(() => _.debounce(onChangeValue, 1000), []);

  const exportExcel = async () => {
    try {
      await MerchantService.exportExcel(filter);
      //}
    } catch (e: any) {
      Alert.error(t`Your download priority is currently limited within ${numOfDay} days`);
    }
  };

  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['list-transaction'],
    queryFn: async () => {
      const response = await getTransaction(filter);
      return response;
    },
  });
  useEffect(() => {
    refetch();
  }, [filter]);
  return (
    <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
      <Transactions transactions={data} filter={filter} handleChange={handleChange} isLoading={isLoading}>
        <div className="row align-items-end mb-7">
          <div className="col">
            <label className="form-label">
              <Trans>Transaction status</Trans>
            </label>
            <Select
              options={filterStatus}
              name={filter.status || ''}
              onChange={(value) => handleChange({ status: value, page: 1 })}
            />
          </div>
          <div className="col">
            <label className="form-label">
              <Trans>Transaction type</Trans>
            </label>
            <Select
              options={filterType}
              name={filter.txType || ''}
              onChange={(value) => handleChange({ txType: value, page: 1 })}
            />
          </div>
          <div className="col">
            <label className="form-label">
              <Trans>Reference code</Trans>
            </label>
            <input className="form-control" onChange={debounceFn} />
          </div>
          <div className="col-auto">
            <DateRangePicker
              onChangeDate={handleChangeDate}
              extraActions={
                <div onClick={exportExcel}>
                  <TabItem
                    label={
                      // ['oneDate', 'oneWeek', ''].includes(activeDate) ? (
                      <i className="fas fa-download text-dark" />
                      // ) : (
                      //   <div className="h-20px"></div>
                      // )
                    }
                    onClick={() => {}}
                  />
                  {/* {['oneDate', 'oneWeek', ''].includes(activeDate) && (
                  <TabItem label={<i className="fas fa-download text-dark" />} onClick={() => {}} />
                )} */}
                </div>
              }
            />
          </div>
        </div>
      </Transactions>
    </FetchStateComponent>
  );
};

export default PageTransactionHistory;
