import React from 'react';

import { Trans } from '@lingui/macro';
import DailySnapshotList from '../../components/reconciliation/DailySnapshotList';

const PageReconciliation: React.FC = () => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">
          <Trans>Daily reconciliation</Trans>
        </h3>
      </div>
      <div className="card-body">
        <DailySnapshotList />
      </div>
    </div>
  );
};

export default PageReconciliation;
