import { createSelector } from 'reselect';
import { RootState } from '../RootReducer';

const getSettings = (state: RootState) => state.settings.data;

const getPending = (state: RootState) => state.settings.pending;

const getError = (state: RootState) => state.settings.error;

export const getSettingsSelector = createSelector(getSettings, (settings) => settings);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
