import { GET_CURRENCIES_REQUEST, GET_CURRENCIES_FAILED, GET_CURRENCIES_SUCCESS } from './actionTypes';
import { CurrencyActions, CurrencyState } from './types';

const initialState: CurrencyState = {
  pending: false,
  currencies: [],
  error: null,
};

const reducer = (state = initialState, action: CurrencyActions) => {
  switch (action.type) {
    case GET_CURRENCIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        pending: false,
        currencies: action.payload.currencies,
        error: null,
      };
    case GET_CURRENCIES_FAILED:
      return {
        ...state,
        pending: false,
        currencies: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
