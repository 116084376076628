/* eslint-disable  @typescript-eslint/no-explicit-any */
import moment, { Moment } from 'moment';
import { ApiListModel, DateRangeTypes } from '../types';
import { CurrencyChain } from '../types/CurrencyBalance';
import { TxFeeTypes, TxTypes } from '../types/Transaction';
import { interval } from 'rxjs';
import { take, delay } from 'rxjs/operators';
import { t } from '@lingui/macro';
import { MerchantType } from '../types/Merchant';
import { DateRange } from '../components/Date/DateRangePicker';
import { FORMAT_DATE_FILTER, FORMAT_DATE_FILTER_START } from '../constants';

export function wrapPromise(promise: Promise<ApiListModel | any>) {
  let status = 'pending';
  let response: ApiListModel | any;

  const suspender = promise.then(
    (res) => {
      status = 'success';
      response = res;
    },
    (err) => {
      status = 'error';
      response = err;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        // throw response;
        return false;
      } else if (status === 'success') {
        return response;
      }
    },
  };
}

export const getDateRangeByRageType = (rageType: DateRangeTypes): { startDate: string; endDate: string } => {
  const startDate: Moment = moment();
  const endDate: Moment = moment();
  switch (rageType) {
    case 'day':
      startDate.subtract(1, 'days');
      break;
    case 'week':
      startDate.subtract(1, 'weeks');
      break;
    case 'month':
      startDate.subtract(1, 'months');
      break;
    case 'year':
      startDate.subtract(1, 'years');
      break;
    default:
      break;
  }

  return {
    startDate: startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
    endDate: endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
  };
};

export const getChainColor = (chainName: string): string => {
  let res: string = 'info';
  switch (chainName) {
    case 'VCHAIN':
      res = 'primary';
      break;
    case 'BINANCE':
      res = 'warning';
      break;
    case CurrencyChain.POLYGON:
      res = 'success';
      break;
    default:
      break;
  }
  return res;
};
export const getTransactionName = (status: string) => {
  switch (status) {
    case 'COMPLETED':
      return t`Transactions Completed`;
    case 'PENDING':
      return t`Pending transactions`;
    case 'INCOMING':
      return t`Incoming transactions`;
    case 'OUTGOING':
      return t`Outgoing transactions`;
  }
};

export const getTransactionImage = (status: string) => {
  switch (status) {
    case 'COMPLETED':
      return 'tx-transaction';
    case 'PENDING':
      return 'tx-pending';
    case 'INCOMING':
      return 'tx-in';
    case 'OUTGOING':
      return 'tx-out';
  }
};

export const getMerchantType = (type: string | any) => {
  switch (type) {
    case MerchantType.VIC_BASED:
      return 'Vic Based';
    case MerchantType.MULTI_CURRENCY:
      return 'Multi currency';
  }
};
const filterByKey = (items: any[], key: string, value?: string | number) => {
  if (!items) {
    return [];
  }
  return items.filter((item) => item[key] === value);
};
const colorRenderChart = (chainName: string) => {
  switch (chainName) {
    case 'BINANCE':
      return '#FAC435';
    case CurrencyChain.POLYGON:
      return '#627EEA';
    case 'VCHAIN':
      return '#4153AF';
  }
};

const checkPoolBalance = (fromToken: any, toToken: any): string => {
  let error = '';
  if (
    fromToken.balance.total !== 0 &&
    toToken.balance.total !== 0 &&
    (fromToken?.inputAmount || toToken?.inputAmount)
  ) {
    const fromAmount = fromToken.balance.total + (fromToken?.inputAmount || 0);
    const toAmount = toToken.balance.total + (toToken?.inputAmount || 0);
    if (fromAmount < 0 && toAmount < 0) {
      error = `Không đủ ${fromToken.symbol} và ${toToken.symbol}`;
    }
    if (fromAmount < 0 && toAmount > 0) {
      error = `Không đủ ${fromToken.symbol}`;
    }
    if (fromAmount > 0 && toAmount < 0) {
      error = `Không đủ - ${toToken.symbol}`;
    }
  }

  return error;
};
const colorRenderTypes = (status: string) => {
  switch (status) {
    case TxTypes.CONSUMED_NFT:
    case TxTypes.USER_PAY:
    case TxTypes.DEPOSIT:
      return 'text-success';
    case TxTypes.WITHDRAW:
    case TxTypes.TRANSFER:
    case TxTypes.ISSUED_NFT:
      return 'text-danger';
    default:
      return 'text-dark';
  }
};
export const renderTxFeeType = (type: string) => {
  switch (type) {
    case TxFeeTypes.CONSUMED_NFT:
    case TxFeeTypes.DEPOSIT:
    case TxFeeTypes.USER_PAY:
      return '+';

    case TxFeeTypes.WITHDRAW:
    case TxFeeTypes.TRANSFERRED_NFT:
    case TxFeeTypes.TRANSFER:
    case TxFeeTypes.FEE:
    case TxFeeTypes.ISSUED_NFT:
      return '-';
  }
};
const getGasFee = (types: string, gasFee: number, gasless: boolean) => {
  const type = TxTypes;
  switch (types) {
    case type.CONSUMED_NFT:
    case type.USER_PAY:
      return gasless ? t`Free` : 'N/A';
    case type.DEPOSIT:
      return 'N/A';
  }
};

const UtilHelpers = {
  getChainColor,
  getDateRangeByRageType,
  filterByKey,
  colorRenderChart,
  checkPoolBalance,
  colorRenderTypes,
  getGasFee,
};

export default UtilHelpers;

export const buildQueryString = (args: any) => {
  const params = new URLSearchParams({});
  /* eslint-disable  prefer-const */
  for (let key in args) {
    /* eslint-disable  no-prototype-builtins */
    if (args.hasOwnProperty(key)) {
      if (args[key]) {
        if (Array.isArray(args[key])) {
          for (const value of args[key]) {
            params.append(key, value.toString());
          }
        } else {
          params.append(key, args[key].toString());
        }
      }
    }
  }
  return params.toString();
};

export const waiting = (seconds: number) =>
  new Promise<void>((resolve) => {
    interval(seconds)
      .pipe(
        take(1),
        delay(0) // Đảm bảo delay để chờ 1 giây
      )
      .subscribe(() => {
        resolve();
      });
  });
export const formatDateRangeFilter = (dateRange: DateRange) => {
  if (!dateRange || !dateRange?.startDate || !dateRange?.endDate) return { fromDate: undefined, toDate: undefined };

  const formatedDateRange = {
    fromDate: moment(dateRange.startDate).format(FORMAT_DATE_FILTER_START),
    toDate: moment(dateRange.endDate).format(FORMAT_DATE_FILTER),
  };

  return formatedDateRange;
};
