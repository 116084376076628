import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { checkIsActive } from '../../../helpers';
import { menuData, MenuItem } from './utils/menuData';
import { Menu } from './utils/Menu';

const SidebarMenuMain = () => {
  const { pathname } = useLocation();

  const [seledtedId, setSelectedId] = useState<string | undefined>();
  const id = localStorage.getItem('menu-selected');

  const handleSelectMenu = (id: string) => {
    setSelectedId(id);
    localStorage.setItem('menu-selected', id);
  };

  const setMenuSelected = (id: string) => {
    setSelectedId(id);
    localStorage.setItem('menu-selected', id);
  };

  const updateMenuSelected = (menus?: Array<MenuItem>) => {
    menus?.forEach((i) => {
      if (checkIsActive(pathname, i.to, true)) {
        if (i?.subMenus) {
          updateMenuSelected(i.subMenus);
        } else {
          setMenuSelected(i.id);
        }
      }
    });
  };

  useEffect(() => {
    id && setSelectedId(id);

    const flattenedMenu = menuData
      .map(({ menus }) =>
        menus.map(({ subMenus, ...innerProps }) => {
          if (subMenus) {
            return [...subMenus, innerProps];
          }
          return [innerProps];
        })
      )
      .flat(2);

    updateMenuSelected(flattenedMenu);
  }, [pathname]);

  return (
    <>
      {menuData?.map((i, index) => (
        <div key={`menu-${index.toString()}`}>
          {i?.title && (
            <div className="menu-item">
              <div className="menu-content pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">{i.translatedName}</span>
              </div>
            </div>
          )}

          <Menu menuData={i?.menus} onClick={handleSelectMenu} seledtedId={seledtedId} />
        </div>
      ))}
    </>
  );
};

export { SidebarMenuMain };
