import { t } from '@lingui/macro';
import Swal from 'sweetalert2';

const success = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'success',
    confirmButtonText: t`Ok`,
    showConfirmButton,
  });
const error = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'error',
    confirmButtonText: t`Ok`,
    showConfirmButton,
  });
const warning = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'warning',
    confirmButtonText: t`Ok`,
    showConfirmButton,
  });

const info = (msg: string, isHtml?: boolean, showConfirmButton = true) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'info',
    confirmButtonText: t`Ok`,
    showConfirmButton,
  });
const confirm = (msg: string, isHtml?: boolean) =>
  Swal.fire({
    text: msg,
    html: isHtml ? msg : '',
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: t`Yes`,
    cancelButtonText: t`No`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      return true;
    } else {
      return false;
    }
  });
const Alert = {
  success,
  error,
  warning,
  info,
  confirm,
};
export { Alert };
