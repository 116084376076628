import { AiOutlineExclamationCircle, AiOutlineWallet } from 'react-icons/ai';
import { Trans } from '@lingui/macro';
import WithTooltip from './WithTooltip';
import { formatCurrency, formatNumber } from '../helpers';
import DepositAction from './deposit/DepositAction';
import WithdrawAction from './Withdraw/WithdrawAction';
import { Totalfiatbalance } from '../types/MerchantNFT';
import { LoadingPending } from './atom/LoadingPending';

export const UserCard = ({ exFiat, loadData }: { exFiat?: Totalfiatbalance | undefined; loadData: () => void }) => {
  return (
    <div className="card card-flush h-xl-100 pt-5">
      <div className="card-body">
        {exFiat ? (
          <div className="">
            <div className="d-flex gap-2 mb-3">
              <AiOutlineWallet size={24} />
              <span className="fs-4">
                <Trans>Total balances</Trans>
              </span>
              <div>
                <WithTooltip id="tooltip-1" tooltip={<Trans>Total balances note</Trans>}>
                  <AiOutlineExclamationCircle />
                </WithTooltip>
              </div>
            </div>
            <div className="fw-bold mb-3" style={{ fontSize: 24 }}>
              ~{formatCurrency(exFiat.fiatTotalBalance, exFiat?.currency)}
            </div>
            <div className="d-flex gap-2 mb-3">
              <span className="">
                <Trans>Asset changes today</Trans>:
              </span>
              {exFiat?.currency}
              <span className={exFiat.fiatBalanceFluctuations >= 0 ? 'text-green' : 'text-danger'}>
                <b>
                  {exFiat.fiatBalanceFluctuations >= 0 ? (
                    <>+{formatCurrency(exFiat.fiatBalanceFluctuations, exFiat?.currency, true)}</>
                  ) : (
                    formatCurrency(exFiat.fiatBalanceFluctuations, exFiat?.currency, true)
                  )}{' '}
                  ({exFiat.fiatBalanceFluctuationsPercent ? formatNumber(exFiat.fiatBalanceFluctuationsPercent, 2) : 0}
                  %)
                </b>
              </span>
            </div>

            <div className="d-flex gap-3 pt-3">
              <DepositAction />
              <WithdrawAction loadData={loadData} />
            </div>
          </div>
        ) : (
          <LoadingPending />
        )}
      </div>
    </div>
  );
};
