import { Trans } from '@lingui/macro';
import DateRangeSelect from '../Date/DateRangePicker';
import { useState } from 'react';
import { StaticRange } from 'react-date-range';
import { Optional } from 'react-date-range/_util';
import FormatHelper from '../../helpers/FormatHelpers';
import { isSameDay } from 'date-fns';
const ReconciliationFilter = ({
  dateRange,
  onSelect,
  customRanges,
  isAlignRight,
}: {
  dateRange?: { startDate: Date; endDate: Date };
  onSelect?: any;
  customRanges?: Optional<StaticRange, 'isSelected'>[];
  isAlignRight: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<any>(dateRange);
  const handleSelect = (selectedRanges: any) => {
    setRange(selectedRanges[0]);
    onSelect(selectedRanges[0]);
    setOpen(false);
  };

  const clearFilter = () => {
    setRange(null);
    onSelect(null);
  };
  return (
    <div className="row mb-6">
      <div className="col-4">
        <label className="col-form-label fw-bold fs-6">
          <Trans>Choose time</Trans>
        </label>
        <div>
          <button
            className="btn btn-outline btn-outline fw-normal d-flex gap-2 align-items-center"
            onClick={(e) => {
              if ((e.target as HTMLElement).tagName !== 'I') {
                setOpen(!open);
              }
            }}
          >
            {range ? (
              <>
                <span className="">
                  {' '}
                  {(range.startDate && FormatHelper.formatDate(range.startDate)) || 'From date'}
                </span>
                {!isSameDay(range.startDate, range.endDate) && (
                  <>
                    <span>-</span>
                    <span className=""> {(range.endDate && FormatHelper.formatDate(range.endDate)) || 'To date'}</span>
                  </>
                )}
              </>
            ) : (
              <span className="text-placeholder">
                <Trans>Select Date</Trans>
              </span>
            )}
            {range ? (
              <span onClick={clearFilter} className="bg-transparent border-none">
                <i className="fas fa-times fa-lg"></i>
              </span>
            ) : (
              <img src="/img/icons/duotune/general/gen014.svg" className="w-20px h-12px" alt="Calendar" />
            )}
          </button>
          <DateRangeSelect
            onSelect={handleSelect}
            show={open}
            customRanges={customRanges}
            dateRange={dateRange}
            isAlignRight={isAlignRight}
          />
        </div>
      </div>
    </div>
  );
};

export default ReconciliationFilter;
