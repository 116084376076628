import { Trans } from '@lingui/macro';
import { isSameDay } from 'date-fns';
import moment from 'moment';
import { Range } from 'react-date-range';
const staticRangeHandler = {
  range: {},
  isSelected(range: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges: any) {
  return ranges.map((range: Range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: <Trans>Today</Trans>,
    range: () => ({
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    }),
  },
  {
    label: <Trans>Yesterday</Trans>,
    range: () => ({
      startDate: moment().subtract(1, 'day').startOf('day').toDate(),
      endDate: moment().subtract(1, 'day').endOf('day').toDate(),
    }),
  },
  {
    // 7 ngày gần nhất
    label: <Trans>Last 7 days</Trans>,
    range: () => ({
      startDate: moment().subtract(6, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    }),
  },
  {
    //Tháng qua
    label: <Trans>Past month</Trans>,
    range: () => ({
      startDate: moment().subtract(29, 'day').toDate(),
      endDate: moment().endOf('day').toDate(),
    }),
  },
  {
    //
    label: <Trans>Last 3 months</Trans>,
    range: () => ({
      startDate: moment().subtract(89, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    }),
  },

  {
    //Năm qua
    label: <Trans>Past year</Trans>,
    range: () => ({
      startDate: moment().subtract(364, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    }),
  },
]);

//export type SelectRange = Range;
export type SelectRange = 'oneDate' | 'oneWeek' | 'oneMonth' | 'threeMonth' | 'oneYear' | 'all';

export const selectDateRange = (key: SelectRange) => {
  const dates = {
    oneDate: {
      startDate: moment().startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
      active: 'oneDate',
    },
    oneWeek: {
      startDate: moment().subtract(6, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
      active: 'oneWeek',
    },

    oneMonth: {
      startDate: moment().subtract(29, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
      active: 'oneMonth',
    },
    threeMonth: {
      startDate: moment().subtract(89, 'day').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
      active: 'threeMonth',
    },
    oneYear: {
      startDate: moment().subtract(364, 'days').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      key: 'selection',
      active: 'oneYear',
    },
    all: {
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
      active: 'all',
    },
  };

  return dates[key as keyof typeof dates];
};
