import { DashboardModel } from './types';
import { IFilterTransaction } from '../transaction/types';

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED';

export const DASHBOARD = {
  REQUEST: (payload: IFilterTransaction) => {
    return {
      type: GET_DASHBOARD_REQUEST,
      payload,
    };
  },
  SUCCESS: (payload: DashboardModel) => {
    return {
      type: GET_DASHBOARD_SUCCESS,
      payload,
    };
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  ERROR: (payload: any) => {
    return {
      type: GET_DASHBOARD_FAILED,
      payload: {
        data: {},
        ...payload,
      },
    };
  },
};
