import { createSelector } from 'reselect';
import { RootState } from '../RootReducer';

const getDashboard = ({ dashboard }: RootState) => dashboard;

const getPending = ({ dashboard }: RootState) => dashboard.pending;

const getError = ({ dashboard }: RootState) => dashboard.error;

export const getDashboardSelector = createSelector(getDashboard, (dashboard) => dashboard);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
