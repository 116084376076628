import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsSelector } from '../../store/settings/selectors';
import WithdrawalModal from './WithdrawalModal';
import { SETTINGS } from '../../store/settings/actions';
import AuthService from '../../services/AuthService';
import { useAuth } from '../../store/auth';
import { Alert } from '../../helpers';
import { Trans, t } from '@lingui/macro';

const WithdrawAction = ({ loadData }: { loadData: any }) => {
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const settings = useSelector(getSettingsSelector);

  const toggle = async () => {
    let userMerchant;
    if (!show && auth) {
      userMerchant = await AuthService.getMe(auth.token);
      dispatch(SETTINGS.REQUEST());
    }

    if (userMerchant?.suspended) {
      //Tài khoản của bạn đang tạm khóa. Xin vui lòng liên hệ với bộ phận kinh doanh để biết thêm chi tiết hoặc hỗ trợ mở khóa
      Alert.error(
        t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
        true
      );
      return;
    }
    setShow(!show);
  };

  return (
    <div className="">
      <div className={!settings.merchant.suspended ? 'opacity-75' : 'opacity-100'}>
        <button disabled={settings.merchant.suspended} className="btn btn-light-primary " onClick={toggle}>
          <Trans>Withdraw Token</Trans>
        </button>
      </div>
      {show && <WithdrawalModal {...{ show, onHide: toggle, loadData }} />}
    </div>
  );
};

export default WithdrawAction;
