import React from 'react';
import clsx from 'clsx';
import { KTSVG } from '../../../helpers';
import { WithChildren } from '../../../helpers/react18MigrationHelpers';
import { IconFonts } from '../../../helpers/IconFontHelpers';

type Props = {
  title: string;
  imgIcon?: string;
  fontIcon?: IconFonts;
  hasBullet?: boolean;
  isActive?: boolean;
  iconImg?: string;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  title,
  imgIcon,
  fontIcon,
  iconImg,
  hasBullet,
  isActive,
}) => {
  return (
    <div className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')} data-kt-menu-trigger="click">
      <span className="menu-link d-flex gap-2 items-center">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}

        {imgIcon && (
          <span className="menu-icon">
            <KTSVG path={imgIcon} className="svg-icon-2" />
          </span>
        )}
        {iconImg && (
          <span className={clsx('me-1')}>
            <img src={`/img/${iconImg}.svg`} />
          </span>
        )}

        {fontIcon && <span className={clsx('fs-3', fontIcon)}></span>}

        <span className="menu-title">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx('menu-sub menu-sub-accordion', {
          'menu-active-bg': isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
