import React, { useEffect, useState } from 'react';
import { FORMAT_DATE_FILTER, FORMAT_DATE_FILTER_START } from '../../constants';
import { IFilterTransaction } from '../../store/transaction/types';
import moment from 'moment';
import { SummaryCards } from '../../components/SummaryCards';
import { DateRangePicker } from '../../components/DateRangePicker';
import ExchangeService from '../../services/ExchangeService';
import { IExchangeRate } from '../../types/ExchangeRate';
import { TopTransactions } from '../../components/dashboard/TopTransactions';
import { getTransaction } from '../../services/TransactionService';
import { Trans } from '@lingui/macro';
import { TokenCard } from '../../components/TokenCard';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { formatCurrency } from '../../helpers';
import { UserCard } from '../../components/UserCard';
import MerchantService from '../../services/MerchantService';
import { MultiCurrency, Totalfiatbalance } from '../../types/MerchantNFT';
import { useAuth } from '../../store/auth';
import { MerchantType } from '../../types/Merchant';
import { useQuery } from '@tanstack/react-query';
import { FetchStateComponent } from '../../components/FetchStateComponent';

import { DateRange } from '../../components/Date/DateRangePicker';
import { formatDateRangeFilter } from '../../helpers/UtilsHelpers';
const PageDashboard: React.FC = () => {
  const [filter, setFilter] = useState<IFilterTransaction>({
    fromDate: moment().subtract(6, 'days').startOf('day').format(FORMAT_DATE_FILTER_START),
    toDate: moment().endOf('day').format(FORMAT_DATE_FILTER),
  });

  const [exRate, setExRate] = useState<IExchangeRate>();
  const [exMultiCurrency, setExMultiCurrency] = useState<MultiCurrency>();
  const [exFiat, setFiat] = useState<Totalfiatbalance>();

  const { currentUser } = useAuth();
  useEffect(() => {
    loadExchangeRate();
  }, [filter]);

  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['list-top-transaction'],
    queryFn: async () => {
      const response = await getTransaction({
        page: 1,
        size: 20,
      });

      return response;
    },
  });

  const loadExchangeRate = async () => {
    try {
      Promise.all([
        ExchangeService.getExchangeRate(),
        MerchantService.getTotalfiatbalance(),
        MerchantService.getMultiCurrency(),
      ]).then(([_exRate, _exFiat, _exMultiCurrency]) => {
        setExRate(_exRate);
        setFiat(_exFiat);
        setExMultiCurrency(_exMultiCurrency);
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChangeDate = (dateRange: DateRange) => {
    setFilter({ ...filter, page: 1, ...formatDateRangeFilter(dateRange) });
  };

  return (
    <>
      <div className="row align-items-center justify-content-between mb-5">
        <div className="col-auto">
          <div className="d-flex align-items-center mb-2">
            <div className="fs-1 fw-bold border-start border-3 border-primary px-4">
              {/* Tổng quan ví */}
              <Trans>Wallet overview</Trans>
            </div>
          </div>
        </div>
        <div className="col-auto"></div>
      </div>
      <div className="row mb-5">
        <div className="col-md-6">
          <UserCard exFiat={exFiat} loadData={refetch} key={'usercard'} />
        </div>
        <div className="col-md-6">
          <TokenCard exMultiCurrency={exMultiCurrency} key={'TokenCard'} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {currentUser?.type !== MerchantType.MULTI_CURRENCY && (
            <div className="d-flex gap-2 mb-6 ">
              <div className="badge py-3 px-4 fs-7 badge-rating">
                {exRate && (
                  <span className="fs-6">
                    <Trans>Deposit rate</Trans>:
                    <b>
                      1 {CurrencySymbols.USDT} ={' '}
                      {formatCurrency(exRate?.purchasingExchangeRatePG || 0, CurrencySymbols.VIC)}
                    </b>
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row align-items-center justify-content-between mb-5">
        <div className="col-auto">
          <DateRangePicker onChangeDate={handleChangeDate} style={{ maxHeight: '100%' }} />
        </div>
      </div>
      <div className="mb-5">
        <SummaryCards key={'SummaryCards'} filter={filter} />
      </div>
      <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
        <TopTransactions transactions={data} currentUser={currentUser} isLoading={isLoading} />
      </FetchStateComponent>
    </>
  );
};

export default PageDashboard;
