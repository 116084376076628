export enum TxTypes {
  ALL = 'ALL',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  TRANSFER = 'TRANSFER',
  // FEE = 'FEE',
  USER_PAY = 'USER_PAY',
  ISSUED_NFT = 'ISSUED_NFT',
  CONSUMED_NFT = 'CONSUMED_NFT',
}
export const TxTypesByMulti = Object.keys(TxTypes).filter((key: string) => {
  return ![TxTypes.CONSUMED_NFT, TxTypes.ISSUED_NFT].includes(key as TxTypes);
});
export enum TxFeeTypes {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  TRANSFER = 'TRANSFER',
  FEE = 'FEE',
  USER_PAY = 'USER_PAY',
  ISSUED_NFT = 'ISSUED_NFT',
  CONSUMED_NFT = 'CONSUMED_NFT',
  TRANSFERRED_NFT = 'TRANSFERRED_NFT',
}

export function getCashFlowDirections(flow: string): string {
  switch (flow) {
    case 'IN':
      return '+';
    case 'OUT':
      return '-';
    default:
      return ''; // Default class or handle unknown status
  }
}

export enum TxTypeClassNames {
  DEPOSIT = 'badge badge-warning text-uppercase',
  WITHDRAW = 'badge badge-primary text-uppercase',
  TRANSFER = 'badge badge-info text-muted text-uppercase',
  FEE = 'badge badge-light-danger text-uppercase',
  USER_PAY = 'badge badge-success text-uppercase',
  ISSUED_NFT = 'badge badge-dark text-uppercase',
  CONSUMED_NFT = 'badge badge-info text-info text-uppercase',
}

export const logStatus = ['FAILED', 'EXPIRED'];

export enum TxStatus {
  ALL = 'ALL',
  CREATED = 'CREATED',
  // DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  ONCHAIN_SUCCESS = 'ONCHAIN_SUCCESS',
  PROCESSING = 'PROCESSING',
  EXPIRED = 'EXPIRED',
  OVER_PAID = 'OVER_PAID',
  UNDER_PAID = 'UNDER_PAID',
  INVALID_DATA = 'INVALID_DATA',
  // LAMBDA_FAILED = 'LAMBDA_FAILED',
}

export enum NFTTxStatus {
  ALL = 'ALL',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  PROCESSING = 'PROCESSING',
}

export enum TxStatusLabels {
  CREATED = 'TX_CREATED',
  EXPIRED = 'TX_EXPIRED',
  FAILED = 'TX_FAILED',
  COMPLETED = 'TX_COMPLETED',
  PROCESSING = 'TX_PROCESSING',
}

export function getStatusClass(status: string): string {
  switch (status) {
    case 'CREATED':
      return 'badge badge-light-warning text-uppercase';
    case 'PROCESSING':
      return 'badge badge-light-warning text-uppercase';
    case 'OVER_PAID':
    case 'COMPLETED':
    case 'UNDER_PAID':
      return 'badge badge-light-success text-uppercase';
    case 'ONCHAIN_SUCCESS':
      return 'badge badge-light-primary text-uppercase';
    case 'EXPIRED':
      return 'badge badge-light-danger text-uppercase';
    case 'IN_CIRCULATION':
      return 'badge badge-light-success text-uppercase';
    case 'FAILED':
    case 'INVALID_DATA':
    case 'LAMBDA_FAILED':
      return 'badge badge-light-danger text-uppercase';
    default:
      return 'badge badge-light text-uppercase'; // Default class or handle unknown status
  }
}

export enum TxAmountColors {
  CREATED = 'text-mute',
  PROCESSING = 'text-warning',
  FAILED = 'text-danger',
  COMPLETED = 'text-success',
}

export interface ITransaction {
  address: string;
  balance: number;
  changeAmount: number;
  cashFlowDirection: string;
  createdAt: string;
  updatedAt: string;
  chainId: number;
  chainName: string;
  fromAddress: string;
  toAddress: string;
  tokenAddress: string;
  tokenType: string;
  tokenSymbol: string;
  txHash: string;
  type: string;
}
