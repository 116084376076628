import { useState } from 'react';
import Button from '../common/Button';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Alert } from '../../helpers';
import { FaIcons } from '../../helpers/FaIconHelper';
import * as Yup from 'yup';
import MerchantDistributorService from '../../services/MerchantDistributorService';
import clsx from 'clsx';
import { Chains } from '../../types';
import { Trans, t } from '@lingui/macro';
import { isAddress } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import { getChainsSelector } from '../../store/chain/selectors';

const DistributorAddNewAction = ({ reload }: { reload: any }) => {
  const [show, setShow] = useState(false);
  const onHide = () => {
    reload();
    setShow(!show);
  };
  // Thêm địa chỉ mới
  // console.log('show', show)
  return (
    <>
      <Button
        textColor="white"
        backgroundColor="primary"
        iconClassName={`${FaIcons['fas fa-plus']} text-white fs-3`}
        onClick={onHide}
      >
        {/* Thêm địa chỉ mới */}
        <Trans>Add new address</Trans>
      </Button>
      {show && <DistributorAddNewModal {...{ show, onHide }} />}
    </>
  );
};

export default DistributorAddNewAction;

export const DistributorAddNewModal = ({
  onAddItem,
  show,
  onHide,
}: {
  onAddItem?: (items: { address: string; chainCode: Chains }[]) => void;
  show: boolean;
  onHide: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // const [errorThree, setErrorThree] = useState('');

  const initialValues = {
    bscAddress: '',
    polygonAddress: '',
    vchainAddress: '',
    name: '',
  };

  const schema = Yup.object().shape(
    {
      polygonAddress: Yup.string().when(['vchainAddress', 'bscAddress'], {
        is: (vchainAddress: any, bscAddress: any) => !vchainAddress && !bscAddress,
        then: Yup.string().required(t`You need to enter at least one of the three addresses`), // Nếu điều kiện thỏa mãn, "bscAddress" bắt buộc
        otherwise: Yup.string(),
      }),
      vchainAddress: Yup.string().when(['polygonAddress', 'bscAddress'], {
        is: (polygonAddress: any, bscAddress: any) => !polygonAddress && !bscAddress,
        then: Yup.string().required(t`You need to enter at least one of the three addresses`), // Nếu điều kiện thỏa mãn, "bscAddress" bắt buộc
        otherwise: Yup.string(),
      }),
      bscAddress: Yup.string().when(['polygonAddress', 'vchainAddress'], {
        is: (polygonAddress: any, vchainAddress: any) => !polygonAddress && !vchainAddress,
        then: Yup.string().required(t`You need to enter at least one of the three addresses`), // Nếu điều kiện thỏa mãn, "bscAddress" bắt buộc
        otherwise: Yup.string(),
      }),

      name: Yup.string().required(t`You haven't entered a nickname`),
    },
    [
      ['polygonAddress', 'vchainAddress'],
      ['polygonAddress', 'bscAddress'],
      ['vchainAddress', 'bscAddress'],
    ]
  );

  const onSubmit = async (
    {
      name,
      bscAddress,
      polygonAddress,
      vchainAddress,
    }: {
      name: string;
      bscAddress: string;
      polygonAddress: string;
      vchainAddress: string;
    },
    { setSubmitting }: any
  ) => {
    setLoading(true);

    try {
      const addresses = [];

      if (bscAddress.trim()) {
        addresses.push({ address: bscAddress.trim(), chainCode: Chains.BINANCE });
      }
      if (polygonAddress.trim()) {
        addresses.push({ address: polygonAddress.trim(), chainCode: Chains.POLYGON });
      }
      if (vchainAddress.trim()) {
        addresses.push({ address: vchainAddress.trim(), chainCode: Chains.VCHAIN });
      }
      await MerchantDistributorService.addDistributor({
        name,
        addresses,
      });
      Alert.success(t`Added successfully`, true).then(() => {
        onHide();
      });
      if (onAddItem) onAddItem(addresses);
    } catch (error: any) {
      if (error.response.status === 409) {
        //Địa chỉ ví đã tồn tại
        console.log(error.response);
        if (error.response.data.code === 'DISTRIBUTOR_NAME_ALREADY_EXISTING') {
          Alert.error(t`The wallet name has existed`, true);
        }
        if (error.response.data.code === 'DATA_DUPLICATED_ADDRESS') {
          Alert.error(t`The wallet address has existed`, true);
        }
      } else if (error.response.status === 400) {
        //Địa chỉ ví không hợp lệ
        Alert.error(t`The address is not valid`, true);
      } else {
        Alert.error(t`Updated unsuccessfully`, true);
      }
    }
    setLoading(false);
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });
  
  const handleInputField = (e: any, inputName: string) => {
    // Assuming you have a function like setFieldValue from Formik
    // Replace 'setFieldValue' with the actual function you're using
    // e.g., setFieldValue('users.${index}.username', e.target.value);

    setError('');

    if (e.target.value !== '' && inputName === 'polygonAddress' && !isAddress(e.target.value)) {
      setError(t`Invalid wallet address `);
    }

    if (e.target.value !== '' && inputName === 'vchainAddress' && !isAddress(e.target.value)) {
      setError(t`Invalid wallet address `);
    }

    if (e.target.value !== '' && inputName === 'bscAddress' && !isAddress(e.target.value)) {
      setError(t`Invalid wallet address `);
    }
  };

  // console.log(formik.isValid, error, formik.errors);
  const chains = useSelector(getChainsSelector);
  return (
    <Modal
      className="modal-sticky modal-sticky-lg modal-sticky-bottom-right"
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="999"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-dialog mw-500px"
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="modal-header border-0 ">
          <h2>
            <Trans>Add new address</Trans>
          </h2>
          <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>
        <div className="modal-body py-0">
          <div className="row">
            <div className="col">
              <div className="mb-6">
                <label htmlFor="" className="form-label required">
                  <Trans>Name</Trans>
                </label>
                <input
                  className="form-control"
                  required
                  type="string"
                  autoComplete="off"
                  maxLength={64}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container invalid-feedback_error">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="" className="form-label required">
                <Trans>Wallet address</Trans>
              </label>
              {chains
                .filter((i) => i?.code !== Chains.TRON)
                .map((item) => {
                  let textField = 'bscAddress';
                  switch (item.code) {
                    case Chains.POLYGON:
                      textField = 'polygonAddress';
                      break;
                    case Chains.VCHAIN:
                      textField = 'vchainAddress';
                      break;
                    default:
                      textField = 'bscAddress';
                      break;
                  }

                  return (
                    <div className="input-group mb-3">
                      <input
                        className="form-control text-truncate"
                        required
                        type="string"
                        {...formik.getFieldProps(`${textField}`)}
                        onChange={(e: any) => {
                          formik.setFieldValue(`${textField}`, e.target.value);
                          handleInputField(e, `${textField}`);
                        }}
                        onPaste={(e: any) => {
                          formik.setFieldValue(`${textField}`, e.target.value);
                          handleInputField(e, `${textField}`);
                        }}
                        onFocus={(e: any) => {
                          formik.setFieldValue(`${textField}`, e.target.value);
                          handleInputField(e, `${textField}`);
                        }}
                      />
                      <span className="input-group-text bg-white d-flex gap-2 w-100px fs-sm" id="inputGroup-sizing-sm">
                        <img src={item.logoUrl} className="img-fluid w-20px" /> {item.name}
                      </span>
                    </div>
                  );
                })}
              <div className="input-group mb-3">
                {!formik.errors.bscAddress && !formik.errors.polygonAddress && !formik.errors.vchainAddress && error && (
                  <div className="fv-plugins-message-container invalid-feedback_error">
                    <div className="fv-help-block">{error}</div>
                  </div>
                )}

                {formik.errors && !!formik.touched.name && (
                  <div className="fv-plugins-message-container invalid-feedback_error">
                    <div className="fv-help-block">{formik.errors.polygonAddress}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 pt-0">
          <button className="btn btn-outline" onClick={onHide}>
            <Trans>Cancel</Trans>
          </button>

          <button
            className="btn btn-primary"
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.name || error !== ''}
          >
            {!loading && <Trans>Confirm</Trans>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                <Trans>Please wait</Trans> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};
