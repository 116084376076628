import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SETTINGS } from '../../store/settings/actions';
import { getSettingsSelector } from '../../store/settings/selectors';
import { Alert } from '../../helpers';
import { NoData } from '../../components/NoData';
import DataBox from '../../components/form/DataBox';
import MerchantService from '../../services/MerchantService';
import { Trans, t } from '@lingui/macro';
import CallbackSetting from '../../components/settings/CallbackSetting';
import ApiKeySetting from '../../components/settings/ApiKeySetting';
import WithTooltip from '../../components/WithTooltip';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const PageSettings: React.FC = () => {
  const dispatch = useDispatch();
  const settings = useSelector(getSettingsSelector);

  const suspendInbound = async () => {
    const question = `<div class="mb-5 text-start">
    ${t`When you perform this action, all your transactions will be temporarily locked. To unlock, please contact the business department. Are you sure you want to proceed?`}
  </div>
  <div class="text-start fs-7 fw-bold">
    ${t`Note`}:
    <br /> ${t`Note action`}
  </div>`;

    const isConfirm = await Alert.confirm(question, true);

    if (isConfirm) {
      const suspended = await MerchantService.suspend({ type: true });
      if (suspended) {
        dispatch(SETTINGS.REQUEST());
        Alert.success(t`Locked successfully`); //
      }
    }
  };

  if (!settings?.merchant?.id) {
    return (
      <div className="card p-5">
        <NoData />
      </div>
    );
  }
  return (
    <div className="card mb-5" id="kt_profile_details_view">
      <div className="card-header ">
        <h3 className="card-title">
          <Trans>Settings</Trans>
        </h3>
      </div>
      <div className="card-body">
        <div className="fs-2 fw-semibold mb-5">
          <Trans>Customer Information</Trans>
        </div>

        <div className="my-5">
          <label className="form-label d-flex gap-1 align-items-center ">
            <Trans>Email</Trans>
          </label>

          <div className="fv-row d-flex gap-3">
            <div className="w-100">
              <div className="form-control form-control-solid-bg">
                <div className="badge badge-primary me-1 mb-1">{settings.merchant.email}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <label className="form-label d-flex gap-1 align-items-center ">
            <Trans>Customer Id</Trans>
          </label>
          <DataBox secret={true} value={settings.merchant.id} copyable />
        </div>
        <div className="my-5">
          <label className="form-label d-flex gap-1 align-items-center ">
            <Trans>API Key</Trans>
          </label>
          <ApiKeySetting />
        </div>

        <CallbackSetting callbackUrl={settings.merchant.callbackUrl} />

        <div className="separator my-10"></div>

        <div className="my-5">
          <label className="form-label d-flex gap-1 align-items-center ">
            <Trans>Deposit address</Trans>
            <WithTooltip id="tooltip-2" tooltip={<Trans>Deposit address note</Trans>}>
              <AiOutlineExclamationCircle />
            </WithTooltip>
          </label>
          <DataBox value={settings.merchant.depositAddress} copyable />
        </div>
        <div className="my-5">
          <label className="form-label d-flex gap-1 align-items-center ">
            <Trans>Withdraw address</Trans>
            <WithTooltip id="tooltip-2" tooltip={<Trans>Withdraw address note</Trans>}>
              <AiOutlineExclamationCircle />
            </WithTooltip>
          </label>
          <DataBox value={settings.merchant.withdrawAddress} copyable />
        </div>
        <div className="mt-5 d-flex justify-content-end ">
          <Button disabled={settings.merchant.suspended} variant={'danger'} onClick={suspendInbound}>
            <Trans>Suspend transaction</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageSettings;
