import AxiosInstance from './axios';

const getExchangeRate = async () => {
  const url = '/exchange-rates/selling-purchase-rate';

  try {
    const result = await AxiosInstance.get(url);
    return result.data;
  } catch (err) {
    console.log('Func: getExchangeRate - PARAMS: err', err);
    throw err;
  }
};

const addExchangeRate = async ({
  fromToken,
  toToken,
  exchangeRate,
}: {
  fromToken: string;
  toToken: string;
  exchangeRate: number;
}) => {
  try {
    const result = await AxiosInstance.post('/exchange-rates', { fromToken, toToken, exchangeRate });
    return result;
  } catch (err) {
    console.log('Func: addExchangeRate - PARAMS: err', err);
    throw err;
  }
};

const updateExchangeRate = async ({
  fromToken,
  toToken,
  exchangeRate,
}: {
  fromToken: string;
  toToken: string;
  exchangeRate: number;
}) => {
  try {
    const result = await AxiosInstance.put('/exchange-rates', { fromToken, toToken, exchangeRate });
    return result;
  } catch (err) {
    console.log('Func: updateExchangeRate - PARAMS: err', err);
    throw err;
  }
};

export default {
  getExchangeRate,
  addExchangeRate,
  updateExchangeRate,
};
