import { GET_TOKENS_REQUEST, GET_TOKENS_FAILED, GET_TOKENS_SUCCESS } from './actionTypes';
import { TokenActions, TokenState } from './types';

const initialState: TokenState = {
  pending: false,
  tokens: [],
  error: null,
};

const reducer = (state = initialState, action: TokenActions) => {
  switch (action.type) {
    case GET_TOKENS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_TOKENS_SUCCESS:
      return {
        ...state,
        pending: false,
        tokens: action.payload.tokens,
        error: null,
      };
    case GET_TOKENS_FAILED:
      return {
        ...state,
        pending: false,
        tokens: [],
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
