import { GET_DASHBOARD_REQUEST, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILED } from './actions';
import { DashboardActions, DashboardState } from './types';

const initialState: DashboardState = {
  pending: false,
  error: null,
  summary: {
    currency: '',
    fiatTransactions: [],
  },
};

const reducer = (state = initialState, action: DashboardActions) => {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        pending: false,
        ...action.payload,
      };
    case GET_DASHBOARD_FAILED:
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
export default reducer;
