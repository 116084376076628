import { t } from '@lingui/macro';

export enum TypeErrorMessage {
  AMOUNT_LESS_THAN_ZERO = 'AMOUNT_LESS_THAN_ZERO',
  AMOUNT_EXCEED_LIMIT = 'AMOUNT_EXCEED_LIMIT',
  PAYMENT_SESSION_EXPIRED = 'PAYMENT_SESSION_EXPIRED',
  PAYMENT_SESSION_REJECTED = 'PAYMENT_SESSION_REJECTED',
}

export const getErrorMessage = (code: string) => {
  const type = TypeErrorMessage;
  switch (code) {
    case type.AMOUNT_LESS_THAN_ZERO:
      //Giá trị giao dịch quá nhỏ không đủ trả phí
      return t`The transaction value is too small`;
    case type.AMOUNT_EXCEED_LIMIT:
      //Số tiền vượt hạn mức, vui lòng liên hệ admin
      return t`The amount exceeded the limit, please contact the admin`;
    case type.PAYMENT_SESSION_EXPIRED:
      //Số tiền vượt hạn mức, vui lòng liên hệ admin
      return t`The payment session has expired.`;
    case type.PAYMENT_SESSION_REJECTED:
      return t`The payment session has been denied.`;
    default:
      //Không thành công. Vui lòng thử lại
      return t`Unsuccessful. Please try again`;
  }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getError = (e: any) => {
  let msg = e.message;
  if (e.response && e.response.data && e.response.data.message) {
    msg = e.response.data.message;
  }
  return {
    status: false,
    msg,
    data: e?.response?.data,
  };
};

export const getErrMessage = (e: any) => e.message;
