export enum TypeTokens {
  VIC = 'VIC',
  USDT = 'USDT',
  USD = 'USD',
}

export interface IAsset {
  id: string;
  chainId: number;
  addressToken: string;
  typeToken: string;
  configs: {
    minAccountBalance: number;
    maxAccountBalance: number;
    minTransfer: number;
    maxTransfer: number;
    coldWalletAddress: string;
  };
  wallet: {
    id: string;
    chainId: number;
    chainName: string;
    address: string;
    type: string;
    typeToken: string;
    coinSymbol: string;
    endPoint: string;
    apiEndPoint: string;
    configs: {
      feePercent: number;
    };
    active: boolean;
  };
}

export interface IAssetBalance {
  id: string;
  chainId: number;
  chainName: string;
  addressToken: string;
  typeToken: string;
  coinSymbol: string;
  balance: number;
}
