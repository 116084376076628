import { formatCurrency, getExplorerLink } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { Address } from '../token/Address';
import { useEffect, useState } from 'react';
import { getCashFlowDirections } from '../../types/Transaction';
import LogEvent from './LogEvent';
import { MertchantTx } from '../../types/Merchant';
import MerchantService from '../../services/MerchantService';
import NftID from '../NFTId';
import { Loading } from '../common/Loading';
import { Trans } from '@lingui/macro';
import { FullDetailTabs, Tabs } from './DetailTabs';

import { InforDetail } from './InforDetail';

export const ConsumeNFTDetails = ({ transactionId }: { transactionId: string }) => {
  const [currentTab, setCurrentTab] = useState(Tabs.DETAILS.toString());
  const [details, setDetails] = useState<MertchantTx>();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const data = await MerchantService.getTxDetails(transactionId);
      setDetails(data);
    } catch (e) {
      console.log(e);
    }
  };

  if (!details) {
    return (
      <div className="modal-body ">
        <div style={{ background: '#F4F5F7' }} className="p-5 rounded-4 mb-5">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="modal-body ">
      <InforDetail details={details} />
      <FullDetailTabs {...{ currentTab, setCurrentTab }} />

      {currentTab === Tabs.DETAILS.toString() && (
        <div>
          <Details details={details} />
          <CurrencyDetails details={details} />
        </div>
      )}
      {currentTab === Tabs.ADDITION.toString() && <Additions details={details} />}
      {currentTab === Tabs.LOG.toString() && <LogEvent details={details} />}
    </div>
  );
};

const Details = ({ details }: { details: MertchantTx }) => {
  return (
    <div className="p-5 border rounded-4 mb-5">
      <div className="row mb-5">
        <div className="col fw-bold ">
          {/* Thông tin đối tác 
        tieng anh: Partner information
        */}
          <Trans>Partner information</Trans>
        </div>
        <div className="col d-flex justify-content-end "></div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <Trans>Reference code</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <Address address={details.paymentId} />
        </div>
      </div>

      <div className="row mb-5">
        <div className="col">
          {/* Ví thanh toán
        tieng anh: Payment wallet
        */}
          <Trans>Payment wallet</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <Address
            address={details.senderAddress}
            link={getExplorerLink(details.chainCode, details.senderAddress, 'address')}
          />
        </div>
      </div>
    </div>
  );
};

const CurrencyDetails = ({ details }: { details: MertchantTx }) => {
  return (
    <div className="p-5 border rounded-4 mb-5">
      <div className="row mb-5">
        <div className="col fw-bold ">
          <Trans>Money flow details</Trans>
        </div>
        <div className="col d-flex justify-content-end "></div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <Trans>Transaction value</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.txCashFlowInfo.txValue.value, details.txCashFlowInfo.txValue.currency)}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <Trans>Service fee</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.txCashFlowInfo.serviceCostInBaseToken, details.baseToken)}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <Trans>Gas fee</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.txCashFlowInfo.gasCostInBaseToken, details.baseToken)}
        </div>
      </div>

      <div className="row mb-5">
        <div className="col">
          <Trans>Balance change</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {getCashFlowDirections(details.txCashFlowInfo.cashFlowDirection)}{' '}
          {formatCurrency(details.txCashFlowInfo.actualTxAmountAfterCostInBaseToken, details.baseToken)}
        </div>
      </div>
    </div>
  );
};

const Additions = ({ details }: { details: MertchantTx }) => {
  return (
    <div>
      <div className="p-5 border rounded-4 mb-5">
        <div className="row mb-5">
          <div className="col fw-bold ">
            {/* Thông tin sử dụng 
          tieng anh: Usage information
          */}
            <Trans>Usage information</Trans>
          </div>
          <div className="col d-flex justify-content-end "></div>
        </div>
        <div className="row mb-5">
          <div className="col">
            {/* Số lượng NFT sử dụng 
          tieng anh: Number of NFT used
          */}
            <Trans>Number of NFT used</Trans>
          </div>
          <div className="col d-flex justify-content-end ">
            <div className="col d-flex justify-content-end ">
              {formatCurrency(details.additionalInfo.quantity, CurrencySymbols.VIC, true)}
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            {/* Tổng giá trị NFT 
          // tieng anh : Total NFT value
          */}
            <Trans>Total NFT value</Trans>
          </div>
          <div className="col d-flex justify-content-end ">
            <div className="col d-flex justify-content-end ">
              {formatCurrency(details.additionalInfo.totalNftValue, CurrencySymbols.VIC, true)}
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 border rounded-4 mb-5">
        <div className="row mb-5">
          <div className="col-2 fw-bold ">
            {/* Mã NFT */}
            <Trans>NFT Id</Trans>
          </div>
          <div className="col fw-bold text-end ">
            {/* Giá trị
          tieng anh: Value
          */}
            <Trans>Value</Trans>
          </div>
        </div>

        <div
          className={details.additionalInfo.nftInfos.length > 5 ? 'h-200px' : ''}
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          {details.additionalInfo.nftInfos.map((item, k) => (
            <div className="row mb-2" key={k}>
              <div className="col-2">
                <NftID nftAddress={item.nftAddress} tokenId={item.tokenId} chainCode={item.chainCode} />
              </div>
              <div className="col d-flex justify-content-end ">{formatCurrency(item.value, item.currency)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
