import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker, StaticRange, createStaticRanges, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import vi from 'date-fns/locale/vi';
import en from 'date-fns/locale/en-US';
import { Optional } from 'react-date-range/_util';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import { t, Trans } from '@lingui/macro';
import { useLanguageProvider } from '../../providers/LanguageProvider';
export type DateRange = Range;
const DateRangeSelect = ({
  dateRange,
  onSelect,
  customRanges,
  show,
  isAlignRight = false,
}: {
  dateRange?: { startDate: Date; endDate: Date };
  onSelect?: any;
  customRanges?: Optional<StaticRange, 'isSelected'>[];
  show?: boolean;
  isAlignRight?: boolean;
}) => {
  const { language } = useLanguageProvider();
  const [open, setOpen] = useState(show ?? false);
  const [range, setRange] = useState([
    {
      startDate: moment(dateRange?.startDate).toDate(),
      endDate: moment(dateRange?.endDate).toDate(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    setOpen(show ?? false);
  }, [show]);

  const onClose = () => {
    setRange([
      {
        startDate: moment(dateRange?.startDate).toDate(),
        endDate: moment(dateRange?.endDate).toDate(),
        key: 'selection',
      },
    ]);
    setOpen(false);
  };

  const apply = () => {
    if (onSelect) {
      onSelect(range);
    }
    setOpen(false);
  };

  const handleOnChange = (ranges: any) => {
    const { selection } = ranges;
    const startDate = startOfDay(selection.startDate);
    const endDate = endOfDay(selection.endDate);
    setRange([{ ...selection, startDate, endDate }]);
  };

  const defaultRanges = [
    {
      label: t`Today`,
      range: () => ({
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: t`Yesterday`,
      range: () => ({
        startDate: startOfDay(moment().subtract(1, 'day').toDate()),
        endDate: endOfDay(moment().subtract(1, 'day').toDate()),
      }),
    },
    {
      label: t`This Month`,
      range: () => ({
        startDate: moment().startOf('month').startOf('day').toDate(),
        endDate: endOfDay(moment().toDate()),
      }),
    },
    {
      label: t`Last 3 Months`,
      range: () => ({
        startDate: startOfMonth(moment().subtract(1, 'month').toDate()),
        endDate: endOfDay(new Date()),
      }),
    },
  ];

  const staticCustomRanges = customRanges ? createStaticRanges(customRanges) : createStaticRanges(defaultRanges);

  return (
    <div className="position-relative">
      {open && (
        <div
          className="overflow-hidden shadow rounded-3 border border-gray-300 position-absolute bg-white"
          style={{ zIndex: 1000, right: isAlignRight ? 0 : undefined }}
        >
          <DateRangePicker
            onChange={handleOnChange}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={false}
            months={1}
            scroll={{
              enabled: true,
            }}
            editableDateInputs={false}
            dateDisplayFormat="MM-dd-yyyy"
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            showPreview={true}
            maxDate={new Date()}
            ranges={range}
            locale={language === 'vi-VN' ? vi : en}
            staticRanges={staticCustomRanges}
            inputRanges={[]}
          />
          <div className="d-flex gap-2 justify-content-end p-3 pt-0">
            <button className="btn btn-secondary btn-sm" onClick={onClose}>
              <Trans>Cancel</Trans>
            </button>
            <button className="btn btn-primary btn-sm" onClick={apply}>
              <Trans>Apply</Trans>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeSelect;
