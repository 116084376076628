import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from './layout/core';
import AuthInit from './store/auth/AuthInit';
import { GlobalDebug } from './utils/function';
import { GoogleOAuthProvider } from '@react-oauth/google';
import appConfig from './appConfig';
import { AppProvider } from './providers/provider';
import { LanguageProvider } from './providers/LanguageProvider';

const App = () => {
  useEffect(() => {
    process.env.NODE_ENV === 'production' && GlobalDebug(false);
  }, []);

  console.log('I am just another dummy console log,  suppose to be suppressed 🙂');

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LanguageProvider>
        <LayoutProvider>
          <GoogleOAuthProvider clientId={appConfig.googleClientId}>
            <AuthInit>
              <AppProvider>
                <Outlet />
              </AppProvider>
            </AuthInit>
          </GoogleOAuthProvider>
        </LayoutProvider>
      </LanguageProvider>
    </Suspense>
  );
};

export { App };
