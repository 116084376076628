import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { en, vi } from 'make-plural/plurals';
import { PluralCategory } from 'make-plural/plurals';
import { DEFAULT_LOCALE, SupportedLocale, dynamicActivate } from '../i18n';

import { toAbsoluteUrl } from '../helpers';

export const languageData = [
  {
    lang: 'en-US',
    name: 'English',
    flag: toAbsoluteUrl('/img/flags/united-states.svg'),
  },
  {
    lang: 'vi-VN',
    name: 'Tiếng Việt',
    flag: toAbsoluteUrl('/img/flags/vietnam.svg'),
  },
];

type LocalePlural = {
  [key in SupportedLocale]: (n: number, ordinal?: boolean) => PluralCategory;
};

export const plurals: LocalePlural = {
  'vi-VN': vi,
  'en-US': en,
  pseudo: en,
};

const LanguageContext = createContext<
  Partial<{
    language: string;
    setLanguage: (language: string) => void;
  }>
>({});

export function LanguageProvider({ children }: { children: ReactNode }) {
  // const locale = useActiveLocale();
  const searchParams = new URLSearchParams(document.location.search);
  const localeQuery = searchParams.get('lng');

  const [lang, setLangLocal] = useLocalStorage('language', DEFAULT_LOCALE);

  const [language, setLanguage] = useState<string>(localeQuery ?? lang ?? DEFAULT_LOCALE);

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      setLanguage(newLocale);
      setLangLocal(newLocale);
      dynamicActivate(newLocale);
    },
    [language]
  );

  useEffect(() => {
    // console.log('1111', lang, plurals['vi-VN']);
    dynamicActivate(lang);
  }, []);

  useEffect(() => {
    // console.log('localeQuery', localeQuery);
    if (localeQuery) {
      const newLanguage = languageData.find((lng) => lng.lang === localeQuery)?.lang || language;
      setLocale(newLanguage);
    }
  }, [localeQuery]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={true}>
        {children}
      </I18nProvider>
    </LanguageContext.Provider>
  );
}

export const useLanguageProvider = () => useContext(LanguageContext);
