import axios from 'axios';
import queryString from 'query-string';
import qs from 'qs';

import appConfig from '../appConfig';
import { Alert } from '../helpers/AlertHelpers';
import { t } from '@lingui/macro';
import { DEFAULT_LOCALE } from '../helpers/locales';

const pageZero = false;
const AxiosInstance = axios.create({
  baseURL: appConfig.apiEndpoint,
});

AxiosInstance.interceptors.request.use((config) => {
  if (!config.url) {
    return config;
  }
  config.headers['Accept-Language'] = localStorage.getItem('language') ?? DEFAULT_LOCALE;
  if (pageZero) {
    const _params = config.params ? modifyPaging(config.params) : '';
    return {
      ...config,
      url: modifyPaging(config.url),
      params: _params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { indices: false });
      },
    };
  } else {
    return {
      ...config,
      params: config.params,
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { indices: false });
      },
    };
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (res) => {
    if (pageZero) {
      const { config, data } = res;
      // if (config.url && config.url.indexOf('user-requests') > -1) {
      //   console.log('axios hooook', config.params, data);
      // }
      let myData = data;
      if (data.page !== undefined) {
        myData = { ...data, page: data.page + 1 };
      }
      return {
        ...res,
        config: {
          ...config,
          url: modifyPaging(config.url as string),
          params: config.params ? modifyPaging(config.params) : '',
        },
        data: myData,
      };
    }
    return res;
  },
  async (err) => {
    if (!err.response) {
      return Promise.reject(err);
    }
    if (err.response.status === 422) {
      console.log('Error status', err.response.status);
    }
    if (err.response.status === 401) {
      console.log(err.response.data.code);

      if (err.response.data.code === 'MERCHANT_HAS_BEEN_CLOSED') {
        Alert.error(t`Your gmail account does not have access! Please contact admin!`).then(() => {
          logout();
        });
        return false;
      }
      if (err.response.data.code === 'USER_NOT_FOUND') {
        Alert.error(t`User does not exist. Please contact admin!`).then(() => {
          logout();
        });
        return false;
      } else {
        Alert.error(t`Your session timeout!`).then(() => {
          logout();
        }); // improve by ticket  PG-1184
        return false;
      }
    }
    if (err.response.status === 403) {
      if (err.response.data.code === 'Unauthorized') {
        Alert.error(
          t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`
        );
        //  return false;
      } else {
        Alert.error(t`Unauthorized`);
      }
    }
    if (err.response.status === 400) {
      console.log('Error status', err.response.status);
    }

    if (err.response.status >= 500) {
      Alert.error(t`Sorry! An error occurred`);
    }

    return Promise.reject(err);
  }
);

export const logout = () => {
  localStorage.removeItem('cxp-auth');
  localStorage.removeItem('user');
  localStorage.removeItem('address');
  localStorage.removeItem('persist:cxp-auth');
  document.location.assign('/auth');
};

const modifyPaging = (url: string | any): string => {
  if (typeof url === 'object') {
    if (url.page) {
      return { ...url, page: url.page - 1 };
    } else {
      return url;
    }
  }
  const myUrl = urlStringParser(url);
  const queryParams = myUrl.query;
  if (queryParams && queryParams.page) {
    const newPage = parseInt(queryParams.page as string) - 1;
    return urlStringBuilder({
      ...myUrl,
      query: { ...queryParams, page: newPage },
    });
  } else {
    return url;
  }
};

export const urlStringParser = (url: string) => {
  return queryString.parseUrl(url);
};

export const urlStringBuilder = (params: any) => {
  return queryString.stringifyUrl(params);
};
export const queryStringBuilder = (params: any) => {
  let str = '';

  if (!params) {
    return str;
  }
  str = queryString.stringify(params);
  return str;
};

export default AxiosInstance;
