import { i18n } from '@lingui/core';
import { plurals } from './providers/LanguageProvider';

export const SUPPORTED_LOCALES = [
  // order as they appear in the language dropdown
  'vi-VN',
  'en-US',
];
export type SupportedLocale = typeof SUPPORTED_LOCALES[number] | 'pseudo';

export const DEFAULT_LOCALE: SupportedLocale = 'vi-VN';

export async function dynamicActivate(locale: string) {
  i18n.loadLocaleData(locale, { plurals: plurals[locale] });
  const catalog = await import(`./locales/${locale}`);
  i18n.load(locale, catalog.messages);
  i18n.activate(locale);
}
