import { Upload } from 'antd';
import { AiOutlineUpload } from 'react-icons/ai';
import { IDenomination } from '../../types/MerchantNFT';
import { toast } from 'react-toastify';
import { FILE_RULES } from '../../constants';
import { FaIcons } from '../../helpers/FaIconHelper';
import ImgCrop from 'antd-img-crop';
import { useState } from 'react';
import { Trans, t } from '@lingui/macro';

const { Dragger } = Upload;

const FormUpload = ({
  setUploadingFile,
  setNewDenominationImage,
  newDenominationImage,
  denomination,
}: {
  setUploadingFile: any;
  newDenominationImage?: string;
  setNewDenominationImage: any;
  denomination?: IDenomination;
}) => {
  const [incorrectFormatting, setIncorrectFormating] = useState<boolean>(false);

  const imageDimensions = (file: File) =>
    new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };

      img.onerror = () => {
        reject('There was some problem with the image.');
      };

      img.src = URL.createObjectURL(file);
    });

  const uploadImage = async ({ onSuccess, onError, file }: any) => {
    if (incorrectFormatting) return;
    try {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(file);
      oFReader.onload = function (oFREvent) {
        setNewDenominationImage(oFREvent?.target?.result?.toString());
      };

      setUploadingFile(file);
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  async function beforeUpload(file: File) {
    setIncorrectFormating(false);
    const JPG = 'image/jpeg';
    const PNG = 'image/png';
    const validImgExts = [PNG, JPG].includes(file.type);
    if (!validImgExts) {
      setIncorrectFormating(true);
      toast.error(t`Wrong format. Support format: JPG, PNG.`);
      //
    }

    const dimensions: any = await imageDimensions(file);
    const validDemension = dimensions.width <= FILE_RULES.width && dimensions.height <= FILE_RULES.height;

    if (!validDemension) {
      toast.error(t`The image size is too large.`);
      //
    }

    // console.log('dimensions', dimensions);

    // console.log('file.size / 1024 / 1024', file.size / 1024 / 1024);

    const isLt2M = file.size / 1024 / 1024 <= FILE_RULES.size;
    if (!isLt2M) {
      toast.error(t`Maximum image capacity 2MB!`);
      //
    }

    // return true;
    return validDemension && validImgExts && isLt2M;
  }

  const onRemove = () => {
    setNewDenominationImage(undefined);
  };

  return (
    <div className="text-center">
      {newDenominationImage ? (
        <div className="position-relative mx-auto border rounded-3" style={{ width: 'max-content' }}>
          <i
            className={`cursor-pointer fs-1 position-absolute ${FaIcons['fas fa-times']}`}
            style={{ right: 10, top: 10 }}
            onClick={onRemove}
          ></i>
          <img src={newDenominationImage} className="img-fluid rounded-3 order-5 " style={{ height: 170 }} />
        </div>
      ) : (
        <ImgCrop cropShape="rect" aspect={480 / 320} modalClassName="">
          <Dragger
            accept=".jpeg,.png,.gif"
            showUploadList={false}
            customRequest={uploadImage}
            beforeUpload={beforeUpload}
            disabled={!denomination}
          >
            <p className="ant-upload-drag-icon">
              <AiOutlineUpload size={34} />
            </p>
            <p className="ant-upload-text">
              <Trans>Select an image</Trans>
            </p>
            <p className="ant-upload-hint">
              <Trans>
                Supported formats: JPG, PNG
                <br />
                Aspect ratio 3:2, maximum 2MB size.
              </Trans>
            </p>
          </Dragger>
        </ImgCrop>
      )}
    </div>
  );
};

export default FormUpload;
