import BlockchainHelper from '../helpers/BLockChainHelpers';
import { IChain } from '../types';
import AxiosInstance from './axios';

const getChainList = async (): Promise<IChain[]> => {
  try {
    const res = await AxiosInstance.get('/info/chains').then((res) => res.data);

    const chains: IChain[] = (res || []).map((i: any) => ({
      ...i,
      logoUrl: BlockchainHelper.getChainImage(i.id) ?? '',
    }));

    return chains;
  } catch (e) {
    console.log('[API] getChainList', e);
    throw e;
  }
};

const ChainService = { getChainList };
export default ChainService;
