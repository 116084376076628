import { getChain, getExplorerLink } from '../helpers';

const NftID = ({ tokenId, nftAddress, chainCode }: { tokenId: number; nftAddress?: string; chainCode: string }) => {
  const chain = getChain(chainCode);
  return (
    <div className="d-flex align-items-center gap-2">
      <span className="symbol symbol-20px">
        <img src={chain?.logoUrl} className="mw-100 mh-100 p-0" alt="network" />
      </span>
      <div className="d-flex flex-wrap align-items-center">
        <div>
          <a
            className=""
            target="_blank"
            rel="noreferrer"
            href={getExplorerLink(chain?.id, `${nftAddress}?a=${tokenId}`, 'token')}
          >
            #{tokenId}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NftID;
