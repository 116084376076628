import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_DASHBOARD_REQUEST, DASHBOARD } from './actions';
import MerchantService from '../../services/MerchantService';
import { FetchDashboard, DashboardModel } from './types';
//@ts-check
function* fetchDashboard({ payload }: FetchDashboard) {
  try {
    const response: DashboardModel = yield call(MerchantService.getDashboard, payload);
    yield put(DASHBOARD.SUCCESS(response));
  } catch (err) {
    const error = err as Error;
    yield put(DASHBOARD.ERROR({ error: error.message }));
    console.log('Func: fetchDashboardSaga - PARAMS: err', err);
  }
}

function* settingsSaga() {
  yield takeEvery(GET_DASHBOARD_REQUEST, fetchDashboard);
}

export default settingsSaga;
