import { useState } from 'react';
import Button from '../../components/common/Button';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Alert } from '../../helpers';
import { FaIcons } from '../../helpers/FaIconHelper';
import * as Yup from 'yup';
import MerchantDistributorService from '../../services/MerchantDistributorService';
import clsx from 'clsx';
import { Chains } from '../../types';
import { IFilteredDistributor } from '../../types/MerchantDistributor';
import TabItem from '../../components/TabItem';
import { Trans, t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { getChainsSelector } from '../../store/chain/selectors';
import { isAddress } from 'ethers/lib/utils';

const DistributorUpdateAction = ({ reload, distributor }: { reload: any; distributor: IFilteredDistributor }) => {
  const [show, setShow] = useState(false);
  const onHide = () => {
    reload();
    setShow(!show);
  };

  // console.log('distributor', distributor);

  // console.log('show', show)
  return (
    <>
      <Button
        textColor="white"
        isHover
        backgroundColor="primary"
        size="sm"
        className="w-100 user-select-none rounded fw-semibold"
        onClick={onHide}
      >
        <Trans>Update</Trans>
      </Button>
      {show && <DistributorUpdateModal {...{ show, onHide, distributor }} />}
    </>
  );
};

export default DistributorUpdateAction;

export const DistributorUpdateModal = ({
  show,
  onHide,
  distributor,
}: {
  show: boolean;
  onHide: any;
  distributor: IFilteredDistributor;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // const [chainInput, setChainInput] = useState('');
  const initialValues = {
    users: [
      {
        username: '',
      },
    ],
    bscAddress: distributor.addresses.find((addr) => addr.chain.code === Chains.BINANCE.toString())?.address || '',
    polygonAddress: distributor.addresses.find((addr) => addr.chain.code === Chains.POLYGON.toString())?.address || '',
    vchainAddress: distributor.addresses.find((addr) => addr.chain.code === Chains.VCHAIN.toString())?.address || '',
    name: distributor.name,
    enabled: distributor.enabled,
  };

  const schema = Yup.object().shape(
    {
      polygonAddress: Yup.string().when(['vchainAddress', 'bscAddress'], {
        is: (vchainAddress: any, bscAddress: any) => !vchainAddress && !bscAddress,
        then: Yup.string().required(t`You need to enter at least one of the three addresses`), // Nếu điều kiện thỏa mãn, "bscAddress" bắt buộc
        otherwise: Yup.string(),
      }),
      vchainAddress: Yup.string().when(['polygonAddress', 'bscAddress'], {
        is: (polygonAddress: any, bscAddress: any) => !polygonAddress && !bscAddress,
        then: Yup.string().required(t`You need to enter at least one of the three addresses`), // Nếu điều kiện thỏa mãn, "bscAddress" bắt buộc
        otherwise: Yup.string(),
      }),
      bscAddress: Yup.string().when(['polygonAddress', 'vchainAddress'], {
        is: (polygonAddress: any, vchainAddress: any) => !polygonAddress && !vchainAddress,
        then: Yup.string().required(t`You need to enter at least one of the three addresses`), // Nếu điều kiện thỏa mãn, "bscAddress" bắt buộc
        otherwise: Yup.string(),
      }),

      name: Yup.string().required(t`You haven't entered a nickname`),
    },
    [
      ['polygonAddress', 'vchainAddress'],
      ['polygonAddress', 'bscAddress'],
      ['vchainAddress', 'bscAddress'],
    ]
  );

  const chains = useSelector(getChainsSelector);
  const onSubmit = async (
    {
      name,
      enabled,
      bscAddress,
      polygonAddress,
      vchainAddress,
    }: {
      name: string;
      enabled: boolean;
      bscAddress: string;
      polygonAddress: string;
      vchainAddress: string;
    },
    { setSubmitting }: any
  ) => {
    setLoading(true);

    try {
      const addresses = [];

      if (bscAddress.trim()) {
        addresses.push({ address: bscAddress.trim(), chainCode: Chains.BINANCE });
      }
      if (polygonAddress.trim()) {
        addresses.push({ address: polygonAddress.trim(), chainCode: Chains.POLYGON });
      }
      if (vchainAddress.trim()) {
        addresses.push({ address: vchainAddress.trim(), chainCode: Chains.VCHAIN });
      }
      await MerchantDistributorService.updateDistributor({
        id: distributor.id,
        name,
        enabled,
        addresses,
      });
      //Cập nhật thành công.
      Alert.success(t`Updated successfully`, true).then(() => {
        onHide();
      });
    } catch (error) {
      //Cập nhật không thành công
      Alert.error(t`Updated unsuccessfully`, true);
    }
    setLoading(false);
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  const handleInputField = (e: any, inputName: string) => {
    // Assuming you have a function like setFieldValue from Formik
    // Replace 'setFieldValue' with the actual function you're using
    // e.g., setFieldValue('users.${index}.username', e.target.value);

    setError('');
    if (e.target.value !== '' && inputName === 'polygonAddress' && !isAddress(e.target.value)) {
      setError(t`Invalid wallet address `);
    }

    if (e.target.value !== '' && inputName === 'vchainAddress' && !isAddress(e.target.value)) {
      setError(t`Invalid wallet address `);
    }

    if (e.target.value !== '' && inputName === 'bscAddress' && !isAddress(e.target.value)) {
      setError(t`Invalid wallet address `);
    }
  };
  return (
    <Modal
      className="modal-sticky modal-sticky-lg modal-sticky-bottom-right"
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="999"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-dialog mw-500px"
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="modal-header border-0 ">
          {/* Cập nhật thông tin */}
          <h2>
            <Trans>Update distributor</Trans>
          </h2>
          <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>
        <div className="modal-body py-0">
          <div className="row">
            <div className="col">
              <div className="mb-6">
                <label htmlFor="" className="form-label required">
                  <Trans>
                    {/* Tên ghi nhớ */}
                    <Trans>Memo name</Trans>
                  </Trans>
                </label>
                <input
                  className="form-control"
                  required
                  type="string"
                  maxLength={64}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container invalid-feedback_error">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="" className="form-label required">
                <Trans>Status</Trans>
              </label>
              <div>
                <div className="d-inline-flex w-auto  form-control  align-items-center gap-1 p-1">
                  {[
                    { name: <Trans>LOCKED</Trans>, value: false },
                    { name: <Trans>ACTIVE</Trans>, value: true },
                  ].map((item, k) => (
                    <TabItem
                      key={k}
                      active={item.value === formik.values.enabled}
                      onClick={() => {
                        formik.setFieldValue('enabled', item.value);
                      }}
                      label={item.name}
                      activeClass={`text-white ${item.value === true ? 'bg-success' : 'bg-danger'}`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label htmlFor="" className="form-label required">
              <Trans>Wallet address</Trans>
            </label>
            {chains
              .filter((it) => it?.code !== Chains.TRON)
              .map((item) => {
                let textField = 'bscAddress';
                switch (item.code) {
                  case Chains.POLYGON:
                    textField = 'polygonAddress';
                    break;
                  case Chains.VCHAIN:
                    textField = 'vchainAddress';
                    break;
                  default:
                    textField = 'bscAddress';
                    break;
                }

                return (
                  <div className="input-group mb-3">
                    <input
                      className="form-control text-truncate"
                      required
                      type="string"
                      {...formik.getFieldProps(`${textField}`)}
                      onChange={(e: any) => {
                        formik.setFieldValue(`${textField}`, e.target.value);
                        handleInputField(e, `${textField}`);
                        //  handleInputField(e, item, i, formik.setFieldValue);
                      }}
                      onPaste={(e: any) => {
                        //handleInputField(e, item, i, formik.setFieldValue);
                        formik.setFieldValue(`${textField}`, e.target.value);
                        handleInputField(e, `${textField}`);
                      }}
                    />
                    <span className="input-group-text bg-white d-flex gap-2 w-100px fs-sm" id="inputGroup-sizing-sm">
                      <img src={item.logoUrl} className="img-fluid w-20px" /> {item.name}
                    </span>
                  </div>
                );
              })}
            <div className="input-group mb-3">
              {!formik.errors.bscAddress && !formik.errors.polygonAddress && !formik.errors.vchainAddress && error && (
                <div className="fv-plugins-message-container invalid-feedback_error">
                  <div className="fv-help-block">{error}</div>
                </div>
              )}
              {formik.errors && !formik.touched.name && (
                <div className="fv-plugins-message-container invalid-feedback_error">
                  <div className="fv-help-block">{formik.errors.polygonAddress}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer border-0 pt-0">
          <button className="btn btn-outline" onClick={onHide}>
            <Trans>Cancel</Trans>
          </button>
          <button className="btn btn-primary" disabled={formik.isSubmitting || !formik.isValid || !formik.values.name}>
            {!loading && <Trans>Update</Trans>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                <Trans>Please wait</Trans> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};
