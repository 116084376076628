import { Route, Routes } from 'react-router-dom';

import AuthLayout from '../layout/AuthLayout';
import PageLogin from '../pages/auth/PageLogin';

const AuthRoutes = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<PageLogin />} />
      <Route index element={<PageLogin />} />
    </Route>
  </Routes>
);

export { AuthRoutes };
