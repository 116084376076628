import AxiosInstance from './axios';
import { IFilterTransaction, TransactionItem, TransactionModel } from '../store/transaction/types';

export const getTransaction = async (params: IFilterTransaction): Promise<TransactionModel> => {
  try {
    const res = await AxiosInstance.get('/merchant/transactions', {
      params,
    }).then((res) => res.data);

    const data: TransactionItem[] = (res.items || []).map(
      (i: { chain: string; token: string; amount: number; feeAmount: number; feePercent: number }) => ({
        ...i,
        chainName: i.chain,
        tokenIn: i.token,
        amount: i.amount || 0,
        feeAmount: i.feeAmount || 0,
        feePercent: i.feePercent || 0,
      })
    );

    const exportData = data.map((d) => ({
      'Mã thanh toán': d.paymentId,
      'Trạng thái': d.status,
      'Thời gian tạo': d.createdAt,
      'Thời gian hoàn thành': d.updatedAt,
      'Số tiền': d.amount,
      'Tiền Phí': d.feeAmount,
      'Tổng tiền': d.amount + d.feeAmount,
      'Loại giao dịch': d.txType,
      'Tên mạng': d.chainName,
      'Mã giao dịch trên chain': d.txHash,
    }));

    return {
      ...res,
      items: data,
      export: exportData,
    };
  } catch (err) {
    console.error('[API] getTransaction', err);
    throw err;
  }
};

export const getTopTransaction = async (params: IFilterTransaction): Promise<TransactionModel> => {
  try {
    const res: TransactionModel = await AxiosInstance.get('/merchant/transactions', {
      params: {
        ...params,
      },
    }).then((res) => res.data);
    const data: TransactionItem[] = (res.items || []).map((i) => ({
      ...i,
      chainName: i.chain,
      tokenIn: i.token,
      amount: i.amount || 0,
      feeAmount: i.feeAmount || 0,
      feePercent: i.feePercent || 0,
    }));

    const exportData = data.map((d) => ({
      'Mã thanh toán': d.paymentId,
      'Trạng thái': d.status,
      'Thời gian tạo': d.createdAt,
      'Thời gian hoàn thành': d.updatedAt,
      'Số tiền': d.amount,
      'Tiền Phí': d.feeAmount,
      'Tổng tiền': d.amount + d.feeAmount,
      'Loại giao dịch': d.txType,
      'Tên mạng': d.chainName,
      'Mã giao dịch trên chain': d.txHash,
    }));

    return {
      ...res,
      items: data,
      export: exportData,
    };
  } catch (err) {
    console.error('[API] getTransaction', err);
    throw err;
  }
};
