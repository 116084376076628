import { FC } from 'react';
import { Link } from 'react-router-dom';

const Error403: FC = () => {
  return (
    <div className="card ">
      <div className="card-header">
        <h3 className="card-title">Truy cập bị từ chối</h3>
      </div>
      <main className="card-body">
        <div className="fw-bold fs-3 text-gray-400 mb-15">
          Bạn không thể truy cập vào nội dung này. <br />
        </div>
        <Link className="btn btn-primary" to="/">
          Về trang chủ
        </Link>
      </main>
    </div>
  );
};

export { Error403 };
