/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  prefer-const */
import { useEffect, useState } from 'react';

export const useAbortRequest = (func: (signal?: AbortSignal) => void, isValidAbort: boolean, dependencies?: any[]) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [data, setData] = useState<any>();

  const controller = new AbortController();
  const signal = controller.signal;

  const fetchData = async () => {
    try {
      setErrorCode('');
      setIsLoading(true);
      let res = await func(signal);
      setData(res);
      setIsLoading(false);
    } catch (error: any) {
      setErrorCode(error?.response?.data?.code || '');
      setData(undefined);
    } finally {
      if (!signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isValidAbort) {
      fetchData();
    } else {
      setIsLoading(false);
      setData(undefined);
      setErrorCode('');
    }
    return () => {
      controller.abort();
    };
  }, dependencies);

  return { errorCode, isLoading, data };
};
