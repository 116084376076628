import clsx from 'clsx';
import { FC } from 'react';
import { languageData, useLanguageProvider } from '../../providers/LanguageProvider';

const Languages: FC = ({ className }: { className?: string }) => {
  const { language } = useLanguageProvider();
  const currentLanguage = languageData.find((x) => x.lang === language);
  // console.log('currentLanguage', currentLanguage);
  return (
    <div className={clsx('app-navbar-item', className)}>
      <button
        className={clsx('cursor-pointer d-flex align-items-center', ' fw-bolder fs-5', 'btn btn-light-primary ')}
        data-kt-menu-trigger="{default: 'click'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        {currentLanguage?.name}{' '}
        <img className="w-15px h-15px rounded-1 ms-2" src={currentLanguage?.flag} alt="metronic" />
      </button>

      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-2 fs-6 w-200px"
        data-kt-menu="true"
      >
        {languageData.map((l) => (
          <div className="menu-item px-3" key={l.lang}>
            <a
              href={`?lng=${l.lang}`}
              className={clsx('menu-link d-flex px-5', {
                'bg-primary  text-white': l.lang === currentLanguage?.lang,
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt={l.flag} />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Languages };
