/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ApiListModel, Currency } from '../types';
import AxiosInstance from './axios';

const getCurrencyBalance = async (id: string): Promise<number | null> => {
  const url = `/assets/${id}/balance`;
  const data = await AxiosInstance.get(url)
    .then((res) => res.data)
    .catch((e) => {
      console.log('[API] can not get asset balance', e);
      return null;
    });

  return data.balance ?? 0;
};
const getCurrencyList = async (): Promise<ApiListModel> => {
  const items: Currency[] = [];
  const response = await AxiosInstance.get('/assets?page=1&size=20&sort=chainId,asc&sort=typeToken,asc')
    .then((res) => res.data)
    .catch((e) => {
      console.log('[API] Can not get merchant balances', e);
      return null;
    });
  if (response && response.items.length) {
    response.items.forEach(
      (item: {
        id: string;
        typeToken: string;
        configs: {
          minAccountBalance: number;
          maxAccountBalance: number;
          minTransfer: number;
          maxTransfer: number;
          coldWalletAddress: string;
        };
        addressToken: string;
        wallet: {
          coinBalance: number;
          coinSymbol: string;
          chainName: string;
          id: string;
          address: string;
          configs: { feePercent: string };
        };
        chainId: number;
      }) => {
        items.push({
          id: item.id,
          symbol: item.typeToken,
          balance: 0,
          balanceAvailable: 0,
          balanceOnHold: 0,
          minAccountBalance: item.configs.minAccountBalance,
          maxAccountBalance: item.configs.maxAccountBalance,
          minTransfer: item.configs.minTransfer,
          maxTransfer: item.configs.maxTransfer,
          coldWalletAddress: item?.configs?.coldWalletAddress,
          token: {
            address: item.addressToken,
            tokenType: item.typeToken,
            symbol: item.typeToken,
            name: item.typeToken,
          },
          nativeBalance: {
            balance: item.wallet.coinBalance ?? 0,
            symbol: item.wallet.coinSymbol,
          },
          chain: {
            id: item.chainId,
            name: item.wallet.chainName,
          },
          wallet: {
            id: item.wallet.id,
            address: item.wallet.address,
            feePercent: item.wallet.configs.feePercent,
          },
        });
      }
    );
  }
  return {
    page: 1,
    totalItems: items.length,
    totalPages: 1,
    perPage: 20,
    items: items,
  };
};

const getCurrencyHistory = async (page: number, pageSize?: number, params?: any) => {
  let filterString = '';
  if (params) {
    // let filters = new URLSearchParams(params);
    // console.log("filters", filters);
    for (const [key, value] of params.entries()) {
      //  console.log(`getCurrencyHistory ${key}, ${value} {}`);
      if (value) {
        if (filterString !== '') {
          filterString += ' and ';
        }
        filterString += `${key}:'${value}'`;
      }
    }
  }

  const url =
    `/wallets/balance-changes?page=${page}&size=${pageSize}${filterString ? '&filter=' + filterString : ''}` +
    '&sort=createdAt,DESC';
  try {
    const res = await AxiosInstance.get(url).then((res) => res.data);
    const rawData = res?.items;
    const items: any[] = [];
    if (rawData) {
      rawData.forEach((item: any) => {
        items.push({
          id: '',
          fromAddress: item.fromAddress,
          toAddress: item.toAddress,
          chain: {
            chainId: item.chainId,
            name: item.chainName,
          },
          transactionType: item.type,
          txHash: item.txHash,
          token: {
            address: item.tokenAddress,
            symbol: item.tokenType,
            icon: '',
          },
          amount: item.changeAmount,
          type: item.cashFlowDirection,
          balance: item.balance,
          createdAt: item.createdAt,
        });
      });
    }
    return {
      page: res.page,
      size: res.perPage,
      totalItems: res.totalItems,
      totalPages: res.totalPages,
      items: items,
    };
  } catch (e) {
    console.error('[API] Can not get transaction', e);
    return null;
  }
};

// const withdraw = async (walletId: string, currencyId: string, formData: CurrencyFormValues) => {
//   const url = `/wallets/${walletId}/transfer/${currencyId}`;
//   try {
//     const data = await AxiosInstance.put(url, { walletId, assetId: currencyId, ...formData }).then((res) => res.data);

//     return {
//       status: true,
//       msg: 'Success',
//       data,
//     };
//   } catch (e: any) {
//     console.log('[API] Can not put data', e);
//     let msg = e.message;
//     if (e.response && e.response.data && e.response.data.message) {
//       msg = e.response.data.message;
//     }
//     return {
//       status: false,
//       msg,
//       data: e?.response?.data,
//     };
//   }
// };

/**
 * Make a request to withdraw my money from system to my wallet. Require to multi sign before transfer.
 *
 * @param walletId
 * @param currencyId
 * @param formData
 * @returns
 */
const requestWithdraw = async (currencyId: string, formData: any) => {
  const url = '/pool/withdrawal';
  // console.log('formData', formData);
  try {
    const data = await AxiosInstance.post(url, {
      assetId: currencyId,
      ...formData,
    }).then((res) => res.data);

    return {
      status: true,
      msg: 'Success',
      data,
    };
  } catch (e) {
    console.log('[API] Can not put data', e);
  }
};

export interface FormUpdateCurrency {
  balanceMin: number;
  balanceMax: number;
  amountMin: number;
  amountMax: number;
  coldWallet: string;
}
export const updateCurrency = async (
  id: string,
  { balanceMin, balanceMax, amountMin, amountMax, coldWallet }: FormUpdateCurrency
) => {
  const url = `/assets/${id}/setting`;
  try {
    const formData = {
      minAccountBalance: balanceMin,
      maxAccountBalance: balanceMax,
      minTransfer: amountMin,
      maxTransfer: amountMax,
      coldWalletAddress: coldWallet,
      id,
    };
    const data = await AxiosInstance.put(url, formData).then((res) => res.data);

    // console.log("put data", data);

    return {
      status: true,
      msg: data.message,
    };
  } catch (e) {
    console.log('[API] Can not put data', e);
  }
};
const CurrencyService = {
  getCurrencyBalance,
  getCurrencyList,
  getCurrencyHistory,
  updateCurrency,
  requestWithdraw,
  // getWithdrawRequests,
  // getWithdrawRequestDetail,
  // getRawTransaction,
  // updateTransaction,
};
export default CurrencyService;
