import React, { FC, useEffect, useState, PropsWithChildren } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import CopyToClipboard from 'react-copy-to-clipboard';
import clsx from 'clsx';
import { AiOutlineEye } from 'react-icons/ai';

const DataBox: FC<
  PropsWithChildren<{
    value: string;
    copyable?: boolean;
    secret?: boolean;
    loadData?: () => void;
  }>
> = ({ value, copyable, children, secret, loadData }) => {
  const [copy, setCopy] = useState(false);

  const [hidedSecret, showSecret] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setCopy(false), 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copy]);

  return (
    <div className="fv-row d-flex gap-3">
      <div className="flex-shrink-1 overflow-hidden w-100">
        <div className="form-control form-control-solid-bg text-truncate">
          {secret ? (
            <div className="d-flex gap-2 align-items-center ">
              <div className="w-100 overflow-hidden ">
                {hidedSecret ? '**************************************' : value}
              </div>
              <AiOutlineEye
                size={20}
                onClick={() => {
                  if (loadData && hidedSecret) {
                    loadData();
                  }
                  showSecret(!hidedSecret);
                }}
              />
            </div>
          ) : (
            value
          )}
        </div>
      </div>
      <div className="d-flex gap-2">
        {copyable && (
          <OverlayTrigger overlay={<Tooltip id="tooltip-copy-to-clipboard">Sao chép</Tooltip>}>
            <CopyToClipboard text={value} onCopy={() => setCopy(true)}>
              <Button variant="secondary">
                <i
                  className={clsx('cursor-pointer fs-4 mw-15px', {
                    'fas fa-check text-success': copy,
                    'far fa-copy': !copy,
                  })}
                />
              </Button>
            </CopyToClipboard>
          </OverlayTrigger>
        )}
        {children}
      </div>
    </div>
  );
};

export default DataBox;
