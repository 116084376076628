import AxiosInstance from './axios';
import { DashboardModel } from '../store/dashboard/types';
import { IDepositFee, IFilterTransaction, IWithdrawFee } from '../store/transaction/types';
import { MultiCurrency, Totalfiatbalance } from '../types/MerchantNFT';

const getDashboard = async (params: IFilterTransaction): Promise<DashboardModel> => {
  try {
    const res = await AxiosInstance.get('/merchant/dashboard', { params }).then((res) => res.data);

    const fiatTransactions = res.fiatTransactions;

    return {
      ...res,
      summary: {
        currency: res.currency,
        fiatTransactions,
      },
    };
  } catch (err) {
    console.log('[API] getDashboard', err);
    throw err;
  }
};

const withdraw = async ({ chainCode, amount, token }: { chainCode: string; amount: number; token: string }) =>
  AxiosInstance.post('/merchant/withdraw', { token, chainCode, amount });

//su dung nft
const calculateFee = async ({
  amount,
  chainCode,
  token,
  type,
  signal,
}: {
  amount: number;
  chainCode: string;
  token: string;
  type: 'withdraw' | 'deposit';
  signal?: AbortSignal;
}): Promise<IWithdrawFee | IDepositFee> => {
  try {
    const url = `/merchant/${type}/calculate-fee`;
    return await AxiosInstance.post(
      url,
      {
        amount,
        chainCode,
        token,
      },
      { signal }
    ).then((res) => res.data);
  } catch (err) {
    console.log('[API] calculateFee', err);
    throw err;
  }
};

const exportExcel = async (params: IFilterTransaction) => {
  try {
    delete params.page;
    const url = '/merchant/transactions/export';
    const res = await AxiosInstance.get(url, { params, responseType: 'blob' });

    const disposition = res.headers['content-disposition']?.toString();
    const filenameRegex = /filename[^;=\n]*=(([‘“]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition || '');
    const filename =
      matches && matches[1] ? matches[1].replace(/['"]/g, '') : `download-file-${new Date().getTime()}.xlsx`;

    const dataUrl = URL.createObjectURL(res.data);

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err: any) {
    //Alert.
    console.log('[API] exportExcel', err.response.status);
    throw err;
  }
};

//suspend
const suspend = async ({ type }: { type: boolean }): Promise<boolean> => {
  try {
    const url = `/merchant/suspend?suspended=${type}`;
    return await AxiosInstance.put(url).then((res) => res.data);
  } catch (err) {
    console.log('[API] suspend - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

const setCallbackUrl = async ({ callBackUrl }: { callBackUrl: string }) => {
  try {
    const url = '/merchant/call-back-url';
    return await AxiosInstance.put(url, { callBackUrl }).then((res) => res.data);
  } catch (err) {
    console.log('[API] setCallbackUrl - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

const getTxDetails = async (txId: string) => {
  try {
    const url = `/merchant/transactions/${txId}`;
    const data = await AxiosInstance.get(url).then((res) => res.data);
    return data;
  } catch (err) {
    console.log('[API] getTxDetails : ', err);
    throw err;
  }
};

const getMerchantBalance = async () => {
  try {
    const url = '/merchant/balance';
    const data = await AxiosInstance.get(url).then((res) => res.data);
    return data;
  } catch (err) {
    console.log('[API]: getMerchantBalance', err);
    throw err;
  }
};

const getApikey = async () => {
  try {
    const url = '/merchant/api-key';
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('[API]: getApikey', err);
    throw err;
  }
};

const postSessionDeposit = async (params: { typeToken: string; chainCode: string; amount: number }) => {
  try {
    const url = '/merchant/deposit';
    return await AxiosInstance.post(url, params).then((res) => res.data);
  } catch (err) {
    console.log('[API] postSessionDeposit', err);
    throw err;
  }
};

// Multi currency
const getMultiCurrency = async (): Promise<MultiCurrency> => {
  try {
    const url = '/merchant/multi-currency';
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('[API] getMultiCurrency', err);
    throw err;
  }
};
// total-fiat-balance
const getTotalfiatbalance = async (): Promise<Totalfiatbalance> => {
  try {
    const url = '/merchant/total-fiat-balance';
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('[API] getTotalfiatbalance', err);
    throw err;
  }
};

const updateSessionDeposit = async (sessionId: string, txHash: string) => {
  try {
    const url = '/merchant/deposit';
    return await AxiosInstance.put(url, { id: sessionId, txHash }).then((res) => res.data);
  } catch (err) {
    console.log('[API] updateSessionDeposit', err);
    throw err;
  }
};
const MerchantService = {
  getDashboard,
  calculateFee,
  withdraw,
  exportExcel,
  suspend,
  setCallbackUrl,
  getTxDetails,
  getApikey,
  postSessionDeposit,
  updateSessionDeposit,
  getMerchantBalance,
  getMultiCurrency,
  getTotalfiatbalance,
};
export default MerchantService;
