import { Trans } from '@lingui/macro';
import { FC } from 'react';

const Error404: FC = () => {
  return (
    <div className="card">
      <div className="card-body p-5">
        <h1 className="fw-bolder fs-4x text-gray-700 mb-5">
          <Trans>Page not found</Trans>
        </h1>
        <div className="fw-bold fs-3 text-gray-400 mb-5">
          <Trans>
            The page you search does not exist
            <br /> Or the data has been deleted.
          </Trans>
        </div>
        <div className="mb-3 d-flex align-content-center justify-content-center">
          <img src="../img/404-error.png" className="mw-100 mh-300px theme-light-show" alt=""></img>
        </div>
      </div>
    </div>
  );
};

export { Error404 };
