import React, { PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const WithTooltip: React.FC<
  PropsWithChildren<{
    id: string;
    tooltip: React.ReactNode;
    placement?: 'top' | 'bottom' | 'right' | 'left';
    className?: string;
  }>
> = ({ id, children, tooltip, placement = 'bottom', className = 'd-inline-block' }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip className="tooltip-auto-width text-start tooltip-inverse" id={id}>
          {tooltip}
        </Tooltip>
      }
      placement={placement}
    >
      <span className={className}>{children}</span>
    </OverlayTrigger>
  );
};

export default WithTooltip;
