/* eslint-disable  @typescript-eslint/no-explicit-any */
import moment from 'moment';
import 'moment/locale/vi';
import { ShortNumber as FSN } from '@lytieuphong/short-number';
import { CurrencyChain, CurrencySymbols } from '../types/CurrencyBalance';

const locale = 'en-US';

export function formatNumber(number: number | bigint | string | undefined, maximumFractionDigits: number = 2): string {
  if (!number) return '0';
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-expect-error
    roundingMode: 'floor',
  }).format(parseFloat(number as string));
}

export function formatCurrency(
  value: number | bigint | string,
  currency = '',
  hideCurrency = false,
  maximumFractionDigits = 4
): string {
  const _currency = !hideCurrency ? ` ${currency}` : '';
  const isVND = [CurrencySymbols.VIC.toString(), CurrencySymbols.VND.toString()].includes(currency);

  if (value && parseFloat(value.toString()) <= 0.9999999999999999) {
    return formatNumber(value, isVND ? 0 : maximumFractionDigits) + _currency;
  }

  return formatNumber(value, isVND ? 0 : maximumFractionDigits) + _currency;
}

export function currencyFormat(
  number?: number | bigint | string,
  currency = '',
  maximumFractionDigits?: number,
  options?: { hasFormatNumber?: boolean; hideCurrency?: boolean }
): string {
  const _currency = options?.hideCurrency ? '' : ` ${currency}`;
  const isVIC = [CurrencySymbols.VIC.toString(), CurrencySymbols.VND.toString()].includes(currency);

  const fractionDigits = isVIC ? 0 : parseFloat(number as string) > 1 ? 2 : maximumFractionDigits ?? 5;

  return formatNumber(number, fractionDigits) + _currency;
}

type Type = 'Date' | 'Time';

export const showDateTime = (date: any, type?: Type) => {
  let dateString;

  switch (type) {
    case 'Date':
      dateString = moment(date).format('DD/MM/YYYY');
      break;
    case 'Time':
      dateString = moment(date).format('HH:mm:ss');
      break;
    default:
      dateString = moment(date).format('MM/DD/YYYY HH:mm:ss');
      break;
  }

  return dateString;
};

export const showDateTimeUTC = (date: any, type?: Type) => {
  let dateString;

  switch (type) {
    case 'Date':
      dateString = moment(date).utc().format('DD/MM/YYYY');
      break;
    case 'Time':
      dateString = moment(date).utc().format('HH:mm:ss');
      break;
    default:
      dateString = moment(date).utc().format('MM/DD/YYYY HH:mm:ss');
      break;
  }

  return dateString;
};

export function showTime(timestamp: any) {
  const days = moment.utc(timestamp).fromNow();
  return days;
}

export const calculateLeftTime = (timestamp: any) => {
  moment.locale('vi');
  const days = moment.utc(timestamp).add(5, 'days').fromNow();
  console.log('days', days);
  return days;
};

export function ellipseAddress(address: string = '', width: number = 4): string {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}
export function ellipsePrefixName(name: string = '', width: number = 4): string {
  return `${name.slice(0, width)}`;
}

export function capitalizeTxt(txt: any) {
  return txt.charAt(0).toUpperCase() + txt.slice(1); //or if you want lowercase the rest txt.slice(1).toLowerCase();
}

export function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join('0');
    }
  }
  return x;
}

export const formatShortNumber = (num: number): string | number => {
  return FSN(num);
};

export const preventInvalidKeys = (e: { key: string; preventDefault: () => void }) => {
  const forbiddenKeys = ['e', 'E', '+', '-', '.', ','];

  if (forbiddenKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const getChainColor = (chain: string) => {
  let classS = 'text-info fw-bolder';
  if (chain === 'VCHAIN') {
    classS = 'text-primary fw-bolder';
  } else if (chain === CurrencyChain.POLYGON) {
    classS = 'text-success fw-bolder';
  } else if (chain === 'BINANCE') {
    classS = 'text-warning fw-bolder';
  }
  return classS;
};
const formatDate = (date: any) => moment(date).format('DD-MM-YYYY');
const FormatHelper = { formatDate };

export default FormatHelper;
