import { GET_CURRENCIES_REQUEST, GET_CURRENCIES_FAILED, GET_CURRENCIES_SUCCESS } from './actionTypes';
import {
  FetchCurrencyRequest,
  FetchCurrencySuccess,
  FetchCurrencySuccessPayload,
  FetchCurrencyFailure,
  FetchCurrencyFailurePayload,
} from './types';

export const fetchCurrencyRequest = (): FetchCurrencyRequest => ({
  type: GET_CURRENCIES_REQUEST,
});

export const fetchCurrencySuccess = (payload: FetchCurrencySuccessPayload): FetchCurrencySuccess => ({
  type: GET_CURRENCIES_SUCCESS,
  payload,
});

export const fetchCurrencyFailure = (payload: FetchCurrencyFailurePayload): FetchCurrencyFailure => ({
  type: GET_CURRENCIES_FAILED,
  payload,
});
