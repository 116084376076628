import { FC } from 'react';

const TokenChainImg: FC<{
  token: string;
  chain?: string;
  zoom?: number;
  w?: string;
}> = ({ token, chain, zoom = 1, w = '20px' }) => {
  return (
    <div className="position-relative h-25px" style={{ zoom }}>
      {token && <img width={24} src={`/img/${token}.svg`} alt="token" />}
      {chain ? (
        <span className="position-absolute" style={{ left: '16px', bottom: '-5px' }}>
          <img src={`/img/${chain}.svg`} width={w} alt="chain" />
        </span>
      ) : (
        <span className="position-absolute bottom-0" style={{ left: '25px' }} />
      )}
    </div>
  );
};

export default TokenChainImg;
