export interface MertchantTx {
  txCashFlowInfo: TxCashFlowInfo;
  generalTxInfo: GeneralTxInfo;
  txLogInfo: TxLogInfo;
  partnerInfo: PartnerInfo;
  additionalInfo: AdditionalInfo;
  paymentId: string;
  recipientAddress: string;
  senderAddress: string;
  baseToken: string;
  chainCode: string;
}

interface AdditionalInfo {
  quantity: number;
  totalNftValue: number;
  nftInfos: NftInfo[];
}

interface NftInfo {
  tokenId: number;
  value: number;
  currency: string;
  issuedMerchantId: string;
  nftAddress: string;
  chainCode: string;
}

interface PartnerInfo {
  paymentId: string;
  depositAddress: string;
  withdrawAddress: string;
  senderAddress: string;
  gasless: boolean;
}

interface TxLogInfo {
  logs: Log[];
  errorMessage: string;
}

interface Log {
  date: string;
  status: string;
}

interface GeneralTxInfo {
  txValue: {
    currency: string;
    value: number;
  };
  txType: string;
  txHash: string;
  status: string;
  txDate: string;
}

interface TxCashFlowInfo {
  amountInBaseToken: number;
  txFeeAmountInVic: number;
  actualTxAmountAfterCostInBaseToken: number;
  actualReceivedAmount: {
    currency: string;
    value: number;
  };
  cashFlowDirection: string;
  txValue: {
    currency: string;
    value: number;
  };
  gasCostInBaseToken: number;
  serviceCostInBaseToken: number;
}

export enum MerchantType {
  VIC_BASED = 'VIC_BASED',
  MULTI_CURRENCY = 'MULTI_CURRENCY',
}
