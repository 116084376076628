import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, currencyFormat, formatCurrency } from '../../helpers';
import { Modal } from 'react-bootstrap';
import { getSettingsSelector } from '../../store/settings/selectors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { FaIcons } from '../../helpers/FaIconHelper';
import MerchantService from '../../services/MerchantService';
import DateTime from '../common/DateTime';
import moment from 'moment';
import { CurrencySymbols } from '../../types/CurrencyBalance';

import { AiOutlineExclamationCircle } from 'react-icons/ai';
import WithTooltip from '../WithTooltip';
import FormikTokenSelect from './FormikTokenSelect';
import { ITokenChain } from '../../types';
import NumberInput from '../form/NumberInput';
import { useTokenChain } from '../../hooks/useTokenChain';

import { useAbortRequest } from '../../hooks/useAbortRequest';
import { Trans, t } from '@lingui/macro';
import ActionPendingModal from '../deposit/ActionPendingModal';
import { getErrorMessage } from '../../helpers/ErrorHelper';
import { MerchantType } from '../../types/Merchant';
import { useAuth } from '../../store/auth';
import { TypeTokens } from '../../types/Asset';
import AuthService from '../../services/AuthService';
import { SETTINGS } from '../../store/settings/actions';

const WithdrawalModal = ({ show, onHide, loadData }: { show: boolean; onHide: () => void; loadData: () => void }) => {
  const settings = useSelector(getSettingsSelector);

  const { tokenChains } = useTokenChain();
  const { auth, currentUser } = useAuth();
  const tokens =
    currentUser && currentUser.type === MerchantType.VIC_BASED
      ? tokenChains.filter((chain) => chain.token.symbol !== TypeTokens.USDT)
      : tokenChains;

  const [loading, setLoading] = useState(false);
  const [selectedChain, setSelectedChain] = useState<ITokenChain>(tokens[0]);

  const [confirmedValues, setShowConfirm] = useState<IWithdrawConfirm | null>();

  const [isMinError, setIsMinError] = useState<boolean>(false);
  const [usdtBalance, setUSDTBalance] = useState(0);
  const [vicBalance, setVICBalance] = useState(0);

  const dispatch = useDispatch();
  const [withdrawPendingShow, setWithdrawPendingShow] = useState(false);
  const initialValues = {
    amount: 0,
  };

  const balance = selectedChain.token.symbol === CurrencySymbols.USDT ? usdtBalance : vicBalance;

  const onSubmit = async (
    values: { amount: number },
    { setSubmitting }: { setSubmitting: (submitted: boolean) => void }
  ) => {
    let userMerchant;
    if (auth) {
      userMerchant = await AuthService.getMe(auth.token);
      dispatch(SETTINGS.REQUEST());
    }

    if (userMerchant?.suspended) {
      Alert.error(
        t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
        true
      );
      return;
    }
    if (values.amount <= 0) {
      setIsMinError(true);
      return;
    }

    if (values.amount > balance) {
      return;
    }

    setLoading(true);

    setShowConfirm({
      amount: values.amount,
      chainName: selectedChain.code,
      token: selectedChain.token.symbol,
    });

    setLoading(false);
    setSubmitting(false);
  };

  useEffect(() => {
    loadBalance();
  }, []);

  const loadBalance = async () => {
    try {
      const balance = await MerchantService.getMerchantBalance();
      if (currentUser && currentUser.type === MerchantType.MULTI_CURRENCY) {
        const usdt = balance.available.find(
          (blc: { currency: CurrencySymbols }) => blc.currency === CurrencySymbols.USDT
        );
        setUSDTBalance(usdt.value);
      }

      const vic = balance.available.find((blc: { currency: CurrencySymbols }) => blc.currency === CurrencySymbols.VIC);
      setVICBalance(vic.value);
    } catch (error) {
      Alert.error(t`Cannot get data`, true);
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required(t`You haven't entered the amount`)
      .max(balance, t`Exceeds the available balance`),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    isLoading: isLoadingFee,
    errorCode,
    data: fee,
  } = useAbortRequest(
    (signal) =>
      MerchantService.calculateFee({
        amount: parseFloat((formik?.values?.amount ?? 0)?.toString()),
        chainCode: selectedChain.code,
        token: selectedChain.token.symbol,
        type: 'withdraw',
        signal,
      }),
    Number(formik?.values?.amount) > 0,
    [Number(formik?.values?.amount)]
  );
  return (
    <Modal
      className={clsx('modal-sticky modal-sticky-lg modal-sticky-bottom-right', confirmedValues ? 'invisible' : '')}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-md"
      onHide={onHide}
    >
      <div className="modal-body">
        <div className="position-relative w-100 mb-5">
          <h1>
            <Trans>Withdraw token</Trans>
          </h1>
          <button
            onClick={onHide}
            className="btn btn-sm btn-icon btn-active-color-primary position-absolute top-0 end-0"
          >
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div>
            <div className="mb-5">
              <label htmlFor="" className="form-label d-flex gap-1">
                <span>
                  <Trans>Withdrawal account</Trans>
                </span>
                <WithTooltip
                  id="tooltip-1"
                  placement="top"
                  tooltip={
                    <div className="text-start">
                      <Trans>The wallet address you registered when setting up the partner account.</Trans>
                    </div>
                  }
                >
                  <AiOutlineExclamationCircle />
                </WithTooltip>
              </label>
              <div className="form-control bg-black bg-opacity-5">{settings.merchant.withdrawAddress}</div>
            </div>

            <div className="p-5 mb-5 rounded-4 " style={{ background: '#F3F5FA' }}>
              <div className="">
                <div className="d-flex justify-content-between">
                  <FormikTokenSelect
                    chains={tokens}
                    formik={formik}
                    selectedChain={selectedChain}
                    setSelectedChain={setSelectedChain}
                  />
                  <div className="text-end">
                    <div className={'float-end d-flex gap-2 mb-2'}>
                      <Trans>Available balance</Trans>:
                      <b>{formatCurrency(balance, selectedChain.token.symbol, true)}</b>
                      <button
                        type="button"
                        className="btn btn-primary btn-xs  p-1 px-2 fs-9 rounded-1"
                        onClick={() => {
                          formik.setFieldValue(
                            'amount',
                            Number(
                              currencyFormat(balance, selectedChain.token.symbol, undefined, {
                                hideCurrency: true,
                              }).replaceAll(',', '')
                            )
                          );
                        }}
                      >
                        <Trans>Max</Trans>
                      </button>
                    </div>
                    <NumberInput
                      id="currencyInput"
                      maxLength={15}
                      autoComplete="off"
                      placeholder="0"
                      autoFocus={true}
                      onValueChange={(value) => {
                        if (Number(value) > 0) {
                          setIsMinError(false);
                        }
                        formik.setFieldValue('amount', value);
                      }}
                      allowDecimals={!selectedChain?.token?.symbol?.includes('VIC')}
                      defaultValue={formik.values.amount}
                      value={formik.values.amount}
                    />

                    {formik.errors.amount && (
                      <div className="fv-plugins-message-container invalid-feedback_error">
                        <div className="fv-help-block">{formik.errors.amount}</div>
                      </div>
                    )}

                    {!isLoadingFee &&
                      !formik.errors.amount &&
                      fee &&
                      Number(fee.actualReceiveInfo.amount) <= 0 &&
                      formik.values.amount > 0 && (
                        <div className="fv-plugins-message-container invalid-feedback_error">
                          <div className="fv-help-block">
                            <Trans>Actual received token have to greater than 0</Trans>
                          </div>
                        </div>
                      )}
                    {!isMinError &&
                      errorCode &&
                      !formik.errors.amount &&
                      formik.values.amount > 0 &&
                      (!fee || Number(fee?.actualReceiveInfo.amount) > 0) && (
                        <div className="fv-plugins-message-container invalid-feedback_error">
                          <div className="fv-help-block">{getErrorMessage(errorCode)}</div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="rounded-4  border p-5 mb-5">
              <div className="mb-5 fw-bolder ">
                <Trans>Token withdrawal information</Trans>
              </div>
              <div className="row mb-5">
                <div className="col fw-bold ">
                  <Trans>Transaction amount</Trans>
                </div>
                <div className="col">
                  <div className="text-end">
                    {formik.values.amount > 0 ? formatCurrency(formik.values.amount, selectedChain.token.symbol) : '--'}
                  </div>
                  {selectedChain.token.symbol === CurrencySymbols.USDT &&
                    fee &&
                    currentUser?.type === MerchantType.VIC_BASED && (
                      <div className="text-end opacity-50 ">
                        (~{formatCurrency(fee.amountInBaseToken.amount, fee.amountInBaseToken.token)})
                      </div>
                    )}
                </div>
              </div>

              <div className="row mb-5">
                <div className="col fw-bold ">
                  <Trans>Service fee</Trans>
                </div>
                <div className="col">
                  <div className="text-end">
                    {isLoadingFee ? (
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    ) : fee ? (
                      <>{formatCurrency(fee.transactionCost.serviceCost, fee.transactionCost.token)}</>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col fw-bold ">
                  <Trans>Gas fee</Trans>
                </div>
                <div className="col">
                  <div className="text-end">
                    {isLoadingFee ? (
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    ) : fee ? (
                      formatCurrency(fee.transactionCost.gasCost, fee.transactionCost.token)
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col fw-bold ">
                  <Trans>
                    {/* Số token thực nhận */}
                    Actual received token
                  </Trans>
                </div>
                <div className="col">
                  <div className="text-end">
                    {isLoadingFee ? (
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    ) : fee ? (
                      <span className={fee.actualReceiveInfo.amount < 0 ? 'text-danger' : ''}>
                        {formatCurrency(fee.actualReceiveInfo.amount, fee.actualReceiveInfo.token)}
                      </span>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={
                  !fee ||
                  formik.isSubmitting ||
                  !formik.isValid ||
                  isMinError ||
                  Number(fee?.actualReceiveInfo.amount) <= 0 ||
                  isLoadingFee ||
                  settings.merchant.suspended
                }
              >
                {!loading && <Trans>Withdraw token</Trans>}
                {loading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    <Trans>Please wait</Trans>{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>

      {confirmedValues && (
        <ModalWithdrawConfirm
          show={!!confirmedValues}
          onHide={() => setShowConfirm(null)}
          confirmedValues={confirmedValues}
          setWithdrawPendingShow={setWithdrawPendingShow}
        />
      )}
      {/* "Xác nhận rút token" */}
      {withdrawPendingShow && (
        <ActionPendingModal
          title={t`Confirm token withdrawal`}
          show={withdrawPendingShow}
          onHide={() => {
            loadData();
            onHide();
          }}
        />
      )}
    </Modal>
  );
};

export default WithdrawalModal;

function ModalWithdrawConfirm({
  confirmedValues,
  show,
  onHide,
  setWithdrawPendingShow,
}: {
  confirmedValues: IWithdrawConfirm;
  show: boolean;
  onHide: () => void;
  setWithdrawPendingShow: (show: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);

  const settings = useSelector(getSettingsSelector);
  const [done, setDone] = useState(false);

  const confirm = () => {
    setLoading(true);

    const { amount, chainName, token } = confirmedValues;
    MerchantService.withdraw({
      amount,
      chainCode: chainName,
      token,
    })
      .then((res) => {
        if (res.data.status === 403 && res.data.code === 'MERCHANT_SUSPENDED') {
          Alert.error(
            t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
            true
          );
          setLoading(false);
          onHide();
          setDone(false);
        } else {
          setDone(true);
          setWithdrawPendingShow(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        const errorCode = error?.response?.data?.code;
        setLoading(false);
        Alert.error(getErrorMessage(errorCode), true);
      });
  };

  return (
    <Modal
      className={clsx('modal-sticky modal-sticky-lg modal-sticky-bottom-right', done ? 'invisible' : '')}
      id="kt_mega_menu_modal"
      aria-hidden="true"
      tabIndex="-1"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      dialogClassName="modal-md"
      onHide={onHide}
    >
      <div className="modal-body">
        <div className="position-relative w-100 mb-5">
          <h2>
            <Trans>Confirm token withdrawal</Trans>
          </h2>
          <button
            onClick={onHide}
            className="btn btn-sm btn-icon btn-active-color-primary position-absolute top-0 end-0"
          >
            <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
          </button>
        </div>

        <div className="row mb-5">
          <div className="col">
            <label className="form-label mb-1">
              <Trans>
                {/*  */}
                Requested date
              </Trans>
            </label>
            <div className="fw-bold fs-2">
              {/* {confirmedValues.profitRecognition} */}
              <DateTime isInline value={moment().toLocaleString()} />
            </div>
          </div>
          <div className="col">
            <label className="form-label mb-1">
              <Trans>
                {/* Số tiền muốn rút */}
                Requested withdrawal amount
              </Trans>
            </label>
            <div className="fw-bold fs-2">{formatCurrency(confirmedValues.amount, confirmedValues.token)}</div>
          </div>
        </div>
        <div className="mb-5">
          <label className="form-label mb-1">
            <Trans>Withdrawal address</Trans>
          </label>
          <div className="fw-bold fs-2">{settings.merchant.withdrawAddress}</div>
        </div>
      </div>

      <div className="modal-footer py-2">
        <button className="btn btn-outline" onClick={onHide}>
          <Trans>Cancel</Trans>
        </button>
        <button onClick={confirm} className="btn btn-primary" disabled={loading || settings.merchant.suspended}>
          {loading ? (
            <span className="indicator-progress" style={{ display: 'block' }}>
              <Trans>Please wait</Trans>
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <Trans>Confirm</Trans>
          )}
        </button>
      </div>
    </Modal>
  );
}

type IWithdrawConfirm = {
  chainName: string;
  token: string;
  amount: number;
};
