import { createSelector } from 'reselect';

import { RootState } from '../RootReducer';

const getPending = (state: RootState) => state.currency.pending;

const getCurrencies = (state: RootState) => state.currency.currencies;

const getError = (state: RootState) => state.currency.error;

export const getCurrenciesSelector = createSelector(getCurrencies, (currencies) => currencies);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
