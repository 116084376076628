export enum IconFonts {
  dashboard = 'icon icon-dashboard',
  asset = 'icon icon-asset',
  bell = 'icon icon-bell',
  account = 'icon icon-account',
  exchange = 'icon icon-exchange',
  log = 'icon icon-log',
  reconcile = 'icon icon-reconcile',
  report = 'icon icon-report',
  request = 'icon icon-request',
  wallet = 'icon icon-wallet',
  bottom_arrow_down_left = 'icon icon-bottom-arrow-down-left',
  bottom_arrow_up_right = 'icon icon-bottom-arrow-up-right',
  chart = 'icon-chart',
  fire = 'icon-fire',
  list = 'icon-list',
  percent = 'icon-percent',
  printer = 'icon-printer',
  send = 'icon-send',
  usdt = 'icon-usdt',
  vndt = 'icon-vndt',
  hitoryDoc = 'icon-history-doc',
  hitoryBoard = 'icon-history-board',
  hitoryList = 'icon-history-list',
  moneyRequest = 'icon-money-request',
}
