import { useEffect, useState } from 'react';
import MerchantNFTService from '../../services/MerchantNFTService';
import { INftIssueHistory } from '../../types/MerchantNFT';
import { TxStatus } from '../../types/Transaction';
import { getExplorerLink } from '../../helpers';
import { Address } from '../../components/token/Address';
import { LoadingIcon } from '../../components/common/LoadingIcon';

const QueueTransactionHash = ({ tx }: { tx: INftIssueHistory }) => {
  const mustPending = !tx.txHash && tx.status === TxStatus.CREATED;

  // const [counter, count] = useState(0);
  const [newTxhash, setNewTxHash] = useState<string>();

  const loadData = async (counter: number) => {
    if (counter < 2) {
      const data = await MerchantNFTService.getNft(tx.id);
      setNewTxHash(data.txHash || undefined);
    } else {
      setNewTxHash('');
    }
  };

  useEffect(() => {
    let count = 0;
    if (mustPending) {
      const interval = setInterval(() => {
        loadData(count);
        count++;
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [mustPending]);

  if (newTxhash !== undefined) {
    return <Address address={tx.txHash} link={getExplorerLink(tx.chainCode, tx.txHash, 'transaction')} />;
  }

  if (mustPending) {
    return (
      <div className="mb-1">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div>
      {tx.txHash && <Address address={tx.txHash} link={getExplorerLink(tx.chainCode, tx.txHash, 'transaction')} />}
    </div>
  );
};

export default QueueTransactionHash;
