export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, _url?: string, absolute?: boolean) {
  let url = _url;
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (absolute) {
    url = `${_url}/`;
  }
  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}
