import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPendingSelector } from '../../store/transaction/selectors';
import { Table } from '../../components/table';
import { getChain, getExplorerLink } from '../../helpers';
import { Address } from '../../components/token/Address';
import {
  IAddress,
  DistributorDataIndexer,
  DistributorStatus,
  IFilteredDistributor,
  getDistributorStatusClass,
} from '../../types/MerchantDistributor';
import MerchantDistributorService from '../../services/MerchantDistributorService';
import DistributorAddNewAction from './DistributorAddNewAction';
import LabelStatus from '../../components/common/LabelStatus';
import DistributorUpdateAction from './DistributorUpdateAction';
import { TListModel } from '../../types';

import { Trans } from '@lingui/macro';
import { StringTypes } from '../../components/TransString';

/* eslint-disable  @typescript-eslint/ban-types */
export const Distributors: FC<PropsWithChildren<{}>> = ({ children }) => {
  const loading = useSelector(getPendingSelector);
  const [reloaded, setReloaded] = useState(false);

  const reload = () => setReloaded(!reloaded);

  const [data, setData] = useState<TListModel<IFilteredDistributor>>();

  const [filter, setFilter] = useState({
    page: 1,
  });

  const handleChange = (params: { page: number }) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };

  useEffect(() => {
    loadData();
  }, [reloaded]);

  useEffect(() => {
    if (data) {
      loadData();
    }
  }, [filter]);

  const loadData = async () => {
    const data = await MerchantDistributorService.getFilteredDistributors(filter);
    // console.log('data', data);
    data.items = data.items.map((item) => {
      const status = item.enabled ? DistributorStatus.ACTIVE.toString() : DistributorStatus.DEACTIVE.toString();
      return {
        ...item,
        status,
      };
    });
    setData(data);
  };

  const columns = [
    {
      // Tên ghi nhớ // Name
      title: <Trans>Name</Trans>,
      dataIndex: DistributorDataIndexer.name,
      key: DistributorDataIndexer.name,
    },
    {
      title: <Trans>Wallet address</Trans>,
      dataIndex: DistributorDataIndexer.addresses,
      key: DistributorDataIndexer.addresses,
      render: (addresses: IAddress[]) =>
        addresses && (
          <div className="">
            {addresses.map((address, k) => {
              const chain = getChain(address.chain.code);
              return (
                <div key={k} className="mb-2">
                  <div className="d-flex align-items-center gap-2">
                    <img className="w-20px h-20px" src={chain?.logoUrl} />
                    <Address
                      address={address.address}
                      link={getExplorerLink(address.chain.id, address.address, 'address')}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ),
    },
    {
      title: <Trans>Status</Trans>,
      dataIndex: DistributorDataIndexer.status,
      key: DistributorDataIndexer.status,
      className: 'text-center',
      render: (status: string) => {
        return (
          <>
            <LabelStatus
              status={status}
              getStatusClass={getDistributorStatusClass}
              type={StringTypes.DISTRIBUTOR_STATUS}
            />
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: DistributorDataIndexer.id,
      key: DistributorDataIndexer.id,
      className: 'text-end w-100px',
      render: (id: string) => {
        const distributor = data?.items.find((ds) => ds.id === id);
        return <>{distributor && <DistributorUpdateAction reload={reload} distributor={distributor} />}</>;
      },
    },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">
            {/* Địa chỉ nhận NFT */}
            <Trans>NFT Recipient address</Trans>
          </h3>
        </div>
        <div className="card-toolbar">
          <DistributorAddNewAction reload={reload} />
        </div>
      </div>

      <div className="card-body">
        {children}
        <Table {...{ handleChange, columns, loading, listModel: data }} />
      </div>
    </div>
  );
};
