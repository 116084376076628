export const OTHER_DENOMINATION = 'ORTHER';

export interface INftIssueHistory {
  id: string;
  nftAddress: string;
  denomination: number;
  quantity: number;
  amount: number;
  distributor: string;
  distributorAddress: string;
  status: string;
  estimatedTxFee: number;
  actualTxFee: number;
  feeAmount: number;
  totalAmount?: number;
  txHash: string;
  chainCode: string;
  chainName: string;

  txHashOnChain: {
    txHash: string;
    chainCode: string;
    chainName: string;
  };

  nftAddressOnChain: {
    nftAddress: string;
    chainCode: string;
  };

  createdAt: string;
  updatedAt: string;
}

export interface MultiCurrency {
  code: string;
  name: string;
  balance: number;
}
export interface Totalfiatbalance {
  currency: string;
  fiatBalanceFluctuations: number;
  fiatBalanceFluctuationsPercent: number;
  fiatTotalBalance: number;
}

export enum NftIssueHistoryDataIndexes {
  id = 'id',
  nftAddress = 'nftAddress',
  denomination = 'denomination',
  quantity = 'quantity',
  amount = 'amount',
  distributor = 'distributor',
  status = 'status',
  estimatedTxFee = 'estimatedTxFee',
  actualTxFee = 'actualTxFee',
  feeAmount = 'feeAmount',
  txHash = 'txHash',
  chainName = 'chainName',
  totalAmount = 'totalAmount',
  actualTxAmountAfterCostInBaseToken = 'actualTxAmountAfterCostInBaseToken',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  distributorAddressOnChain = 'distributorAddressOnChain',
  nftAddressOnChain = 'nftAddressOnChain',
  txHashOnChain = 'txHashOnChain',
}

export interface IIssueNftResult {
  status: number;
  type: string;
  code: string;
  message: string;
  parameter: {
    field_name: string;
    value: string | number;
  };
}

export interface IEstimatePrice {
  amount: number;
  discountAmount: number;
  feeAmount: number;
  gasFee: number;
  serviceFee: number;
  token: string;
}

export interface IConsume {
  chainCode: string;
  nftAddress: string;
  tokenId: number;
  permit: {
    deadline: number;
    v: number;
    r: string;
    s: string;
  };
}

export interface IDenomination {
  id?: string;
  name?: string;
  value: number;
  imageUrl?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IChain {
  code: string;
  name: string;
  id: number;
  coinName: string;
  endpoint: string;
  explorer: string;
  coinSymbol: string;
  apiEndpoint: string;
  coinDecimals: number;
  eip1559Supported: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum NFTStatus {
  ALL = 'ALL',
  CONSUMED = 'CONSUMED',
  TRANSFERRED = 'TRANSFERRED',
  ISSUED = 'ISSUED',
}

export function getNFTStatusClass(status: string): string {
  switch (status) {
    case 'CONSUMED':
      return 'badge badge-warning text-uppercase';
    case 'ISSUED':
      return 'badge badge-success text-uppercase';
    case 'TRANSFERRED':
      return 'badge badge-primary text-uppercase';
    default:
      return 'badge badge-light text-uppercase'; // Default class or handle unknown status
  }
}

export interface INft {
  id: string;
  tokenId: number;
  denomination: number;
  distributorAddress: string;
  createdAt: string;
  issuedMerchant: string;
  consumedMerchant: string;
  updatedAt: string;
  imageUrl: string;
  chainCode: string;
  status: string;
  nftAddress: string;
  distributorAddressOnChain: {
    distributorAddress: string;
    chainCode: string;
  };
  nftAddressOnChain: {
    nftAddress: string;
    chainCode: string;
    tokenId: number;
  };
}

export enum INftIndexes {
  id = 'id',
  tokenId = 'tokenId',
  denomination = 'denomination',
  distributorAddress = 'distributorAddress',
  createdAt = 'createdAt',
  issuedMerchant = 'issuedMerchant',
  consumedMerchant = 'consumedMerchant',
  updatedAt = 'updatedAt',
  imageUrl = 'imageUrl',
  chainCode = 'chainCode',
  status = 'status',
  nftAddress = 'nftAddress',
  distributorAddressOnChain = 'distributorAddressOnChain',
  nftAddressOnChain = 'nftAddressOnChain',
}

export interface IFilterNFT {
  status?: string;
  tokenId?: string;
  distributorAddress?: string;
  chainCode?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
}

export interface INFTDashboard {
  consumedNft: number;
  consumedNftAmount: number;
  inCirculationNft: number;
  inCirculationNftAmount: number;
  issuedNft: number;
  issuedNftAmount: number;
  transferredNft: number;
  transferredNftAmount: number;
}
