import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import MerchantNFTService from '../../services/MerchantNFTService';
import { Alert, currencyFormat, formatCurrency, preventInvalidKeys } from '../../helpers';
import { useFormik } from 'formik';
import { IDenomination, OTHER_DENOMINATION } from '../../types/MerchantNFT';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardSelector } from '../../store/dashboard/selectors';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { Chains } from '../../types';
import _ from 'lodash';
import { DASHBOARD } from '../../store/dashboard/actions';
import { getSettings } from '../../services/SettingService';
import { Loading } from '../../components/common/Loading';
import { Link } from 'react-router-dom';
import { useAbortRequest } from '../../hooks/useAbortRequest';
import { Trans, t } from '@lingui/macro';
import MerchantService from '../../services/MerchantService';
import FormikChainSelect from '../../components/nft/FormikChainSelect';
import FormikNFTDenominationsSelect from '../../components/nft/FormikNFTDenominationsSelect';
import NftPendingModal from '../../components/nft/NftPendingModal';
import FormikDistributorsSelect from '../../components/nft/FormikDistributorsSelect';
import FormUpload from '../../components/nft/FormUpload';
import { YupI18n } from '../../services/yup';
import { validInMin } from '../../utils/component';
import { getSettingsSelector } from '../../store/settings/selectors';
import { useAuth } from '../../store/auth';
import { SETTINGS } from '../../store/settings/actions';
import AuthService from '../../services/AuthService';

const PageNFTIssue: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [denomination, setDenomination] = useState<IDenomination>();
  const [uploadingFile, setUploadingFile] = useState<File | undefined>();
  const [newDenominationImage, setNewDenominationImage] = useState<string | undefined>();
  const [nftPendingShow, setNftPendingShow] = useState(false);
  const [balance, setVicBalance] = useState<any>();
  const { auth } = useAuth();
  const settings = useSelector(getSettingsSelector);
  const dispatch = useDispatch();
  YupI18n();

  const otherActive = denomination?.id === OTHER_DENOMINATION;

  const { summary } = useSelector(getDashboardSelector);

  const [showDistributorAddNewModal, setShowDistributorAddNewModal] = useState(false);

  const initialValues = {
    chainCode: Chains.BINANCE.toString(),
    denominationId: '',
    quantity: 1,
    distributorAddress: '',
    value: 0,
  };
  //You haven't entered the quantity. Bạn chưa nhập số lượng
  // Enter a quantity, minimum 1 and maximum 100  //Nhập số lượng thấp nhất 1 và cao nhất là 100
  //  Nhập số lượng thấp nhất 1 và cao nhất là 100
  // Bạn chưa chọn địa chỉ nhận NFT
  const schema = Yup.object().shape({
    quantity: Yup.number().required().min(1).max(100),
    distributorAddress: Yup.string().required(),
  });

  const onSubmit = async (
    values: {
      value: number;
      chainCode: string;
      denominationId: string;
      quantity: number;
      distributorAddress: string;
    },
    { setSubmitting }: any
  ) => {
    try {
      let userMerchant;
      if (auth) {
        userMerchant = await AuthService.getMe(auth.token);

        dispatch(SETTINGS.REQUEST());
      }

      if (userMerchant?.suspended) {
        //Tài khoản của bạn đang tạm khóa. Xin vui lòng liên hệ với bộ phận kinh doanh để biết thêm chi tiết hoặc hỗ trợ mở khóa
        Alert.error(
          t`Your account is temporarily locked. Please contact the sales department for more details or support for unlocking`,
          true
        );
        return;
      }

      if (!denomination) {
        return;
      }
      if (!prices) {
        Alert.error(t`The balance is not enough to release NFT`, true);
        return;
      }
      setLoading(true);

      const confirmed = await Alert.confirm(t`Do you want to release this NFT?`, true);

      if (confirmed) {
        let denominationId = values.denominationId;
        if (denomination.id === OTHER_DENOMINATION || denomination.imageUrl !== newDenominationImage) {
          const value = denomination.id === OTHER_DENOMINATION ? values.value : denomination.value || 0;
          const newDeno = await MerchantNFTService.addDenomination({
            value,
            file: uploadingFile,
          });
          denominationId = newDeno.id;
        }

        const { chainCode, quantity, distributorAddress } = values;
        const res = await MerchantNFTService.issue({
          chainCode,
          denominationId,
          quantity,
          distributorAddress,
          estimatedFeeAmount: prices.feeAmount,
        });

        if (res) {
          dispatch(DASHBOARD.REQUEST({}));
          setNftPendingShow(true);
        }
      }
    } catch (error) {
      Alert.error(t`Transaction failed`, true);
    }
    setLoading(false);
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit,
  });

  const value = denomination?.id === OTHER_DENOMINATION ? formik?.values.value : denomination?.value || 0;

  const { isLoading: loadingPrices, data: prices } = useAbortRequest(
    (signal) =>
      MerchantNFTService.estimatePrice({
        ...formik.values,
        denominationValue: denomination?.id === OTHER_DENOMINATION ? formik.values.value : denomination?.value || 0,
        signal,
      }),
    Number(value) > 0 && formik.values.quantity > 0 && !!formik.values.distributorAddress,
    [value, formik.values]
  );

  useEffect(() => {
    if (denomination?.imageUrl) {
      setNewDenominationImage(denomination?.imageUrl);
    } else {
      setNewDenominationImage(undefined);
    }
  }, [denomination]);

  const [settingsMin, setSettings] = useState({
    minCustomizedNFTValue: 0,
    maxCustomizedNFTValue: 0,
  });

  const getMinMaxNFTValueInfo = async () => {
    Promise.all([getSettings(formik.values.chainCode), MerchantService.getMerchantBalance()]).then(
      ([data, _balance]) => {
        const vicBalance = _balance.available.find(
          (blc: { currency: CurrencySymbols }) => blc.currency === CurrencySymbols.VIC
        );

        setVicBalance(vicBalance);

        setSettings(data.minMaxNFTValueInfo);
      }
    );
  };

  useEffect(() => {
    getMinMaxNFTValueInfo();
  }, [formik.values.chainCode, nftPendingShow]);

  const errorArgs = {
    minValue: settingsMin.minCustomizedNFTValue,
    maxValue: settingsMin.maxCustomizedNFTValue,
    rangeValueError: t`Please enter a value within the range from ${formatCurrency(
      settingsMin.minCustomizedNFTValue,
      CurrencySymbols.VIC
    )} to ${formatCurrency(settingsMin.maxCustomizedNFTValue, CurrencySymbols.VIC)}`,
    // rangeValueMaxError: t`Input amount less than or equal to ${formatCurrency(
    //   settings.maxCustomizedNFTValue,
    //   CurrencySymbols.VIC
    // )}`,
    rangeMaxValueError: t`Total amount exceeds available balance.`,

    imgUrlRequiredError: t`Please select photos`,
  };

  const invalidValueForOtherDenomination = formik.values.value < errorArgs.minValue;
  const invalidMaxValueForOtherDenomination = formik.values.value > errorArgs.maxValue;

  const invalidValueMaxValue = prices && balance && !validInMin(balance.value, prices.totalAmount);

  const invalidImgForOtherDenomination = !uploadingFile;

  const submitionValidator =
    loading ||
    !prices ||
    prices.totalAmount > balance.value ||
    !formik.values.distributorAddress ||
    formik.isSubmitting ||
    !formik.isValid ||
    (otherActive && (invalidValueForOtherDenomination || invalidImgForOtherDenomination));

  const onChangeValue = (e: any) => {
    formik.setFieldValue('value', e.target.value);
  };

  const debounceFn = _.debounce(onChangeValue, 1000);

  const onHide = () => {
    formik.values.denominationId = '';
    formik.values.value = 0;
    formik.values.distributorAddress = '';
    formik.setFieldValue('distributorAddress', '');
    setDenomination(undefined);
    setShowDistributorAddNewModal(false);
    formik.resetForm();

    setNftPendingShow(!nftPendingShow);
  };

  return (
    <div className="card mb-5" id="kt_profile_details_view">
      <div className="card-header">
        <h3 className="card-title">
          <Trans>Issue NFT</Trans>
        </h3>
        <div className="card-toolbar"></div>
      </div>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="modal-body py-3 row">
            <div className="col border-end pe-7 ">
              <FormikChainSelect formik={formik} />
              <FormikNFTDenominationsSelect formik={formik} setDenomination={setDenomination} settings={settingsMin} />
              {otherActive && (
                <div className="mb-6">
                  <label htmlFor="" className="form-label required">
                    {/* Nhập giá trị tùy chọn 
                    // tieng anh: Enter custom value
                    */}
                    <Trans>Custom NFT value</Trans>
                  </label>
                  <input
                    className="form-control"
                    required
                    name="customvalue"
                    type="number"
                    disabled={!otherActive}
                    min={0}
                    maxLength={10}
                    onKeyDown={preventInvalidKeys}
                    onChange={debounceFn}
                  />
                  {otherActive && invalidValueForOtherDenomination && formik.values.value > 0 && (
                    <div className="fv-plugins-message-container invalid-feedback_error">
                      <div className="fv-help-block">{errorArgs.rangeValueError} </div>
                    </div>
                  )}
                  {otherActive && invalidMaxValueForOtherDenomination && formik.values.value > 0 && (
                    <div className="fv-plugins-message-container invalid-feedback_error">
                      <div className="fv-help-block">{errorArgs.rangeValueError}</div>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col">
                  <div className="mb-6">
                    <label htmlFor="" className="form-label required">
                      {/* Số lượng NFT */}
                      <Trans>NFT quantity</Trans>
                    </label>
                    <input
                      className="form-control"
                      required
                      type="number"
                      min={1}
                      onKeyDown={preventInvalidKeys}
                      {...formik.getFieldProps('quantity')}
                    />
                    {formik.touched.quantity && formik.errors.quantity && (
                      <div className="fv-plugins-message-container invalid-feedback_error">
                        <div className="fv-help-block">{formik.errors.quantity}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <FormikDistributorsSelect
                {...{
                  formik,
                  showDistributorAddNewModal,
                  setShowDistributorAddNewModal,
                }}
              />
              <div className="mb-6">
                <label htmlFor="" className="form-label required">
                  <Trans>NFT image</Trans>
                </label>
                <FormUpload
                  setNewDenominationImage={setNewDenominationImage}
                  newDenominationImage={newDenominationImage}
                  setUploadingFile={setUploadingFile}
                  denomination={denomination}
                />
                {otherActive && invalidImgForOtherDenomination && (
                  <div className="fv-plugins-message-container invalid-feedback_error">
                    <div className="fv-help-block">{errorArgs.imgUrlRequiredError}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="col ps-7 ">
              <div className="card-header px-0">
                <h3 className="card-title">
                  {/* Chi tiết giao dịch */}
                  <Trans>Transaction details</Trans>
                </h3>
              </div>

              <div className="row mb-3">
                <div className="col fw-bold">
                  {/* Số lượng NFT */}
                  <Trans>NFT quantity</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">{formik.values.quantity}</div>
              </div>

              <div className="row mb-3">
                <div className="col fw-bold">
                  {/* Giá trị mỗi NFT
                // tieng anh : 1 NFT value
                */}
                  <Trans>1 NFT value</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">
                  {formik.values.value ? (
                    formatCurrency(formik.values.value, CurrencySymbols.VIC)
                  ) : denomination ? (
                    formatCurrency(denomination.value, CurrencySymbols.VIC)
                  ) : (
                    <>-- {CurrencySymbols.VIC}</>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col fw-bold">
                  {/* Tổng giá trị NFT sẽ phát hành
                // tieng anh : Total NFT value to be issued
                */}
                  <Trans>Total NFT value to be issued</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">
                  {loadingPrices ? (
                    <Loading />
                  ) : prices ? (
                    formatCurrency(prices.amount, CurrencySymbols.VIC)
                  ) : (
                    <>-- {CurrencySymbols.VIC}</>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col fw-bold ">
                  <Trans>Service fee</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">
                  {loadingPrices ? (
                    <Loading />
                  ) : prices ? (
                    currencyFormat(prices.serviceFee, CurrencySymbols.VIC)
                  ) : (
                    <>-- {CurrencySymbols.VIC}</>
                  )}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col fw-bold ">
                  <Trans>Gas fee</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">
                  {loadingPrices ? (
                    <Loading />
                  ) : prices ? (
                    currencyFormat(prices.gasFee, CurrencySymbols.VIC)
                  ) : (
                    <>-- {CurrencySymbols.VIC}</>
                  )}
                </div>
              </div>

              <div className="row py-5 border-bottom border-top my-5">
                <div className="col fw-bold ">
                  <Trans>Total</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">
                  {loadingPrices ? (
                    <Loading />
                  ) : prices ? (
                    formatCurrency(prices.totalAmount, CurrencySymbols.VIC)
                  ) : (
                    <>-- {CurrencySymbols.VIC}</>
                  )}
                </div>
              </div>

              <div className="row mb-10">
                <div className="col fw-bold ">
                  <Trans>Available balance</Trans>
                </div>
                <div className="col text-end d-flex justify-content-end">
                  {loadingPrices ? (
                    <Loading />
                  ) : summary ? (
                    balance && formatCurrency(balance.value, balance.currency)
                  ) : (
                    <>-- VIC</>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-end mb-3">
                {invalidValueMaxValue && <div className="text-danger">{errorArgs.rangeMaxValueError} </div>}
              </div>
              <div className="d-flex justify-content-end gap-3">
                <Link to={'/nft-history'} className="btn btn-outline">
                  {/* Lịch sử phát hành 
                // english: Issue history
                */}
                  <Trans>Issue history</Trans>
                </Link>{' '}
                <button
                  className="btn btn-primary"
                  disabled={submitionValidator || !newDenominationImage || settings.merchant.suspended}
                >
                  {!loading && <Trans>Issue NFT</Trans>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      <Trans>Please wait</Trans>{' '}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
        {nftPendingShow && <NftPendingModal onHide={onHide} show={nftPendingShow} />}
      </div>
    </div>
  );
};

export default PageNFTIssue;
