import { Fragment } from 'react';
import { useAuth } from '../../../../store/auth';
import { SidebarMenuItem } from '../SidebarMenuItem';
import { SidebarMenuItemWithSub } from '../SidebarMenuItemWithSub';
import { MenuItem } from './menuData';
import CheckMerchantType from '../../../../components/atom/CheckMerchantType';
import { MerchantType } from '../../../../types/Merchant';

interface Props {
  menuData: MenuItem[];
  seledtedId?: string;
  onClick: (id: string) => void;
}

export const Menu: React.FC<Props> = ({ menuData, seledtedId, onClick }) => {
  const RenderMenuChild: React.FC<{ menu: MenuItem }> = ({ menu }) => {
    return (
      <Fragment key={menu.id}>
        <SidebarMenuItem
          to={menu.to}
          title={menu.translatedName}
          isActive={seledtedId === menu.id}
          fontIcon={menu.fontIcon}
          iconImg={menu.iconImg}
          onClick={() => onClick(menu.id)}
        />
      </Fragment>
    );
  };

  const RenderMenuParent: React.FC<{ menu: MenuItem }> = ({ menu }) => {
    const { currentUser } = useAuth();
    const checkTypeExist = menu?.type === currentUser?.type;

    return (
      <Fragment key={menu.id}>
        {checkTypeExist && (
          <CheckMerchantType types={MerchantType.VIC_BASED}>
            <SidebarMenuItemWithSub
              key={menu.id}
              title={menu.translatedName}
              isActive={menu?.subMenus?.map((i) => i?.id).includes(String(seledtedId))}
              fontIcon={menu.fontIcon}
              iconImg={menu.iconImg}
            >
              {menu.subMenus?.map((subMenu, k) => {
                return <RenderMenuChild key={k} menu={subMenu} />;
              })}
            </SidebarMenuItemWithSub>
          </CheckMerchantType>
        )}
      </Fragment>
    );
  };

  return (
    <>
      {menuData.map((menu, i) => {
        return (
          <Fragment key={i}>
            {!menu?.subMenus ? <RenderMenuChild menu={menu} /> : <RenderMenuParent menu={menu} />}
          </Fragment>
        );
      })}
    </>
  );
};
