import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ellipseAddress } from '../../helpers';
import { FaIcons } from '../../helpers/FaIconHelper';
import { Trans } from '@lingui/macro';

const Address = ({
  className = 'd-flex',
  linkClassName = 'user-select-auto',
  showCopy = true,
  address,
  link,
  onClick,
}: {
  linkClassName?: string;
  className?: string;
  address: string;
  showCopy?: boolean;
  link?: string;
  onClick?: any;
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }
  }, [copySuccess]);
  if (!address) {
    return <></>;
  }
  return (
    <span className={className}>
      <OverlayTrigger key="address" placement="top" overlay={<Tooltip id="tooltip-address">{address}</Tooltip>}>
        {link ? (
          <a href={link} target="_blank" rel="noreferrer" className={linkClassName}>
            <span>{ellipseAddress(address, 4)}</span>
          </a>
        ) : onClick ? (
          <a className={linkClassName} onClick={onClick}>
            <span>{ellipseAddress(address, 4)}</span>
          </a>
        ) : (
          <span>{ellipseAddress(address, 4)}</span>
        )}
      </OverlayTrigger>
      {showCopy === true && address !== '' && (
        <OverlayTrigger
          key="copy-to-clipboard"
          placement="top"
          overlay={
            <Tooltip id="tooltip-copy-to-clipboard">
              <Trans>Copy</Trans>
            </Tooltip>
          }
        >
          <CopyToClipboard
            text={address}
            onCopy={() => {
              setCopySuccess(true);
            }}
          >
            <i
              className={`cursor-pointer  px-2  ${
                copySuccess ? `${FaIcons['fas fa-check']} text-success` : FaIcons['far fa-copy']
              }`}
            ></i>
          </CopyToClipboard>
        </OverlayTrigger>
      )}
    </span>
  );
};
export { Address };
