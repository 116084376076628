/* eslint-disable  @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';

export const showToastMessage = (eMessage: string, isSuccess?: boolean) => {
  if (isSuccess) {
    return toast.success(`${eMessage}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
  return toast.error(`${eMessage}`, {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
};
export const errorProcessor = {
  isOffline(networkState: { online: boolean }) {
    if (!networkState.online) {
      return showToastMessage('Network error. Please check your Internet connection');
    }
    return undefined;
  },
  isFromChainSameAsDstChain(fromChain: { id: number }, toChain: { id: number }) {
    if (fromChain && toChain && fromChain?.id === toChain?.id) {
      return false;
    }
    return undefined;
  },
  isFromChainDiffFromWalletChain(fromChain: any, chainId: number) {
    if (fromChain && fromChain?.id !== chainId && chainId > 0) {
      return showToastMessage(`You must switch to metamask  ${fromChain?.name}
            to begin the transfer. `);
    }
    return undefined;
  },
  ApprovalFail(error: any, fromChain: { id: number; name: string }) {
    const chainName = fromChain?.name;
    if (error) {
      return showToastMessage(
        `Your Approval has failed on ${chainName}. Rest assured that your funds are safe. You may try again later. `
      );
    }
    return undefined;
  },

  pausedChain(error: any) {
    if (error) {
      return true;
    }
    return undefined;
  },
};
