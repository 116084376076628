import { FC, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MasterLayout } from '../layout/MasterLayout';
import { WithChildren } from '../helpers/react18MigrationHelpers';

import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../assets/ts/_utils';

import { Error404 } from '../components/errors/Error404';
import PageSettings from '../pages/settings/PageSettings';
import PageTransactionHistory from '../pages/transaction/PageTransactionHistory';
import PageDashboard from '../pages/dashboard/PageDashboard';
import PageNFTHistory from '../pages/nft/PageNFTHistory';
import PageDistributors from '../pages/distributor/PageDistributors';
import PageNfts from '../pages/nft/PageNfts';
import PageNFTIssue from '../pages/nft/PageNFTIssue';
import CheckMerchantType from '../components/atom/CheckMerchantType';
import { MerchantType } from '../types/Merchant';
import PageReconciliation from '../pages/reconciliation/PageReconciliation';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        <Route path="/dashboard" element={<PageDashboard />} />

        <Route path="/transaction-history" element={<PageTransactionHistory />} />
        <Route path="reconciliation" element={<PageReconciliation />} />
        <Route path="/settings" element={<PageSettings />} />

        <Route
          path="/issue-nft"
          element={
            <CheckMerchantType types={MerchantType.VIC_BASED} showError={true}>
              <PageNFTIssue />
            </CheckMerchantType>
          }
        />

        <Route
          path="/nft-history"
          element={
            <CheckMerchantType types={MerchantType.VIC_BASED} showError={true}>
              <PageNFTHistory />
            </CheckMerchantType>
          }
        />

        <Route
          path="/list"
          element={
            <CheckMerchantType types={MerchantType.VIC_BASED} showError={true}>
              <PageNfts />
            </CheckMerchantType>
          }
        />

        <Route path="/distributors" element={<PageDistributors />} />

        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes, SuspensedView };
