import { Trans } from '@lingui/macro';
import { currencyFormat } from '../../helpers';
import { IDepositFee } from '../../store/transaction/types';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { useAuth } from '../../store/auth';
import { MerchantType } from '../../types/Merchant';

interface Props {
  isLoading?: boolean;
  amount: number;
  symbol: string;
  isUnsupportedChain?: boolean;
  estimateFee?: IDepositFee;
}

const DepositInformation: React.FC<Props> = ({ isLoading, amount, symbol, isUnsupportedChain, estimateFee }) => {
  const { currentUser } = useAuth();
  return (
    <div className="rounded-4 border p-5 mb-5">
      <div className="mb-5 fw-bolder ">
        <Trans>Top-up wallet information</Trans>{' '}
      </div>
      <div className="row mb-5">
        <div className="col">
          <Trans>Transaction value</Trans>{' '}
        </div>
        <div className="col">
          {currentUser?.type === MerchantType.MULTI_CURRENCY && (
            <>
              <div className="text-end">
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                ) : amount > 0 && !isUnsupportedChain ? (
                  currencyFormat(amount, symbol)
                ) : (
                  '--'
                )}
              </div>
            </>
          )}
          {currentUser?.type === MerchantType.VIC_BASED && (
            <>
              <div className="text-end">
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                ) : !isUnsupportedChain && estimateFee ? (
                  <>
                    {symbol === CurrencySymbols.USDT ? '~' : ''}
                    {currencyFormat(estimateFee?.amountInBaseToken.amount, estimateFee?.amountInBaseToken.token)}
                  </>
                ) : (
                  '--'
                )}
              </div>

              {amount > 0 && !isUnsupportedChain && symbol === CurrencySymbols.USDT && (
                <div className={`text-end opacity-50 ${symbol === CurrencySymbols.USDT ? 'visible' : 'invisible'}`}>
                  ({currencyFormat(amount, symbol)})
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <Trans>Service fee</Trans>
        </div>
        <div className="col">
          <div className="text-end">
            {isLoading ? (
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            ) : estimateFee && !isUnsupportedChain ? (
              currencyFormat(estimateFee.transactionCost.serviceCost || 0, estimateFee.transactionCost.token)
            ) : (
              '--'
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Trans>Recorded in the account</Trans>
        </div>
        <div className="col">
          {isLoading ? (
            <div className="text-end">
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
          ) : (
            <div className="text-end">
              {estimateFee && !isUnsupportedChain
                ? currencyFormat(estimateFee.amountAfterCost.amount, estimateFee.amountAfterCost.token)
                : '--'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepositInformation;
