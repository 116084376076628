import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { createStaticRanges, DateRangePicker as DateRange } from 'react-date-range';
import { endOfDay, startOfDay } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { defaultStaticRanges, selectDateRange, SelectRange } from './defaultRanges';
import clsx from 'clsx';
import { t, Trans } from '@lingui/macro';
import { useLanguageProvider } from '../../providers/LanguageProvider';
import vi from 'date-fns/locale/vi';
import en from 'date-fns/locale/en-US';
import moment from 'moment';
interface IDateRangePicker {
  style?: CSSProperties;
  className?: string;
  onChangeDate: any;
  extraActions?: ReactNode;
  showOneMonth?: boolean;
  showOneYear?: boolean;
  showAll?: boolean;
}

// interface IDate {
//   startDate?: Date;
//   endDate?: Date;
//   key: string;
//   active: string;
// }

export const DateRangePicker = ({
  style,
  className,
  onChangeDate,
  extraActions,
  showOneMonth = true,
  showOneYear = true,
  showAll = true,
}: IDateRangePicker) => {
  const ref = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([selectDateRange('oneWeek')]);
  const { language } = useLanguageProvider();
  // const [range, setRange] = useState([
  //   {
  //     startDate: moment(dateRange?.startDate).toDate(),
  //     endDate: moment(dateRange?.endDate).toDate(),
  //     key: 'selection',
  //   },
  // ]);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && open) {
        handleClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [state]);

  const handleOnChange = (ranges: any) => {
    const { selection } = ranges;
    const startDate = startOfDay(selection.startDate);
    const endDate = endOfDay(selection.endDate);
    setState([{ ...selection, startDate, endDate }]);
  };
  // const handleOnChange = (dateRange: DateRange) => {
  //   const params = {
  //     ...dateRange,
  //     endDate: moment().endOf('day').toDate(),
  //     active: 'custom',
  //   };
  //   console.log('handleOnChange', params);
  //   // setState([params]);
  //   // onChangeDate?.(params);
  // };
  const apply = () => {
    // const params = {
    //   ...state[0],
    //   endDate: moment().endOf('day').toDate(),
    //   active: 'custom',
    // };
    // console.log('state', state);
    if (onChangeDate) {
      onChangeDate?.(state[0]);
    }
    handleClose();
  };
  const handleChangeDate = (key: SelectRange) => {
    handleClose();
    // check 2 case chart and normal

    setState([selectDateRange(key)]);
    onChangeDate?.(selectDateRange(key));
  };
  const staticCustomRanges = defaultStaticRanges ? createStaticRanges(defaultStaticRanges) : undefined;
  // console.log(language);
  return (
    <div className={clsx(className, 'date-range-picker rounded position-relative d-inline-flex h-100')} style={style}>
      <div className="d-flex align-items-center gap-1">
        <TabItem active={state[0].active === 'oneDate'} onClick={() => handleChangeDate('oneDate')} label="1D" />
        {showAll ? (
          <TabItem active={state[0].active === 'oneWeek'} onClick={() => handleChangeDate('oneWeek')} label="1W" />
        ) : (
          <>
            <TabItem active={state[0].active === 'oneWeek'} onClick={() => handleChangeDate('oneWeek')} label="7D" />
            <TabItem active={state[0].active === 'oneMonth'} onClick={() => handleChangeDate('oneMonth')} label="1M" />
            <TabItem
              active={state[0].active === 'threeMonth'}
              onClick={() => handleChangeDate('threeMonth')}
              label="3M"
            />
          </>
        )}

        {showOneMonth && (
          <TabItem active={state[0].active === 'oneMonth'} onClick={() => handleChangeDate('oneMonth')} label="1M" />
        )}
        {showOneYear && (
          <TabItem active={state[0].active === 'oneYear'} onClick={() => handleChangeDate('oneYear')} label="1Y" />
        )}
        {showAll && (
          <TabItem active={state[0].active === 'all'} onClick={() => handleChangeDate('all')} label={t`ALL`} />
        )}
        <TabItem
          active={state[0].active === 'custom'}
          onClick={() => setOpen(!open)}
          label={<i className="far fa-calendar-alt text-dark" />}
        />
        {extraActions && (
          <>
            <span style={{ color: '#D6DBE7' }}>|</span>
            {extraActions}
          </>
        )}
      </div>

      {open && (
        <div
          className="overflow-hidden shadow rounded-3 border border-gray-300 position-absolute bg-white top-100"
          // className="position-absolute end-0 top-100 border shadow rounded-3 overflow-hidden"
          style={{ zIndex: 1000, right: 0 }}
          // style={{ zIndex: 1020 }}
          ref={ref}
        >
          <DateRange
            locale={language === 'vi-VN' ? vi : en}
            onChange={handleOnChange}
            months={2}
            ranges={state}
            direction="vertical"
            staticRanges={staticCustomRanges}
            inputRanges={[]}
            maxDate={moment().endOf('day').toDate()}
          />
          <div className="d-flex gap-2 justify-content-end p-3 pt-0">
            <button className="btn btn-secondary btn-sm" onClick={handleClose}>
              <Trans>Cancel</Trans>
            </button>
            <button className="btn btn-primary btn-sm" onClick={apply}>
              <Trans>Apply</Trans>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const TabItem = ({
  label,
  className,
  onClick,
  active,
  activeClass = 'active',
}: {
  label: ReactNode;
  onClick: () => void;
  active?: boolean;
  className?: string;
  activeClass?: string;
}) => {
  return (
    <button
      className={clsx('range-item', className, {
        [activeClass]: active,
      })}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
