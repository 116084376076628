export interface IDistributor {
  id: string;
  name: string;
  merchant: Merchant;
  addresses: IAddress[];
  mapAddress: MapAddress;
}

export interface IFilteredDistributor {
  id: string;
  name: string;
  totalIssuedNft: string;
  totalIssuedNftValue: string;
  addresses: IAddress[];
  mapAddress: MapAddress;
  enabled: boolean;
  status: string;
}

interface MapAddress {
  POLYGON: IAddress[];
}

export interface IAddress {
  id: string;
  address: string;
  chain: IChain;
}

interface IChain {
  code: string;
  name: string;
  id: number;
  coinName: string;
  endpoint: string;
  explorer: string;
  coinSymbol: string;
  apiEndpoint: string;
  coinDecimals: number;
  eip1559Supported: boolean;
}

interface Merchant {
  id: string;
  name: string;
  callbackUrl: string;
  redirectUrl: string;
  createdAt: number;
  updatedAt: number;
  merchantBalance: MerchantBalance;
  email: string;
  depositAddress: string;
  withdrawAddress: string;
  suspended: boolean;
  version: number;
  partitionKey: number;
}

interface MerchantBalance {
  id: string;
  balance: number;
  availableBalance: number;
  totalFee: number;
  version: number;
}

export enum DistributorStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}

export function getDistributorStatusClass(status: string): string {
  switch (status) {
    case 'ACTIVE':
      return 'badge badge-light-success text-uppercase';
    case 'DEACTIVE':
      return 'badge badge-light-danger text-uppercase';
    default:
      return 'badge badge-light text-uppercase'; // Default class or handle unknown status
  }
}

export enum DistributorDataIndexer {
  id = 'id',
  name = 'name',
  chain = 'chain',
  addresses = 'addresses',
  totalIssuedNft = 'totalIssuedNft',
  totalIssuedNftValue = 'totalIssuedNftValue',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  enabled = 'enabled',
  status = 'status',
}
