import LoginByGoogleBtn from '../../components/auth/LoginByGoogleBtn';
import LanguageSwitcher from '../../components/common/LanguageSwitcher';
import { Trans } from '@lingui/macro';

function PageLogin() {
  return (
    <section className="">
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          <Trans>Login</Trans>
        </h1>
        <div className="text-gray-400 fw-bold fs-4"></div>
      </div>

      <div className="text-center">
        <LoginByGoogleBtn />
        {/* <LoginByWalletBtn /> */}
      </div>
      <div>
        <LanguageSwitcher />
      </div>
    </section>
  );
}

export default PageLogin;
