import { Trans } from '@lingui/macro';
import TabItem from '../TabItem';

export enum Tabs {
  DETAILS = 'DETAILS',
  ADDITION = 'ADDITION',
  LOG = 'LOG',
}

// "DETAILS": "Chi tiết",
// "LOG": "Thông tin log",
// "ADDITION": "Thông tin bổ sung",

export interface IDetailTab {
  code: Tabs;
  label: any;
}

export const FullDetailTabs = ({ currentTab, setCurrentTab }: { currentTab: string; setCurrentTab: any }) => {
  const tabs: IDetailTab[] = [
    { code: Tabs.DETAILS, label: <Trans>Details</Trans> },
    { code: Tabs.ADDITION, label: <Trans>Addition</Trans> },
    { code: Tabs.LOG, label: <Trans>Log</Trans> },
  ];

  return (
    <div
      className="row align-items-center w-100 gap-1 border p-1 rounded mx-0 mb-3"
      style={{ background: '#F4F5F7' }}
      // style={{ background: '#FAFBFC', opacity: disable ? 0.5 : 1 }}
    >
      {tabs.map((item, k) => (
        <TabItem
          key={k}
          active={item.code === currentTab}
          onClick={() => {
            setCurrentTab(item.code);
          }}
          label={item.label}
          className="col text-center py-2"
          activeClass="bg-white shadown"
        />
      ))}
    </div>
  );
};

export const DetailTabs = ({ currentTab, setCurrentTab }: { currentTab: string; setCurrentTab: any }) => {
  const tabs: IDetailTab[] = [
    { code: Tabs.DETAILS, label: <Trans>Details</Trans> },
    { code: Tabs.LOG, label: <Trans>Log</Trans> },
  ];

  return (
    <div
      className="row align-items-center w-100 gap-1 border p-1 rounded mx-0 mb-3"
      style={{ background: '#F4F5F7' }}
      // style={{ background: '#FAFBFC', opacity: disable ? 0.5 : 1 }}
    >
      {tabs.map((item, k) => (
        <TabItem
          key={k}
          active={item.code === currentTab}
          onClick={() => {
            setCurrentTab(item.code);
          }}
          label={item.label}
          className="col text-center py-2"
          activeClass="bg-white shadown"
        />
      ))}
    </div>
  );
};
