import AxiosInstance from './axios';

import { IDistributor, IFilteredDistributor } from '../types/MerchantDistributor';
import { TListModel } from '../types';
import { buildQueryString } from '../helpers/UtilsHelpers';

//
const updateDistributor = async ({
  id,
  name,
  addresses,
  enabled,
}: {
  id: string;
  name: string;
  addresses: {
    chainCode: string;
    address: string;
  }[];
  enabled: boolean;
}): Promise<IDistributor> => {
  try {
    const url = `/merchant/distributors/${id}`;
    return await AxiosInstance.put(url, {
      name,
      addresses,
      enabled,
    }).then((res) => res.data);
  } catch (err) {
    console.log('Func: updateDistributor - PARAMS: err', err);
    throw err;
  }
};

//
const addDistributor = async ({
  name,
  addresses,
}: {
  name: string;
  addresses: { address: string; chainCode: string }[];
}): Promise<IDistributor> => {
  try {
    const url = '/merchant/distributors';
    return await AxiosInstance.post(url, {
      name,
      addresses,
    }).then((res) => res.data);
  } catch (err) {
    console.log('Func: addDistributor - PARAMS: err', err);
    throw err;
  }
};

//danh sach gia tri nft
const getDistributorsByChainCode = async ({ chainCode }: { chainCode: string }): Promise<IDistributor[]> => {
  try {
    const querystring = new URLSearchParams({ chainCode });
    const url = `/merchant/distributors?${querystring}`;
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('Func: getDistributorsByChainCode - PARAMS: err', err);
    throw err;
  }
};

const getDistributors = async (): Promise<IDistributor[]> => {
  try {
    const url = '/merchant/distributors';
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('Func: getDistributors - PARAMS: err', err);
    throw err;
  }
};

const getFilteredDistributors = async ({
  chainCode,
  enabled,
  page,
}: {
  chainCode?: string;
  enabled?: boolean;
  page?: number;
}): Promise<TListModel<IFilteredDistributor>> => {
  try {
    const urlSearchParams = buildQueryString({
      chainCode,
      enabled,
      page,
      size: 10,
    });

    const url = `/merchant/distributors/filter?${urlSearchParams}`;
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('Func: getConfig - PARAMS: err', err);

    throw err;
  }
};

export default {
  getDistributorsByChainCode,
  updateDistributor,
  getDistributors,
  addDistributor,
  getFilteredDistributors,
};
