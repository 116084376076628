import { formatCurrency } from '../helpers';
import WithTooltip from './WithTooltip';
import { Trans } from '@lingui/macro';
import CardDashboard from './CardDashboard';
import { FiatTransactions, TokenTransactions } from '../store/transaction/types';
import { getTransactionImage, getTransactionName } from '../helpers/UtilsHelpers';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import MerchantService from '../services/MerchantService';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const SummaryCards = ({ filter }: { filter: any }) => {
  const { data: summary, refetch } = useQuery({
    queryKey: ['summary'],
    queryFn: async () => {
      const response = await MerchantService.getDashboard(filter);

      return response;
    },
  });
  useEffect(() => {
    refetch();
  }, [filter]);
  return (
    <div className="row">
      {summary &&
        summary.fiatTransactions.map((item: FiatTransactions, index: number) => {
          return (
            <div className="col-3" key={index}>
              <CardDashboard
                title={
                  <>
                    <img
                      src={`/img/${getTransactionImage(item.transactionStatus)}.svg`}
                      className="sizeImg"
                      alt="sizeImg"
                    />
                    <div className="d-flex align-content-start">{getTransactionName(item.transactionStatus)}</div>
                    <div className="d-flex align-content-end ">
                      {/* {item.tokenTransactions.length > 0 ? ( */}
                      <WithTooltip
                        id="tooltip-1"
                        tooltip={
                          <>
                            {item.tokenTransactions.length > 0 ? (
                              item.tokenTransactions.map((tkItem: TokenTransactions, tkIndex: number) => {
                                return (
                                  <div className="d-flex align-items-end flex-wrap p-1 " key={tkIndex}>
                                    <div className="d-flex align-items-start me-3  me-xl-2">
                                      {tkItem.totalTransactions} <Trans>transaction</Trans>
                                    </div>
                                    <div className=" ">
                                      <span className="me-2">
                                        {formatCurrency(tkItem.totalTransactionAmount, tkItem.token, true)}
                                      </span>{' '}
                                      <img src={`../img/${tkItem.token}.svg`} width="16px" />
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <>
                                0 <Trans>transaction</Trans>
                              </>
                            )}
                          </>
                        }
                        placement="top"
                      >
                        <AiOutlineExclamationCircle />
                        {/* <img src={`/img/${getTransactionImage(item.transactionStatus)}.svg`} /> */}
                      </WithTooltip>
                      {/* ) : (
                        <AiOutlineExclamationCircle />
                      
                      )} */}
                    </div>
                  </>
                }
                number={formatCurrency(item?.totalFiatTransactionCount, '', true)}
                total={formatCurrency(item?.totalFiatTransactionAmount, summary.currency)}
              />
            </div>
          );
        })}
    </div>
  );
};
