import { Trans } from '@lingui/macro';
import { CurrentTokenItem, TokenItem } from '../token/TokenItem';
import { ITokenChain } from '../../types';

const FormikTokenSelect = ({
  chains,
  selectedChain,
  formik,
  setSelectedChain,
}: {
  chains: any;
  selectedChain: any;
  formik: any;
  setSelectedChain: any;
}) => {
  return (
    <div className="dropdown">
      <div className="fw-semibold mb-4">
        <Trans>Withdrawal amount</Trans>:
      </div>
      <button className="border-0 bg-transparent p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {selectedChain && <CurrentTokenItem chain={selectedChain} className="p-0" />}
      </button>
      <ul className="dropdown-menu">
        {chains.map((chain: ITokenChain, i: number) => {
          return (
            <li key={`currency-item-${i}`}>
              <TokenItem
                chain={chain}
                selectedChain={selectedChain}
                onSelectChain={(e) => {
                  formik.resetForm();
                  formik.setFieldValue('chain', e);
                  formik.setFieldValue('amount', 0);
                  setSelectedChain(e);
                }}
                className="cursor-pointer"
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FormikTokenSelect;
