import clsx from 'clsx';
import TransString, { StringTypes } from '../TransString';

const LabelStatus = ({
  status,
  getStatusClass,
  className = '',
  text,
  type = StringTypes.STATUS,
}: {
  status: string;
  getStatusClass?: (status: string) => string;
  className?: string;
  text?: string;
  type?: StringTypes;
}) => {
  const statusClass = getStatusClass ? getStatusClass(status) : 'badge badge-light-info text-muted text-uppercase';

  return (
    <span className={clsx(statusClass, className)}>
      {status && <TransString value={status} type={type} />} {text}
    </span>
  );
};

export default LabelStatus;
