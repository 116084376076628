/* eslint-disable  @typescript-eslint/no-explicit-any */
import AxiosInstance from './axios';

/**
 * Log user in
 *
 * @param email Email
 * @param password Password
 *
 * @returns object
 */
const login = async (email: string, password: string): Promise<{ status: boolean; msg: string; data?: any }> => {
  let status = false;
  let msg = '';
  return AxiosInstance.post('/auth/sign-in', {
    email,
    password,
  })
    .then((res) => {
      msg = 'LoginSuccess';
      status = true;

      return { status, msg, data: res.data };
    })
    .catch((error) => {
      msg = 'WrongEmailOrPassword';
      return {
        status,
        msg: error?.message ? error.message : 'Error',
        data: error,
      };
    });
};

/**
 * Log google in
 *
 * @param token Access token google
 *
 * @returns object
 */
const googleSignin = async (token?: string): Promise<{ status: boolean; msg: string; data?: any }> => {
  let status = false;
  let msg = '';
  return AxiosInstance.post('/auth/google-sign-in', {
    token,
  })
    .then((res) => {
      msg = 'LoginSuccess';
      status = true;

      return { status, msg, data: res.data };
    })
    .catch((error) => {
      msg = 'WrongEmailOrPassword';
      return {
        status,
        msg: error?.message ? error.message : 'Error',
        data: error,
      };
    });
};

const getMe = async (token: string) => {
  const res = await AxiosInstance.get('/auth/merchant-user', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const userData = res.data;
  localStorage.setItem('user', JSON.stringify(userData));

  return userData;
};

const getWalletNone = (address: string) => {
  localStorage.setItem('address', address);
  return AxiosInstance.get('/auth/wallet/' + address + '/nonce');
};

const signWallet = (publicAddress: string, signature: string, nonce: string) =>
  AxiosInstance.post(`/auth/wallet/${publicAddress}/signature`, {
    nonce,
    signature,
  });

const AuthService = { login, getMe, getWalletNone, signWallet, googleSignin };
export default AuthService;
