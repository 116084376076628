export const SUPPORTED_LOCALES = [
  // order as they appear in the language dropdown
  'vi-VN',
  'en-US',
];
export type SupportedLocale = typeof SUPPORTED_LOCALES[number] | 'pseudo';

export const DEFAULT_LOCALE: SupportedLocale = 'vi-VN';

export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  'vi-VN': 'Tiếng Việt',
  'en-US': 'English',
};
export const LOCALE_LABEL_SHORT: { [locale in SupportedLocale]: string } = {
  'vi-VN': 'VN',
  'en-US': 'EN',
};
