import clsx from 'clsx';

import { FC } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

const NumberInput: FC<CurrencyInputProps> = (props) => {
  return (
    <CurrencyInput
      decimalsLimit={2}
      // decimalSeparator="."
      // groupSeparator=","
      allowNegativeValue={false}
      prefix=""
      className={clsx('bg-transparent border-0 text-end p-0 w-100 fw-bold', props.className)}
      style={{ outline: 'none', fontSize: '20px' }}
      {...props}
    />
  );
};

export default NumberInput;
