import { fetchChainSuccess } from '../store/chain/actions';
import { wrapPromise } from '../helpers/UtilsHelpers';
import ChainService from '../services/ChainService';
import { useDispatch } from 'react-redux';
import { useAuth } from './auth';
import { WithChildren } from '../helpers/react18MigrationHelpers';
import { memo, useEffect } from 'react';
import { SETTINGS } from './settings/actions';

const chains = wrapPromise(ChainService.getChainList());

const DataInit: React.FC<WithChildren> = (props) => {
  const dispatch = useDispatch();
  const { auth } = useAuth();

  useEffect(() => {
    if (auth?.token) {
      const _chains = chains.read();

      if (_chains) {
        dispatch(fetchChainSuccess({ chains: _chains }));
      }
      dispatch(SETTINGS.REQUEST());
    }
  }, [auth]);

  return <>{props.children}</>;
};

export default memo(DataInit); //connector(DataInit);
