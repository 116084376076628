import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FaIcons } from '../../helpers/FaIconHelper';
// import { TransactionItem } from '../../store/transaction/types';
import { Address } from '../token/Address';
import { TxTypes } from '../../types/Transaction';
import clsx from 'clsx';
import DepositDetails from '../TransactionDetails/DepositDetails';
import { ConsumeNFTDetails } from '../TransactionDetails/ConsumeNFTDetails';
import IssuedNFTDetails from '../TransactionDetails/IssuedNFTDetails';
import TransferDetails from '../TransactionDetails/TransferDetails';
import WithdrawDetails from '../TransactionDetails/WithdrawDetails';
import UserpayDetails from '../TransactionDetails/UserpayDetails';
import { Trans } from '@lingui/macro';

const TxViewDetailsAction = ({
  transactionId,

  type,

  paymentId,
}: {
  transactionId: string;

  type: string;

  paymentId: string;
}) => {
  const [show, setShow] = useState(false);
  const onHide = () => {
    setShow(!show);
  };

  // console.log('show', show)
  const settingComponents: Record<string, any> = {
    [TxTypes.DEPOSIT]: DepositDetails,
    [TxTypes.CONSUMED_NFT]: ConsumeNFTDetails,
    [TxTypes.ISSUED_NFT]: IssuedNFTDetails,
    [TxTypes.TRANSFER]: TransferDetails,
    [TxTypes.WITHDRAW]: WithdrawDetails,
    [TxTypes.USER_PAY]: UserpayDetails,
  };

  const Component = settingComponents[type];

  return (
    <>
      <Address address={paymentId} onClick={onHide} linkClassName="hover" />
      {/* {show && <TxDetailModal {...{ show, onHide, transaction }} />} */}
      {show && (
        <Modal
          className="modal-sticky modal-sticky-lg modal-sticky-bottom-right"
          id="kt_mega_menu_modal"
          tabIndex="999"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          dialogClassName="modal-dialog mw-500px"
          onHide={onHide}
        >
          <div>
            <div className="modal-header border-0 pb-0">
              <h2>
                <Trans>Transaction details</Trans>
              </h2>
              {/* Chi tiết giao dịch */}
              <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
                <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
              </button>
            </div>

            {transactionId && (
              <>
                <Component transactionId={transactionId} />;
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default TxViewDetailsAction;

// export const TxDetailModal = ({
//   show,
//   onHide,
//   transaction,
// }: {
//   show: boolean;
//   onHide: () => void;
//   transaction: TransactionItem;
// }) => {
//   return (
//     <Modal
//       className="modal-sticky modal-sticky-lg modal-sticky-bottom-right"
//       id="kt_mega_menu_modal"
//       aria-hidden="true"
//       tabIndex="999"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       show={show}
//       dialogClassName="modal-dialog mw-500px"
//       onHide={onHide}
//     >
//       <div>
//         <div className="modal-header border-0 pb-0">
//           <h2>
//             {/* Chi tiết giao dịch */}
//             <Trans>Transaction details</Trans>
//           </h2>
//           <button onClick={onHide} className="btn btn-sm btn-icon btn-active-color-primary">
//             <i className={clsx(FaIcons['fas fa-times'], 'fs-1')} />
//           </button>
//         </div>
//         {transaction.txType === TxTypes.DEPOSIT && <DepositDetails transaction={transaction} />}
//         {transaction.txType === TxTypes.CONSUMED_NFT && <ConsumeNFTDetails transaction={transaction} />}
//         {transaction.txType === TxTypes.ISSUED_NFT && <IssuedNFTDetails transaction={transaction} />}
//         {transaction.txType === TxTypes.TRANSFER && <TransferDetails transaction={transaction} />}
//         {transaction.txType === TxTypes.WITHDRAW && <WithdrawDetails transaction={transaction} />}
//         {transaction.txType === TxTypes.USER_PAY && <UserpayDetails transaction={transaction} />}
//       </div>
//     </Modal>
//   );
// };
