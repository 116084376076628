import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_SETTINGS_REQUEST, SETTINGS } from './actions';
import { getSettings } from '../../services/SettingService';
import { SettingsModel } from './types';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function* fetchSetting({ payload }: any) {
  try {
    const response: SettingsModel = yield call(getSettings, payload);
    yield put(SETTINGS.SUCCESS(response));
  } catch (err) {
    yield put(SETTINGS.ERROR(err));
    console.log('Func: fetchSettingSaga - PARAMS: err', err);
  }
}

function* settingsSaga() {
  yield takeEvery(GET_SETTINGS_REQUEST, fetchSetting);
}

export default settingsSaga;
