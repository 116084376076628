import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { WithChildren } from '../../../helpers/react18MigrationHelpers';
import { KTSVG } from '../../../helpers';
import { IconFonts } from '../../../helpers/IconFontHelpers';

type Props = {
  to?: string;
  title: string;
  imgIcon?: string;
  fontIcon?: IconFonts;
  isActive?: boolean;
  hasBullet?: boolean;
  counter?: number;
  onClick?: () => void;
  iconImg?: string;
};

const SidebarMenuItem: React.FC<Props & WithChildren> = ({
  children,
  to = '',
  title,
  imgIcon,
  fontIcon,
  isActive,
  hasBullet = false,
  counter,
  onClick,
  iconImg,
}) => {
  return (
    <div className="menu-item" onClick={onClick}>
      <Link className={clsx('menu-link without-sub', 'd-flex items-center', { active: isActive })} to={to}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {imgIcon && (
          <span className="menu-icon">
            <KTSVG path={imgIcon} className="svg-icon-2" />
          </span>
        )}
        {fontIcon && <span className={clsx('me-2 fs-3', fontIcon)}></span>}
        {iconImg && (
          <span className={clsx('me-1')}>
            <img src={`/img/${iconImg}.svg`} />
          </span>
        )}
        <span className="menu-title">{title}</span>
        {counter !== undefined && counter > 0 && (
          <span className="menu-badge">
            <span className="badge badge-sm badge-circle badge-danger">{counter}</span>
          </span>
        )}
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
