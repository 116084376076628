import AxiosInstance from './axios';
import { buildQueryString } from '../helpers/UtilsHelpers';
import { IChain, TListModel } from '../types';
import {
  IConsume,
  IDenomination,
  INftIssueHistory,
  IIssueNftResult,
  IEstimatePrice,
  INft,
  IFilterNFT,
  INFTDashboard,
} from '../types/MerchantNFT';

//phat hanh nft
const issue = async ({
  chainCode,
  denominationId,
  quantity,
  distributorAddress,
  estimatedFeeAmount,
}: {
  chainCode: string;
  denominationId: string;
  quantity: number;
  distributorAddress: string;
  estimatedFeeAmount: number;
}): Promise<IIssueNftResult> => {
  try {
    const url = '/merchant-nft/issue';
    return await AxiosInstance.post(url, {
      chainCode,
      denominationId,
      quantity,
      distributorAddress,
      estimatedFeeAmount,
    }).then((res) => res.data);
  } catch (err) {
    console.log('Func: issue - PARAMS: err', err);
    throw err;
  }
};

//tinh toan nft
const estimatePrice = async ({
  chainCode,
  denominationValue,
  quantity,
  distributorAddress,
  signal,
}: {
  chainCode: string;
  denominationValue: number;
  quantity: number;
  distributorAddress: string;
  signal?: AbortSignal;
}): Promise<IEstimatePrice> => {
  try {
    const url = '/merchant-nft/estimate-price';
    return await AxiosInstance.post(
      url,
      {
        chainCode,
        denominationValue,
        quantity,
        distributorAddress,
      },
      { signal }
    ).then((res) => res.data);
  } catch (err) {
    console.log('Func: estimatePrice', err);
    throw err;
  }
};

//su dung nft
const consume = async ({
  chainCode,
  denominationId,
  quantity,
  distributorId,
}: {
  chainCode: string;
  denominationId: string;
  quantity: number;
  distributorId: string;
}): Promise<IConsume> => {
  try {
    const url = '/merchant-nft/consume';
    return await AxiosInstance.post(url, {
      chainCode,
      denominationId,
      quantity,
      distributorId,
    }).then((res) => res.data);
  } catch (err) {
    console.log('Func: consume - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

const getIssueHistory = async ({
  status,
  txType,
  fromDate,
  toDate,
  page,
}: {
  status?: string;
  txType?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
}): Promise<TListModel<INftIssueHistory>> => {
  try {
    const url = '/merchant-nft/issue-history';
    const urlSearchParams = buildQueryString({
      status,
      txType,
      fromDate,
      toDate,
      page,
    });
    return await AxiosInstance.get(`${url}?${urlSearchParams}`).then((res) => res.data);
  } catch (err) {
    console.log('Func: getIssueHistory - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

// danh sach nft
const getNftList = async ({
  status,
  tokenId,
  fromDate,
  toDate,
  page,
  size = 10,
}: {
  status?: string;
  tokenId?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
  size?: number;
}): Promise<TListModel<INft>> => {
  try {
    const url = '/merchant-nft/list';
    const urlSearchParams = buildQueryString({
      status,
      tokenId,
      fromDate,
      toDate,
      page,
      size,
    });
    return await AxiosInstance.get(`${url}?${urlSearchParams}`).then((res) => res.data);
  } catch (err) {
    console.log('Func: getIssueHistory - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

// danh sach nft
const dashboard = async ({
  chainCode,
}: {
  status?: string;
  tokenId?: string;
  distributorAddress?: string;
  chainCode?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
  size?: number;
}): Promise<INFTDashboard> => {
  try {
    const url = '/merchant-nft/dashboard';
    const urlSearchParams = buildQueryString({
      chainCode,
    });
    return await AxiosInstance.get(`${url}?${urlSearchParams}`).then((res) => res.data);
  } catch (err) {
    console.log('Func: dashboard - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

// danh sach nft
const getIssuedNFTs = async ({
  status,
  tokenId,
  distributorAddress,
  chain_Code,
  fromDate,
  toDate,
  page,
  size = 10,
}: {
  status?: string;
  tokenId?: string;
  distributorAddress?: string;
  chain_Code?: string;
  fromDate?: string;
  toDate?: string;
  page?: number;
  size?: number;
}): Promise<TListModel<INft>> => {
  try {
    const url = '/merchant-nft/issued-nfts';
    const chainCode = chain_Code;
    const urlSearchParams = buildQueryString({
      status,
      tokenId,
      distributorAddress,
      chainCode,
      fromDate,
      toDate,
      page,
      size,
    });
    return await AxiosInstance.get(`${url}?${urlSearchParams}`).then((res) => res.data);
  } catch (err) {
    console.log('Func: getIssueHistory - PARAMS: err', err);
    // toast.error(err?.response?.data?.message);
    throw err;
  }
};

//danh sach gia tri nft
const getDenominations = async (): Promise<IDenomination[]> => {
  try {
    const url = '/merchant-nft/denominations';
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('Func: getConfig - PARAMS: err', err);
    throw err;
  }
};

//danh sach gia tri nft
const getNft = async (id: string): Promise<INftIssueHistory> => {
  try {
    const url = `/merchant-nft/issue/${id}`;
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('Func: getConfig - PARAMS: err', err);
    throw err;
  }
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
const addDenomination = async ({ value, file }: { value: number; file?: any }) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('value', value.toString());
    if (file) {
      bodyFormData.append('file', file);
    }
    const headers = { 'Content-Type': 'multipart/form-data' };
    const url = '/merchant-nft/denominations';
    return await AxiosInstance.post(url, bodyFormData, { headers }).then((res) => res.data);
  } catch (err) {
    console.log('Func: consume - PARAMS: err', err);
    //  toast.error(err?.response?.data?.message);
    throw err;
  }
};

//danh sach gia tri nft
const getChains = async (): Promise<IChain[]> => {
  try {
    const url = '/merchant-nft/chains';
    return await AxiosInstance.get(url).then((res) => res.data);
  } catch (err) {
    console.log('Func: getConfig - PARAMS: err', err);
    //  toast.error(err?.response?.data?.message);
    throw err;
  }
};

const exportExcel = async (params: IFilterNFT) => {
  try {
    delete params.page;
    const searchParams: any = params;
    const querystring = new URLSearchParams(searchParams);
    const url = '/merchant-nft/list/export';
    const res = await AxiosInstance.get(`${url}?${querystring}`, { responseType: 'blob' });

    // console.log('res', res);
    const disposition = res.headers['content-disposition']?.toString();
    const filenameRegex = /filename[^;=\n]*=(([‘“]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition || '');
    const filename =
      matches && matches[1] ? matches[1].replace(/['"]/g, '') : `download-file-${new Date().getTime()}.xlsx`;

    const dataUrl = URL.createObjectURL(res.data);
    // console.log('dataUrl', dataUrl);

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error: any) {
    console.log('[API] exportExcel', error);
    throw error;
  }
};

export default {
  getIssueHistory,
  issue,
  consume,
  getDenominations,
  addDenomination,
  getNft,
  getChains,
  estimatePrice,
  getNftList,
  getIssuedNFTs,
  exportExcel,
  dashboard,
};
