/* eslint-disable  @typescript-eslint/no-explicit-any */
interface ProviderProps {
  connector: any;
  provider?: string;
  title: string;
  logo: string;
  disabled?: boolean;
  onSelect: (connector: any, provider: any) => void;
}

const ProviderItem = ({ connector, provider, logo, title, onSelect, disabled = false }: ProviderProps): JSX.Element => {
  return (
    <button
      className="btn btn-lg d-flex justify-content-center align-items-center w-100 bg-primary border-none bg-opacity-25"
      disabled={disabled}
      onClick={() => {
        onSelect(connector, provider);
      }}
    >
      <img src={`/img/${logo}`} height={24} width={24} alt={logo} />
      <div className="fw-bold ms-2 text-primary">{title}</div>
    </button>
  );
};

export default ProviderItem;
