import { ChangeEvent, CSSProperties } from 'react';
import clsx from 'clsx';

type OptionValue = string;

type Option<Type extends OptionValue> = {
  value: Type;
  label: any;
};

type SelectProps<Type extends OptionValue> = {
  options: Option<Type>[];
  name: string;
  onChange?: (val: any) => void;
  className?: string;
  style?: CSSProperties;
};

function Select<Type extends OptionValue>({
  options,
  name,
  onChange,
  className,
  style,
  ...restProps
}: SelectProps<Type>) {
  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    if (onChange) onChange(event.target.value);
  }

  return (
    <select
      value={name}
      onChange={handleChange}
      className={clsx('form-select', className)}
      style={style}
      {...restProps}
    >
      {options.map((option, index) => (
        <option className="text-uppercase" key={`${option.label}-${index}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
export { Select };
