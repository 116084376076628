import { Trans } from '@lingui/macro';
import { TxStatus, TxTypes } from '../types/Transaction';
import { DistributorStatus } from '../types/MerchantDistributor';
import { NFTStatus } from '../types/MerchantNFT';

export enum StringTypes {
  STATUS = 'STATUS',
  TYPE = 'TYPE',
  LOG = 'LOG',
  DISTRIBUTOR_STATUS = 'DISTRIBUTOR_STATUS',
  NFT_STATUS = 'NFT_STATUS',
}

const TransString = ({ value, type = StringTypes.STATUS }: { value: string; type?: StringTypes }) => {
  if (type === StringTypes.TYPE) {
    return <TypeLabel value={value} />;
  }

  if (type === StringTypes.LOG) {
    return <LogStatusLabel value={value} />;
  }

  if (type === StringTypes.DISTRIBUTOR_STATUS) {
    return <DistributorStatusLabel value={value} />;
  }

  if (type === StringTypes.NFT_STATUS) {
    return <NFTStatusLabel value={value} />;
  }

  return <StatusLabel value={value} />;
};

export default TransString;

export const StatusLabel = ({ value }: { value: string }) => {
  let component = <></>;
  switch (value) {
    // ALL
    case TxStatus.ALL:
      component = <Trans>ALL</Trans>;
      break;
    // DRAFT
    // case TxStatus.DRAFT:
    //   component = <Trans>DRAFT</Trans>;
    //   break;
    // CREATED
    case TxStatus.CREATED:
      component = <Trans>CREATED</Trans>;
      break;

    // FAILED
    case TxStatus.FAILED:
      component = <Trans>FAILED</Trans>;
      break;

    // EXPIRED
    case TxStatus.EXPIRED:
      component = <Trans>EXPIRED</Trans>;
      break;

    case TxStatus.COMPLETED:
      component = <Trans>COMPLETED</Trans>;
      break;

    case TxStatus.PROCESSING:
      component = <Trans>PROCESSING</Trans>;
      break;
    case TxStatus.INVALID_DATA:
      component = <Trans>INVALID DATA</Trans>;
      break;
    // case TxStatus.LAMBDA_FAILED:
    //   component = <Trans>LAMBDA_FAILED</Trans>;
    //   break;
    case TxStatus.ONCHAIN_SUCCESS:
      component = <Trans>ONCHAIN SUCCESS</Trans>;
      break;
    case TxStatus.OVER_PAID:
      component = <Trans>OVER PAID</Trans>;
      break;
    case TxStatus.UNDER_PAID:
      component = <Trans>UNDER PAID</Trans>;
      break;
    default:
      break;
  }

  return component;
};

export const TypeLabel = ({ value }: { value: string }) => {
  let component = <></>;
  switch (value) {
    // ALL
    case TxTypes.ALL:
      component = <Trans>ALL</Trans>;
      break;

    case TxTypes.CONSUMED_NFT:
      component = <Trans>CONSUMED NFT</Trans>;
      break;

    case TxTypes.DEPOSIT:
      component = <Trans>DEPOSIT</Trans>;
      break;

    case TxTypes.ISSUED_NFT:
      component = <Trans>ISSUED NFT</Trans>;
      break;

    case TxTypes.TRANSFER:
      component = <Trans>TRANSFER</Trans>;
      break;

    case TxTypes.USER_PAY:
      component = <Trans>USER PAY</Trans>;
      break;

    case TxTypes.WITHDRAW:
      component = <Trans>WITHDRAW</Trans>;
      break;

    default:
      break;
  }

  return component;
};

export const LogStatusLabel = ({ value }: { value: string }) => {
  let component = <></>;
  switch (value) {
    // CREATED
    case TxStatus.CREATED:
      component = <Trans>TX_CREATED</Trans>;
      break;

    // FAILED
    case TxStatus.FAILED:
      component = <Trans>TX_FAILED</Trans>;
      break;

    // EXPIRED
    case TxStatus.EXPIRED:
      component = <Trans>TX_EXPIRED</Trans>;
      break;

    case TxStatus.COMPLETED:
      component = <Trans>TX_COMPLETED</Trans>;
      break;

    case TxStatus.PROCESSING:
      component = <Trans>TX_PROCESSING</Trans>;
      break;

    default:
      break;
  }

  return component;
};

export const DistributorStatusLabel = ({ value }: { value: string }) => {
  let component = <></>;
  switch (value) {
    // ALL
    case DistributorStatus.ACTIVE:
      component = <Trans>ACTIVE</Trans>;
      break;

    case DistributorStatus.DEACTIVE:
      component = <Trans>DEACTIVE</Trans>;
      break;

    default:
      break;
  }

  return component;
};

export const NFTStatusLabel = ({ value }: { value: string }) => {
  let component = <></>;
  switch (value) {
    case NFTStatus.ALL:
      component = <Trans>ALL</Trans>;
      break;

    case NFTStatus.CONSUMED:
      component = <Trans>CONSUMED</Trans>;
      break;

    case NFTStatus.ISSUED:
      component = <Trans>ISSUED</Trans>;
      break;

    case NFTStatus.TRANSFERRED:
      component = <Trans>TRANSFERRED</Trans>;
      break;

    default:
      break;
  }

  return component;
};
