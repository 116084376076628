import { FC, useState } from 'react';

import Button from '../common/Button';
import { LoadingIcon } from '../common/LoadingIcon';
import DailySnapshotService from '../../services/DailySnapshotService';

const ButtonDowload: FC<{
  item: any;
}> = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const getDownload = async (snapshotId: string) => {
    return await new DailySnapshotService().getDownload(snapshotId);
  };

  const handleDownload = (snapshotId: string) => {
    setLoading(true);
    getDownload(snapshotId).then((res) => {
      if (res.status === true) {
        setLoading(false);
        window.open(res.data);
      }
      setLoading(false);
    });
  };
  return (
    <Button
      backgroundColor="light"
      className={`btn-icon btn-light me-3 ${item.exportUrl ? '' : 'btn-disabled'}`}
      disabled={loading}
      onClick={() => {
        handleDownload(item.id);
      }}
      target="_blank"
    >
      {loading ? <LoadingIcon /> : <i className="fas fa-download text-dark"></i>}
    </Button>
  );
};

export default ButtonDowload;
