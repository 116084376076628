import React from 'react';

import DateTime from '../../components/common/DateTime';
import { Table } from '../../components/table';
import { formatCurrency, getChain, getExplorerLink } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { Address } from '../../components/token/Address';
import { IFilterNFT, INftIndexes, getNFTStatusClass } from '../../types/MerchantNFT';

import LabelStatus from '../../components/common/LabelStatus';

import { Trans } from '@lingui/macro';
import { StringTypes } from '../../components/TransString';
import PreviewableImage from '../common/PreviewableImage';

export const NftList = ({
  data,
  children,

  handleChange,
}: {
  className?: string;

  data: any;
  handleChange: (params: IFilterNFT) => void;
  children: React.ReactChild | React.ReactNode;
}) => {
  const items = data?.items.map((item: any) => ({
    ...item,
    nftAddressOnChain: {
      nftAddress: item.nftAddress,
      chainCode: item.chainCode,
      tokenId: item.tokenId,
    },
    distributorAddressOnChain: {
      distributorAddress: item.distributorAddress,
      chainCode: item.chainCode,
    },
  }));

  const listModel: any = data
    ? {
        page: data.page,
        perPage: data.perPage,
        totalItems: data.totalItems,
        totalPages: data.totalPages,
        items,
      }
    : undefined;

  const columns = [
    {
      // 'Ngày phát hành' tiếng anh : 'Date of issue'
      title: <Trans>Date of issue</Trans>,
      dataIndex: INftIndexes.createdAt,
      key: INftIndexes.createdAt,
      render: (date: string) => <DateTime value={date} />,
    },
    {
      //Mã NFT tiếng anh : NFT code
      title: <Trans>NFT code</Trans>,
      dataIndex: INftIndexes.nftAddressOnChain,
      key: INftIndexes.nftAddressOnChain,
      className: '',
      render: (nftAddressOnChain: { nftAddress: string; chainCode: string; tokenId: number }) => {
        const chain = getChain(nftAddressOnChain.chainCode);
        return (
          <div className="d-flex align-items-center gap-2">
            <span className="symbol symbol-20px">
              <img src={chain?.logoUrl} className="mw-100 mh-100 p-0" alt="network" />
            </span>
            <div className="d-flex flex-wrap align-items-center">
              <div>
                <a
                  className=""
                  target="_blank"
                  rel="noreferrer"
                  href={getExplorerLink(
                    chain?.id,
                    `${nftAddressOnChain.nftAddress}?a=${nftAddressOnChain.tokenId}`,
                    'token'
                  )}
                >
                  #{nftAddressOnChain.tokenId}
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <>
          <Trans>NFT value</Trans> (VIC)
        </>
      ),
      dataIndex: INftIndexes.denomination,
      key: INftIndexes.denomination,
      className: 'text-end',
      render: (number: number) => <div className="text-end">{formatCurrency(number, CurrencySymbols.VIC, true)}</div>,
    },
    {
      title: <Trans>NFT image</Trans>,
      dataIndex: INftIndexes.imageUrl,
      key: INftIndexes.imageUrl,
      className: 'text-center',
      render: (imageUrl: string) => {
        return (
          <div className="text-center">
            <PreviewableImage imageUrl={imageUrl} />
            {/* <Image width={50} className="rounded-1 border shadow-sm" src={imageUrl} fallback="/img/no-img.png" /> */}
          </div>
        );
      },
    },
    {
      //'Phát hành đến địa chỉ' tiếng anh : 'Issued to address'
      title: <Trans>Issued to address</Trans>,
      dataIndex: INftIndexes.distributorAddressOnChain,
      key: INftIndexes.distributorAddressOnChain,
      render: (distributorAddressOnChain: { distributorAddress: string; chainCode: string }) =>
        distributorAddressOnChain && (
          <div>
            <div className="d-flex align-items-center gap-2">
              <Address
                address={distributorAddressOnChain.distributorAddress}
                link={getExplorerLink(
                  distributorAddressOnChain.chainCode,
                  distributorAddressOnChain.distributorAddress,
                  'address'
                )}
              />
            </div>
          </div>
        ),
    },
    {
      title: <Trans>Status</Trans>,
      dataIndex: INftIndexes.status,
      key: INftIndexes.status,
      className: 'text-center',
      render: (status: string) => {
        return <LabelStatus status={status} getStatusClass={getNFTStatusClass} type={StringTypes.NFT_STATUS} />;
      },
    },
  ];

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title d-block py-3">
          <h3 className="card-label mb-1">
            <Trans>Issued NFT list</Trans>
          </h3>
          <div className="fs-6 fw-semibold text-muted d-block ">
            <Trans>Issued NFT list and status.</Trans>
          </div>
        </div>
      </div>

      <div className="card-body">
        {children}
        <Table loading={false} columns={columns} handleChange={handleChange} listModel={listModel} />

        {/* <Table {...{ handleChange, columns, loading, listModel: data }} /> */}
      </div>
    </div>
  );
};
