/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getChainsSelector } from '../../store/chain/selectors';
import Select from 'react-select';
import { Trans, t } from '@lingui/macro';

const FormikChainSelect = ({ formik }: { formik: any }) => {
  const [data, setData] = useState<{ label: string; image: string; value: string }[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const chain = useSelector(getChainsSelector);

  useEffect(() => {
    if (chain) {
      getData();
    }
  }, [chain]);

  const getData = async () => {
    const data = chain
      .map((item) => ({
        label: item.name,
        image: `img/${item.code.toLowerCase()}.svg`,
        value: item.code,
      }))
      .filter((item) => item.value !== 'TRON');

    setData(data);
  };

  return (
    <div className="mb-6 position-relative" style={{ zIndex: 1000 }}>
      <label htmlFor="" className="form-label required">
        {/* Chọn mạng */}
        <Trans>Select network</Trans>
      </label>
      <div>
        <Select
          options={data}
          className="w-100"
          placeholder={<Trans>Select network</Trans>}
          noOptionsMessage={() => t`No options`}
          onChange={(item) => {
            formik.resetForm();
            formik.setFieldValue('chainCode', item?.value);
          }}
          value={data.find((i) => i.value === formik.getFieldProps('chainCode').value)}
          formatOptionLabel={(option) => (
            <div className="d-flex gap-3 align-items-center ">
              <img src={option.image} height={20} alt="image" />
              <span>{option.label}</span>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default FormikChainSelect;
