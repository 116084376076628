/* eslint-disable  @typescript-eslint/no-explicit-any */
import { BigNumber, Contract, ethers, utils } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { ABI, TOKEN_ABI, VCHAIN_ABI } from '../abi/paymentgateway';
import { Alert } from '../../helpers/AlertHelpers';
import { IWeb3 } from '../Web3Service';
import Web3 from 'web3';
import { ITokenChain } from '../../types';
import { t } from '@lingui/macro';
import { ADDRESS_ZERO, V_CHAIN } from '../../constants';

const checkMetaMask = async (): Promise<any> => {
  const provider = await detectEthereumProvider();
  const installedMetaMask = window?.ethereum && window?.ethereum?.isMetaMask;
  if (!provider || !installedMetaMask) {
    Alert.error(
      `<div className="text-muted">
       ${t`Please install the Metamask browser extension to connect Metamask wallet.`}
     </div>
     <br />
    <a class='btn btn-primary text-white' href='https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask' target="_blank">Cài đặt</a>
   `,
      true,
      false
    );
    return false;
  }
  if (provider !== window.ethereum || window.ethereum.isCoin98) {
    Alert.error(t`If you have installed many wallets, remove other wallets. Then try again.`, true);
    return false;
  }
  return provider;
};

class Ether implements IWeb3 {
  wallet: string = 'METAMASK';
  signer: any;
  provider: any;
  constructor(wallet: string) {
    this.wallet = wallet;
  }
  async connect(chain?: ITokenChain): Promise<{
    address: string;
    installed: boolean;
    connected: boolean;
    signer?: any;
    addressHex: string;
    error?: any;
  }> {
    let _address: any = '';
    let installed: boolean = true;
    let connected: boolean = false;
    let signer;
    let error;
    let address: any = _address;
    // require install Meta Mask
    const ethereum = await checkMetaMask();
    await ethereum.enable();
    const provider = new ethers.providers.Web3Provider(ethereum, 'any');
    if (!provider) {
      return { address, installed, connected, signer, addressHex: _address };
    }
    this.provider = provider;

    installed = true;

    try {
      await provider.send('eth_requestAccounts', []);
      provider.on('accountsChanged', function (accounts: string[]) {
        _address = accounts[0];
      });
      if (chain) {
        await this.switchChain(chain);
      }

      signer = provider.getSigner();
      this.signer = signer;

      _address = await signer.getAddress();
    } catch (e) {
      console.log('ethereum error', e);
      connected = false;
      error = e;
    }
    address = _address.toLowerCase();
    return { address, signer, installed, connected, addressHex: _address, error };
  }

  async switchChain(chain: ITokenChain) {
    let newChainId = null;
    if (chain) {
      const chainId = `0x${chain.id.toString(16)}`;
      try {
        await this.provider.send('wallet_switchEthereumChain', [{ chainId: chainId }]);
        newChainId = chain.id;
      } catch (e) {
        console.log('Error: Switch Chain', e);
      }
      if (!newChainId) {
        try {
          const newChain = {
            chainId: chainId,
            rpcUrls: [chain.publicEndpoint],
            chainName: chain.name,
            blockExplorerUrls: [chain.explorer],
            nativeCurrency: {
              name: chain.name,
              symbol: chain.coinSymbol,
              decimals: chain.coinDecimals,
            },
          };
          await this.provider.send('wallet_addEthereumChain', [newChain]);
          newChainId = chain.id;
        } catch (e) {
          console.log('Error: Add Chain', e);
        }
      }
    }
    return newChainId;
  }

  checkAddress(chainName: string) {
    return Web3.utils.isAddress(chainName);
  }

  getContract = (contractAddress: string, ABI: any): Contract => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    if (!provider) {
      throw new Error('Can not get contract');
    }
    return new ethers.Contract(contractAddress, ABI, provider.getSigner());
  };

  async approveToken(spender: string, tokenAddress: string, amount: number, decimals: number) {
    const tokenContract = this.getContract(tokenAddress, TOKEN_ABI);
    let _amount: BigNumber;
    if (Math.floor(amount) === amount) {
      _amount = BigNumber.from(amount).mul(BigNumber.from(10).pow(decimals));
    } else {
      _amount = utils.parseUnits(amount.toString(), decimals);
    }
    try {
      const approveTx = await tokenContract.approve(spender, _amount);
      const txReceipt = await approveTx.wait(1);
      return txReceipt;
    } catch (e) {
      console.log('Approve error', e);
      return false;
    }
  }

  async getAllowanceToken(tokenAddress: string, ownerAddress: string, spenderAddress: string, decimals = 18) {
    try {
      const contract = this.getContract(tokenAddress, TOKEN_ABI);
      const allowance = await contract.allowance(ownerAddress, spenderAddress);
      return parseFloat(utils.formatUnits(allowance || 0, decimals));
    } catch (e) {
      console.log('Get Allowance error', e);
      return 0;
    }
  }

  async depositWallet(
    sessionId: string,
    tokenAddress: string,
    spenderAddress: string,
    merchantAddress: string,
    amount: number,
    decimals: number,
    chainCode: string,
    gasPrice?: bigint | undefined
  ) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');

      const signer = provider.getSigner();

      if (chainCode === V_CHAIN) {
        return await this.depositVchain(
          sessionId,
          tokenAddress,
          spenderAddress,

          amount,
          decimals,
          signer
        );
      } else {
        return await this.deposit(
          sessionId,
          tokenAddress,
          spenderAddress,
          merchantAddress,
          amount,
          decimals,
          signer,
          gasPrice
        );
      }
    } catch (e) {
      console.log('Deposit error', e);
      throw e;
    }
  }

  async deposit(
    sessionId: string,
    tokenAddress: string,
    spenderAddress: string,
    merchantAddress: string,
    amount: number,
    decimals: number,
    signer: any,
    gasPrice?: bigint
  ) {
    const contract = this.getContract(spenderAddress, ABI);
    const _amount = utils.parseUnits(String(amount), decimals);
    const encoded = contract.interface.encodeFunctionData('deposit', [sessionId, tokenAddress, _amount]);
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const gasLimit = await provider.estimateGas({
      from: merchantAddress, // sender
      to: spenderAddress, // Payment gateway
      data: encoded,
    });

    const estimatedGas = gasPrice
      ? {
          gasLimit: gasLimit.mul(2),
          gasPrice: Math.round(Number(gasPrice) * 1.1),
        }
      : null;
    return await contract.connect(signer).deposit(sessionId, tokenAddress, _amount, estimatedGas);
  }

  async depositVchain(
    sessionId: string,
    tokenAddress: string,
    spenderAddress: string,

    amount: number,
    decimals: number,
    signer: any
  ) {
    const contract = this.getContract(spenderAddress, VCHAIN_ABI);
    const _amount = utils.parseUnits(String(amount), decimals);
    return await contract.connect(signer).deposit(sessionId, tokenAddress, _amount, {
      value: _amount,
    });
  }

  async getTokenBalance(walletAddress: string, address: string, decimals = 18): Promise<number | string | false> {
    if (walletAddress && address) {
      if (address === ADDRESS_ZERO) {
        return this.getNativeTokenBalance(walletAddress, address, decimals);
      } else {
        const contract = this.getContract(address, TOKEN_ABI);
        if (!contract) {
          return false;
        }
        const balance = await contract.balanceOf(walletAddress);
        return parseFloat(utils.formatUnits(balance || 0, decimals));
      }
    } else {
      return 0;
    }
  }
  async getNativeTokenBalance(walletAddress: string, address: string, decimals = 18): Promise<number | string | false> {
    if (walletAddress && address) {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const balance = await provider.getBalance(walletAddress);

      return parseFloat(utils.formatUnits(balance || 0, decimals));
    } else {
      return 0;
    }
  }
}
const EtherService = new Ether('METAMASK');
export default EtherService;
