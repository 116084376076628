export enum SnapshotStatus {
  'CREATED' = 'CREATED',
  'EXPORT_PROCESSING' = 'EXPORT_PROCESSING',
  'EXPORT_FINISH' = 'EXPORT_FINISH',
  'UPLOADING' = 'UPLOADING',
  'UPLOAD_FINISH' = 'UPLOAD_FINISH',
  'PENDING' = 'PENDING',
  'VERIFIED' = 'VERIFIED',
}

export enum SnapshotTokenType {
  'VIC' = 'VIC',
  'BUSD' = 'BUSD',
  'USDT' = 'USDT',
}
export interface ISnapshot {
  id: string;
  name: string;
  exportUrl: string;
  status: string;
  verifyStatus: string;
  createdAt: string;
  reportedAt: string;
  amount: number;
  note: string;
  verifiedBy: { email: string; name: string };
  verifiedAt: string;

  createdBy: {
    id: string;
    name: string;
  };
  updatedBy: {
    id: string;
    name: string;
  };

  totalTransaction: number;
  merchant: IMerchant;
  assetSummaries: IAssetSummaries[];
}
export interface IAssetSummaries {
  tokenType: string;
  dayStartBalance: number;
  dayEndBalance: number;
  amountIn: number;
  amountOut: number;
  transactionsIn: number;
  transactionsOut: number;
  totalTransactions: number;
}
export interface IMerchant {
  id: string;
  name: string;
  email: string;
  depositAddress: string;
  withdrawAddress: string;
  suspended: true;
  redirectUrl: string;
  callbackUrl: string;
  createdAt: string;
  updatedAt: string;
  balance: number;
  availableBalance: number;
  status: string;
  type: {
    code: string;
    name: string;
  };
}
export interface IFilterSnapshot {
  fromDate?: string;
  toDate?: string;
  page?: number;
  size?: number;
  sortBy?: string;
  sortDirection?: string;
}
