import clsx from 'clsx';
import { ITokenChain } from '../../types';
import TokenChainImg from './TokenChainImg';
import { BsCaretDownFill } from 'react-icons/bs';

interface TokenItemProps {
  onSelectChain?: (chainInfo: ITokenChain) => void;
  selectedChain?: ITokenChain;
  chain: ITokenChain;
  className?: string;
}
function TokenItem({ onSelectChain, chain, selectedChain, className = '' }: TokenItemProps): JSX.Element {
  if (!chain) {
    return <></>;
  }
  return (
    <div
      className={clsx(
        'dropdown-item',
        selectedChain?.code === chain.code && selectedChain.token.symbol === chain.token.symbol ? 'active' : ''
      )}
    >
      <div
        className={`d-flex border-0 align-items-center ${className}`}
        style={{ padding: '8px 10px' }}
        onClick={() => {
          if (onSelectChain) onSelectChain(chain);
        }}
      >
        <TokenChainImg
          token={chain.token.symbol.toUpperCase()}
          chain={chain.code.toLowerCase()}
          zoom={1.0}
          w={'15px'}
        />
        <span className="ms-5 me-1 fs-4 fw-bold  text-space-cadet">{chain.token.symbol}</span>
        <span className="text-dark-electric-blue fs-8">({chain.name})</span>
      </div>
    </div>
  );
}

function CurrentTokenItem({
  onSelectChain,
  chain,
  selectedChain,
  className = '',
}: {
  onSelectChain?: (chainInfo: ITokenChain) => void;
  chain: ITokenChain;
  selectedChain?: ITokenChain;
  className?: string;
}): JSX.Element {
  return (
    <div
      className={`${selectedChain?.token?.address === chain?.token?.address ? 'active' : ''} ${className}`}
      style={{ width: '10rem' }}
      onClick={() => {
        if (onSelectChain) onSelectChain(chain);
      }}
    >
      <div className="d-flex gap-4 align-items-center mb-2">
        <div>
          <TokenChainImg
            token={chain?.token?.symbol.toUpperCase()}
            chain={chain?.code?.toLocaleLowerCase()}
            zoom={1.0}
            w={'15px'}
          />
        </div>
        <div className="fw-bold fs-3">{chain?.token?.symbol}</div>
        <BsCaretDownFill />
      </div>
      <div className="text-start fs-8">
        {chain?.token.name} - {chain.name}
      </div>
    </div>
  );
}

export { TokenItem, CurrentTokenItem };
