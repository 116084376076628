import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { IFilterTransaction } from '../../store/transaction/types';
import { FetchStateComponent } from '../FetchStateComponent';
import TxViewDetailsAction from '../dashboard/TxViewDetailsAction';
import { TypeLabel } from '../TransString';
import DateTime from '../common/DateTime';

import FormatTokenAmount from '../atom/FormatTokenAmount';
import clsx from 'clsx';
import { ISnapshot } from '../../types/Snapshot';
import { Loading } from '../common/Loading';
import { NoData } from '../NoData';
import { Pagination } from '@cxptek/core';
import DailySnapshotService from '../../services/DailySnapshotService';
import UtilHelpers, { renderTxFeeType } from '../../helpers/UtilsHelpers';

const TransactionList = ({ snapshot }: { snapshot: ISnapshot }) => {
  const [filter, setFilter] = useState<IFilterTransaction>({
    size: 10,
  });

  const { isLoading, data, refetch, error, isFetching } = useQuery({
    queryKey: ['list-transaction-daily'],
    queryFn: async () => {
      const response = await new DailySnapshotService().getTransaction(snapshot.id, filter);
      return response;
    },
  });

  const handleChange = (params: IFilterTransaction) => {
    const p = params;
    if (!params.page) {
      p.page = 1;
    }
    setFilter((prev) => ({
      ...prev,
      ...p,
    }));
  };
  useEffect(() => {
    refetch();
  }, [filter]);
  return (
    <FetchStateComponent isLoading={isLoading || isFetching} error={error}>
      <div className="table-responsive">
        {/* begin::Table */}
        <table className="table table-hover">
          <thead>
            <tr>
              <th>
                <Trans>Reference code</Trans>
              </th>
              <th className="min-w-140px">
                <Trans>Transaction type</Trans>
              </th>

              <th className="min-w-140px">
                <Trans>Created date</Trans>
              </th>
              <th className="min-w-140px">
                <Trans>Completed date</Trans>
              </th>
              <th className="text-end">
                <Trans>Balance changes</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={8}>
                  <Loading />
                </td>
              </tr>
            )}
            {!data?.items.length && !isLoading && (
              <>
                <tr>
                  <td colSpan={8}>
                    <NoData />
                  </td>
                </tr>
              </>
            )}
            {data?.items &&
              data.items.map((item: any) => {
                return (
                  <tr key={`list_daily_${item.id}`}>
                    <td>
                      <TxViewDetailsAction transactionId={item.id} type={item.txType} paymentId={item.paymentId} />
                    </td>
                    <td>
                      <TypeLabel value={item.txType} />
                    </td>
                    <td>
                      <DateTime value={item.createdAt} />
                    </td>
                    <td>
                      <DateTime value={item.updatedAt} />
                    </td>
                    <td className="text-end">
                      <div className={clsx(' text-end', UtilHelpers.colorRenderTypes(item.txType))}>
                        <FormatTokenAmount
                          currency={item.baseToken}
                          amount={item.actualTxAmountAfterCostInBaseToken}
                          direction={renderTxFeeType(item.txType)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {data && data?.totalPages > 1 && (
        <Pagination
          totalPages={data.totalPages}
          currentPage={data.page == 0 ? 1 : data.page}
          onChangePage={(page: number) => handleChange({ page })}
        />
      )}
    </FetchStateComponent>
  );
};
export default TransactionList;
