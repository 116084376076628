import { FC } from 'react';
import { IAssetSummaries } from '../../types/Snapshot';
import { Trans } from '@lingui/macro';
import { formatCurrency } from '../../helpers/FormatHelpers';

interface Props {
  assetSummaries: any;
}

const AmmountStats: FC<Props> = ({ assetSummaries }) => {
  return (
    <div className="row">
      {assetSummaries.map((item: IAssetSummaries, i: number) => {
        return (
          <div className="col-6" key={`assetSummaries_${i}`}>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6">
                <Trans>Beginning of the day balance</Trans>
              </label>

              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.dayStartBalance, item.tokenType)}</div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6">
                <Trans>End of the day balance</Trans>
              </label>
              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.dayEndBalance, item.tokenType)}</div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6 ">
                <Trans>Outbound amount</Trans>
              </label>
              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.amountOut, item.tokenType)}</div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6 ">
                <Trans>Inbound amount</Trans>
              </label>
              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.amountIn, item.tokenType)}</div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6 ">
                <Trans>Total number of transactions</Trans>
              </label>
              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.totalTransactions, item.tokenType, true)}</div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6 ">
                <Trans>Total number of inbound transactions</Trans>
              </label>
              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.transactionsIn, item.tokenType, true)}</div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-md-5  fw-bold fs-6 ">
                <Trans>Total number of outbound transactions</Trans>
              </label>
              <div className="col-md-6">
                <div className="col-md-8">{formatCurrency(item.transactionsOut, item.tokenType, true)}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AmmountStats;
