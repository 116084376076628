import { Trans } from '@lingui/macro';

const OverLayLoading = () => {
  return (
    <div className="overlay-layer rounded bg-dark bg-opacity-5">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">
          <Trans>Loading</Trans>
        </span>
      </div>
    </div>
  );
};

export default OverLayLoading;
