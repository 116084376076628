import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as auth from '../../store/auth/AuthRedux';
import AuthService from '../../services/AuthService';
import { Alert, toAbsoluteUrl } from '../../helpers';
import { useAuth } from '../../store/auth';
import { useGoogleLogin } from '@react-oauth/google';
import { Trans, t } from '@lingui/macro';

const LoginByGoogleBtn = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { saveAuth, setCurrentUser } = useAuth();

  const login = useGoogleLogin({
    async onSuccess(codeResponse) {
      if (codeResponse.access_token) {
        loginByToken(codeResponse.access_token);
      }
    },
    onError(errorResponse) {
      console.log('errorResponse', errorResponse);
    },
  });

  const loginByToken = (token: string) => {
    AuthService.googleSignin(token)
      .then(async (res) => {
        const { data } = res;
        setLoading(false);
        saveAuth(data);
        AuthService.getMe(data.token)
          .then((user) => {
            setCurrentUser(user);
            dispatch(auth.actions.login(data.token));
          })
          .catch((err) => {
            console.log('err', err);
            //  Alert.error(t`Your gmail account is having an error or is locked! Please contact admin!`);
          });
      })
      .catch((err) => {
        // Alert.error(t`Your gmail account does not have access! Please contact admin!`);
        console.log('err', err);
        setLoading(false);
      });
  };

  const handleSignIn = async () => {
    setLoading(true);
    try {
      await login();
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      Alert.error(t`Login was denied on Google. Please check again!`);
    }
  };

  return (
    <>
      {/* <div className='google-btn d-flex justify-content-center mb-5'>
        <GoogleLogin
          width='100%'
          onSuccess={credentialResponse => {
            console.log(credentialResponse);
            if (credentialResponse.credential) {
              loginByToken(credentialResponse.credential?.toString())
            }
          }}
          onError={() => {
            console.log('Login Failed');
          }}

          shape='rectangular'
          size='large'
        />
      </div> */}
      <button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" onClick={handleSignIn}>
        <img alt="Logo" src={toAbsoluteUrl('/img/google.svg')} className="h-20px me-3" />
        {!loading && (
          <span className="indicator-label">
            <Trans>Login by Google</Trans>
          </span>
        )}
        {loading && (
          <span className="indicator-progress" style={{ display: 'block' }}>
            <Trans>Please wait</Trans>...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    </>
  );
};

export default LoginByGoogleBtn;
