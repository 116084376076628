import React from 'react';

import { toAbsoluteUrl } from '../helpers';
import { Trans } from '@lingui/macro';
type Props = {
  className?: string;
  text?: string;
};

const NoData: React.FC<Props> = ({ className, text }) => {
  return (
    <div className={`no-data text-center ${className}`}>
      <div>
        <img width="30%" src={toAbsoluteUrl('/img/svg/blank.svg')} />
      </div>
      <div className="content text-muted">{text || <Trans>No data</Trans>}</div>
    </div>
  );
};
export { NoData };
