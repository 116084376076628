// import { useAuth } from '../../../store/auth';
// import { HeaderUserMenu } from '../../../partials'
import CustomHeaderUserMenu from '../header-menus/CustomHeaderUserMenu';
import { useLayout } from '../../core';
import { Languages } from '../../../components/common/Languages';

const itemClass = 'ms-1 ms-lg-3';
const userAvatarClass = 'symbol-35px symbol-md-40px';

const Navbar = () => {
  const { config } = useLayout();

  // const { currentUser } = useAuth();
  // const avt = currentUser && currentUser?.roleNames?.length ? currentUser?.roleNames[0] : 'MANAGER';

  return (
    <div className="app-navbar flex-shrink-0 gap-5">
      <Languages />
      <CustomHeaderUserMenu userAvatarClassName={userAvatarClass} className={itemClass} />

      {config.app?.header?.default?.menu?.display && (
        <div className="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
          <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle"></div>
        </div>
      )}
    </div>
  );
};

export { Navbar };
