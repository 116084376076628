import { Trans } from '@lingui/macro';
import { MertchantTx } from '../../types/Merchant';
import LabelStatus from '../common/LabelStatus';
import { getStatusClass } from '../../types/Transaction';
import DateTime from '../common/DateTime';
import { formatCurrency } from '../../helpers/FormatHelpers';
import TransString, { StringTypes } from '../TransString';
import { Address } from '../token/Address';
import { getExplorerLink } from '../../helpers/BLockChainHelpers';

export const InforDetail = ({ details }: { details: MertchantTx }) => {
  return (
    <div style={{ background: '#F4F5F7' }} className="p-5 rounded-4 mb-3">
      <div className="row mb-3">
        <div className="col">
          {/* Loại giao dịch */}
          <Trans>Transaction type</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <span className="fw-bolder">
            <TransString value={details.generalTxInfo.txType} type={StringTypes.TYPE} />
          </span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          {/* Giá trị giao dịch */}
          <Trans>Transaction value</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          {formatCurrency(details.generalTxInfo.txValue.value, details.generalTxInfo.txValue.currency)}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          {/* Mã giao dịch 
          // tieng anh: Transaction hash
          */}
          <Trans>Transaction hash</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <Address
            address={details.generalTxInfo.txHash}
            link={getExplorerLink(details.chainCode, details.generalTxInfo.txHash, 'transaction')}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <Trans>Status</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <LabelStatus status={details.generalTxInfo.status} getStatusClass={getStatusClass} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          {/* Ngày giao dịch */}
          <Trans>Transaction date</Trans>
        </div>
        <div className="col d-flex justify-content-end ">
          <DateTime value={details.generalTxInfo.txDate} isInline />
        </div>
      </div>
    </div>
  );
};
