import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IFilterTransaction, TransactionItem, TransactionModel } from '../../store/transaction/types';
import DateTime from '../../components/common/DateTime';
import { TRANSACTION } from '../../store/transaction/actions';

import clsx from 'clsx';
import { formatCurrency, getExplorerLink } from '../../helpers';
import { CurrencySymbols } from '../../types/CurrencyBalance';
import { getChainsSelector } from '../../store/chain/selectors';
import LabelStatus from '../../components/common/LabelStatus';
import { getStatusClass, TxTypes } from '../../types/Transaction';
import TxViewDetailsAction from '../../components/dashboard/TxViewDetailsAction';
import UtilHelpers, { renderTxFeeType } from '../../helpers/UtilsHelpers';
import { Trans } from '@lingui/macro';
import { TypeLabel } from '../../components/TransString';
import { useAuth } from '../../store/auth';
import { MerchantType } from '../../types/Merchant';
import FormatTokenAmount from '../../components/atom/FormatTokenAmount';
import ChainIconWithAddress from '../common/ChainIconWithAddress';
import { Loading } from '../common/Loading';
import { NoData } from '../NoData';
import { Pagination } from '@cxptek/core';

export const Transactions = ({
  transactions,
  filter,
  children,
  handleChange,
  isLoading,
}: {
  className?: string;
  transactions?: TransactionModel;
  filter: IFilterTransaction;
  handleChange: (params: IFilterTransaction) => void;
  children: React.ReactChild | React.ReactNode;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const chains = useSelector(getChainsSelector);

  const items = transactions?.items.map((item: TransactionItem) => ({
    ...item,
    txHashOnChain: item.txHash
      ? {
          txHash: item.txHash,
          chainId: chains.find((chain) => chain.name === item.chainName)?.id || 0,
        }
      : null,
  }));

  const listModel: any = transactions
    ? {
        page: transactions.page,
        perPage: transactions.perPage,
        totalItems: transactions.totalItems,
        totalPages: transactions.totalPages,
        items,
      }
    : undefined;

  useEffect(() => {
    dispatch(TRANSACTION.REQUEST(filter));
  }, [dispatch, filter]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              <Trans>Transaction history</Trans>
            </h3>
          </div>
        </div>

        <div className="card-body">
          {children}
          <div className="row">
            <div className="col pt-4">
              <div className="table-responsive">
                {/* begin::Table */}
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>
                        <Trans>Reference code</Trans>
                      </th>
                      <th className="min-w-140px">
                        <Trans>Transaction type</Trans>
                      </th>
                      <th className="min-w-140px text-end">
                        <Trans>Transaction Amount</Trans>
                      </th>
                      <th className="text-end">
                        <Trans>Service fee</Trans>
                      </th>
                      <th className="text-end">
                        <Trans>Gas fee</Trans>
                      </th>
                      <th className="text-end">
                        <Trans>Balance changes</Trans>
                      </th>

                      <th className="min-w-140px">
                        <Trans>Transaction date</Trans>
                      </th>
                      <th className="min-w-140px">
                        <Trans>Status</Trans>
                      </th>
                      <th>
                        <Trans>Transaction hash</Trans>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan={8}>
                          <Loading />
                        </td>
                      </tr>
                    )}
                    {!listModel?.items.length && !isLoading && (
                      <>
                        <tr>
                          <td colSpan={8}>
                            <NoData />
                          </td>
                        </tr>
                      </>
                    )}
                    {listModel?.items &&
                      listModel.items.map((item: any) => {
                        const isDirection =
                          [TxTypes.ISSUED_NFT, TxTypes.TRANSFER, TxTypes.WITHDRAW].indexOf(item.txType as TxTypes) >= 0
                            ? true
                            : false;

                        return (
                          <tr key={`list-transaction_daily_${item.id}`}>
                            <td>
                              <TxViewDetailsAction
                                transactionId={item.id}
                                type={item.txType}
                                paymentId={item.paymentId}
                              />
                            </td>
                            <td>
                              <TypeLabel value={item.txType} />
                            </td>
                            <td>
                              <div className="text-end">
                                <FormatTokenAmount
                                  currency={item.tokenIn}
                                  amount={item.amount}
                                  direction={item?.cashFlowDirection === 'IN' ? '+' : '-'}
                                >
                                  {item.tokenIn === CurrencySymbols.USDT &&
                                    currentUser?.type === MerchantType.VIC_BASED && (
                                      <div className={clsx('fs-7 text-end text-gray-600', ' ')}>
                                        {formatCurrency(`${item?.amountInBaseToken}` || 0, CurrencySymbols.VIC, false)}
                                      </div>
                                    )}
                                </FormatTokenAmount>
                              </div>
                            </td>
                            <td>
                              <div className={clsx('text-end')}>
                                {
                                  <FormatTokenAmount
                                    currency={item.baseToken}
                                    amount={item.serviceCostInBaseToken}
                                    direction={item.serviceCostInBaseToken > 0 ? '-' : ''}
                                  ></FormatTokenAmount>
                                }
                              </div>
                            </td>
                            <td>
                              <div className={clsx('text-end')}>
                                {UtilHelpers.getGasFee(item.txType, item?.gasCostInBaseToken, item.gasless)}
                                {!!isDirection && (
                                  <FormatTokenAmount
                                    currency={item.baseToken}
                                    amount={item.gasCostInBaseToken}
                                    direction={item.gasCostInBaseToken > 0 ? '-' : ''}
                                    maximumFractionDigits={5}
                                  ></FormatTokenAmount>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className={clsx(' text-end')}>
                                <div
                                  className={clsx(
                                    ' text-end',
                                    ![0, null].includes(item.actualTxAmountAfterCostInBaseToken) &&
                                      UtilHelpers.colorRenderTypes(item.txType)
                                  )}
                                >
                                  <FormatTokenAmount
                                    currency={item.baseToken}
                                    amount={item.actualTxAmountAfterCostInBaseToken}
                                    direction={renderTxFeeType(item.txType)}
                                  />
                                </div>
                              </div>
                            </td>

                            <td>
                              <DateTime value={item.createdAt} />
                            </td>
                            <td>
                              <LabelStatus status={item.status} getStatusClass={getStatusClass} />
                            </td>

                            <td>
                              <ChainIconWithAddress
                                size={25}
                                chainCode={item.chainName}
                                address={item.txHash}
                                link={getExplorerLink(item.chainName, item.txHash, 'transaction')}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
            </div>
          </div>
          {listModel && listModel?.totalPages > 1 && (
            <Pagination
              totalPages={listModel?.totalPages}
              currentPage={listModel.page === 0 ? 1 : listModel.page}
              onChangePage={(page: number) => handleChange({ page })}
            />
          )}
        </div>
      </div>
    </>
  );
};
