import React from 'react';
import clsx from 'clsx';
import { Trans } from '@lingui/macro';

type Props = {
  className?: string;
  text?: string;
};

const Loading: React.FC<Props> = ({ text, className }) => {
  return (
    <span className={clsx('indicator-progress d-flex justify-content-center align-items-center', className)}>
      <span className="spinner-border spinner-border-sm align-middle me-2"></span>
      {text ? text : <Trans>Loading</Trans>}
    </span>
  );
};
export { Loading };
