export enum FaIcons {
  'fab fa-500px' = 'fab fa-500px',
  'fas fa-address-book' = 'fas fa-address-book',
  'far fa-address-book' = 'far fa-address-book',
  'fas fa-address-card' = 'fas fa-address-card',
  'far fa-address-card' = 'far fa-address-card',
  'fas fa-arrow-alt-circle-down' = 'fas fa-arrow-alt-circle-down',
  'far fa-arrow-alt-circle-down' = 'far fa-arrow-alt-circle-down',
  'fas fa-arrow-alt-circle-left' = 'fas fa-arrow-alt-circle-left',
  'far fa-arrow-alt-circle-left' = 'far fa-arrow-alt-circle-left',
  'fas fa-arrow-alt-circle-right' = 'fas fa-arrow-alt-circle-right',
  'far fa-arrow-alt-circle-right' = 'far fa-arrow-alt-circle-right',
  'fas fa-arrow-alt-circle-up' = 'fas fa-arrow-alt-circle-up',
  'far fa-arrow-alt-circle-up' = 'far fa-arrow-alt-circle-up',
  'fas fa-bell' = 'fas fa-bell',
  'far fa-bell' = 'far fa-bell',
  'fas fa-bell-slash' = 'fas fa-bell-slash',
  'far fa-bell-slash' = 'far fa-bell-slash',
  'fas fa-bookmark' = 'fas fa-bookmark',
  'far fa-bookmark' = 'far fa-bookmark',
  'fas fa-building' = 'fas fa-building',
  'far fa-building' = 'far fa-building',
  'fas fa-calendar' = 'fas fa-calendar',
  'far fa-calendar' = 'far fa-calendar',
  'fas fa-calendar-alt' = 'fas fa-calendar-alt',
  'far fa-calendar-alt' = 'far fa-calendar-alt',
  'fas fa-calendar-check' = 'fas fa-calendar-check',
  'far fa-calendar-check' = 'far fa-calendar-check',
  'fas fa-calendar-minus' = 'fas fa-calendar-minus',
  'far fa-calendar-minus' = 'far fa-calendar-minus',
  'fas fa-calendar-plus' = 'fas fa-calendar-plus',
  'far fa-calendar-plus' = 'far fa-calendar-plus',
  'fas fa-calendar-times' = 'fas fa-calendar-times',
  'far fa-calendar-times' = 'far fa-calendar-times',
  'fas fa-caret-square-down' = 'fas fa-caret-square-down',
  'far fa-caret-square-down' = 'far fa-caret-square-down',
  'fas fa-caret-square-left' = 'fas fa-caret-square-left',
  'far fa-caret-square-left' = 'far fa-caret-square-left',
  'fas fa-caret-square-right' = 'fas fa-caret-square-right',
  'far fa-caret-square-right' = 'far fa-caret-square-right',
  'fas fa-caret-square-up' = 'fas fa-caret-square-up',
  'far fa-caret-square-up' = 'far fa-caret-square-up',
  'fas fa-chart-bar' = 'fas fa-chart-bar',
  'far fa-chart-bar' = 'far fa-chart-bar',
  'fas fa-check-circle' = 'fas fa-check-circle',
  'far fa-check-circle' = 'far fa-check-circle',
  'fas fa-check-square' = 'fas fa-check-square',
  'far fa-check-square' = 'far fa-check-square',
  'fas fa-circle' = 'fas fa-circle',
  'far fa-circle' = 'far fa-circle',
  'fas fa-clipboard' = 'fas fa-clipboard',
  'far fa-clipboard' = 'far fa-clipboard',
  'fas fa-clock' = 'fas fa-clock',
  'far fa-clock' = 'far fa-clock',
  'fas fa-clone' = 'fas fa-clone',
  'far fa-clone' = 'far fa-clone',
  'fas fa-closed-captioning' = 'fas fa-closed-captioning',
  'far fa-closed-captioning' = 'far fa-closed-captioning',
  'fas fa-comment' = 'fas fa-comment',
  'far fa-comment' = 'far fa-comment',
  'fas fa-comment-alt' = 'fas fa-comment-alt',
  'far fa-comment-alt' = 'far fa-comment-alt',
  'fas fa-comments' = 'fas fa-comments',
  'far fa-comments' = 'far fa-comments',
  'fas fa-compass' = 'fas fa-compass',
  'far fa-compass' = 'far fa-compass',
  'fas fa-copy' = 'fas fa-copy',
  'far fa-copy' = 'far fa-copy',
  'fas fa-copyright' = 'fas fa-copyright',
  'far fa-copyright' = 'far fa-copyright',
  'fas fa-credit-card' = 'fas fa-credit-card',
  'far fa-credit-card' = 'far fa-credit-card',
  'fas fa-dot-circle' = 'fas fa-dot-circle',
  'far fa-dot-circle' = 'far fa-dot-circle',
  'fas fa-edit' = 'fas fa-edit',
  'far fa-edit' = 'far fa-edit',
  'fas fa-envelope' = 'fas fa-envelope',
  'far fa-envelope' = 'far fa-envelope',
  'fas fa-envelope-open' = 'fas fa-envelope-open',
  'far fa-envelope-open' = 'far fa-envelope-open',
  'fas fa-eye-slash' = 'fas fa-eye-slash',
  'far fa-eye-slash' = 'far fa-eye-slash',
  'fas fa-file' = 'fas fa-file',
  'far fa-file' = 'far fa-file',
  'fas fa-file-alt' = 'fas fa-file-alt',
  'far fa-file-alt' = 'far fa-file-alt',
  'fas fa-file-archive' = 'fas fa-file-archive',
  'far fa-file-archive' = 'far fa-file-archive',
  'fas fa-file-audio' = 'fas fa-file-audio',
  'far fa-file-audio' = 'far fa-file-audio',
  'fas fa-file-code' = 'fas fa-file-code',
  'far fa-file-code' = 'far fa-file-code',
  'fas fa-file-excel' = 'fas fa-file-excel',
  'far fa-file-excel' = 'far fa-file-excel',
  'fas fa-file-image' = 'fas fa-file-image',
  'far fa-file-image' = 'far fa-file-image',
  'fas fa-file-pdf' = 'fas fa-file-pdf',
  'far fa-file-pdf' = 'far fa-file-pdf',
  'fas fa-file-powerpoint' = 'fas fa-file-powerpoint',
  'far fa-file-powerpoint' = 'far fa-file-powerpoint',
  'fas fa-file-video' = 'fas fa-file-video',
  'far fa-file-video' = 'far fa-file-video',
  'fas fa-file-word' = 'fas fa-file-word',
  'far fa-file-word' = 'far fa-file-word',
  'fas fa-flag' = 'fas fa-flag',
  'far fa-flag' = 'far fa-flag',
  'fas fa-folder' = 'fas fa-folder',
  'far fa-folder' = 'far fa-folder',
  'fas fa-folder-open' = 'fas fa-folder-open',
  'far fa-folder-open' = 'far fa-folder-open',
  'fas fa-frown' = 'fas fa-frown',
  'far fa-frown' = 'far fa-frown',
  'fas fa-futbol' = 'fas fa-futbol',
  'far fa-futbol' = 'far fa-futbol',
  'fas fa-gem' = 'fas fa-gem',
  'far fa-gem' = 'far fa-gem',
  'fas fa-hand-lizard' = 'fas fa-hand-lizard',
  'far fa-hand-lizard' = 'far fa-hand-lizard',
  'fas fa-hand-paper' = 'fas fa-hand-paper',
  'far fa-hand-paper' = 'far fa-hand-paper',
  'fas fa-hand-peace' = 'fas fa-hand-peace',
  'far fa-hand-peace' = 'far fa-hand-peace',
  'fas fa-hand-point-down' = 'fas fa-hand-point-down',
  'far fa-hand-point-down' = 'far fa-hand-point-down',
  'fas fa-hand-point-left' = 'fas fa-hand-point-left',
  'far fa-hand-point-left' = 'far fa-hand-point-left',
  'fas fa-hand-point-right' = 'fas fa-hand-point-right',
  'far fa-hand-point-right' = 'far fa-hand-point-right',
  'fas fa-hand-point-up' = 'fas fa-hand-point-up',
  'far fa-hand-point-up' = 'far fa-hand-point-up',
  'fas fa-hand-pointer' = 'fas fa-hand-pointer',
  'far fa-hand-pointer' = 'far fa-hand-pointer',
  'fas fa-hand-rock' = 'fas fa-hand-rock',
  'far fa-hand-rock' = 'far fa-hand-rock',
  'fas fa-hand-scissors' = 'fas fa-hand-scissors',
  'far fa-hand-scissors' = 'far fa-hand-scissors',
  'fas fa-hand-spock' = 'fas fa-hand-spock',
  'far fa-hand-spock' = 'far fa-hand-spock',
  'fas fa-handshake' = 'fas fa-handshake',
  'far fa-handshake' = 'far fa-handshake',
  'fas fa-hdd' = 'fas fa-hdd',
  'far fa-hdd' = 'far fa-hdd',
  'fas fa-heart' = 'fas fa-heart',
  'far fa-heart' = 'far fa-heart',
  'fas fa-hospital' = 'fas fa-hospital',
  'far fa-hospital' = 'far fa-hospital',
  'fas fa-hourglass' = 'fas fa-hourglass',
  'far fa-hourglass' = 'far fa-hourglass',
  'fas fa-id-badge' = 'fas fa-id-badge',
  'far fa-id-badge' = 'far fa-id-badge',
  'fas fa-id-card' = 'fas fa-id-card',
  'far fa-id-card' = 'far fa-id-card',
  'fas fa-image' = 'fas fa-image',
  'far fa-image' = 'far fa-image',
  'fas fa-images' = 'fas fa-images',
  'far fa-images' = 'far fa-images',
  'fas fa-keyboard' = 'fas fa-keyboard',
  'far fa-keyboard' = 'far fa-keyboard',
  'fas fa-lemon' = 'fas fa-lemon',
  'far fa-lemon' = 'far fa-lemon',
  'fas fa-life-ring' = 'fas fa-life-ring',
  'far fa-life-ring' = 'far fa-life-ring',
  'fas fa-lightbulb' = 'fas fa-lightbulb',
  'far fa-lightbulb' = 'far fa-lightbulb',
  'fas fa-list-alt' = 'fas fa-list-alt',
  'far fa-list-alt' = 'far fa-list-alt',
  'fas fa-map' = 'fas fa-map',
  'far fa-map' = 'far fa-map',
  'fas fa-meh' = 'fas fa-meh',
  'far fa-meh' = 'far fa-meh',
  'fas fa-minus-square' = 'fas fa-minus-square',
  'far fa-minus-square' = 'far fa-minus-square',
  'fas fa-money-bill-alt' = 'fas fa-money-bill-alt',
  'far fa-money-bill-alt' = 'far fa-money-bill-alt',
  'fas fa-moon' = 'fas fa-moon',
  'far fa-moon' = 'far fa-moon',
  'fas fa-newspaper' = 'fas fa-newspaper',
  'far fa-newspaper' = 'far fa-newspaper',
  'fas fa-object-group' = 'fas fa-object-group',
  'far fa-object-group' = 'far fa-object-group',
  'fas fa-object-ungroup' = 'fas fa-object-ungroup',
  'far fa-object-ungroup' = 'far fa-object-ungroup',
  'fas fa-paper-plane' = 'fas fa-paper-plane',
  'far fa-paper-plane' = 'far fa-paper-plane',
  'fas fa-pause-circle' = 'fas fa-pause-circle',
  'far fa-pause-circle' = 'far fa-pause-circle',
  'fas fa-play-circle' = 'fas fa-play-circle',
  'far fa-play-circle' = 'far fa-play-circle',
  'fas fa-plus-square' = 'fas fa-plus-square',
  'far fa-plus-square' = 'far fa-plus-square',
  'fas fa-question-circle' = 'fas fa-question-circle',
  'far fa-question-circle' = 'far fa-question-circle',
  'fas fa-registered' = 'fas fa-registered',
  'far fa-registered' = 'far fa-registered',
  'fas fa-save' = 'fas fa-save',
  'far fa-save' = 'far fa-save',
  'fas fa-share-square' = 'fas fa-share-square',
  'far fa-share-square' = 'far fa-share-square',
  'fas fa-smile' = 'fas fa-smile',
  'far fa-smile' = 'far fa-smile',
  'fas fa-snowflake' = 'fas fa-snowflake',
  'far fa-snowflake' = 'far fa-snowflake',
  'fas fa-square' = 'fas fa-square',
  'far fa-square' = 'far fa-square',
  'fas fa-star' = 'fas fa-star',
  'far fa-star' = 'far fa-star',
  'fas fa-star-half' = 'fas fa-star-half',
  'far fa-star-half' = 'far fa-star-half',
  'fas fa-sticky-note' = 'fas fa-sticky-note',
  'far fa-sticky-note' = 'far fa-sticky-note',
  'fas fa-stop-circle' = 'fas fa-stop-circle',
  'far fa-stop-circle' = 'far fa-stop-circle',
  'fas fa-sun' = 'fas fa-sun',
  'far fa-sun' = 'far fa-sun',
  'fas fa-thumbs-down' = 'fas fa-thumbs-down',
  'far fa-thumbs-down' = 'far fa-thumbs-down',
  'fas fa-thumbs-up' = 'fas fa-thumbs-up',
  'far fa-thumbs-up' = 'far fa-thumbs-up',
  'fas fa-times-circle' = 'fas fa-times-circle',
  'far fa-times-circle' = 'far fa-times-circle',
  'fas fa-trash-alt' = 'fas fa-trash-alt',
  'far fa-trash-alt' = 'far fa-trash-alt',
  'fas fa-user' = 'fas fa-user',
  'far fa-user' = 'far fa-user',
  'fas fa-user-circle' = 'fas fa-user-circle',
  'far fa-user-circle' = 'far fa-user-circle',
  'fas fa-window-close' = 'fas fa-window-close',
  'far fa-window-close' = 'far fa-window-close',
  'fas fa-window-maximize' = 'fas fa-window-maximize',
  'far fa-window-maximize' = 'far fa-window-maximize',
  'fas fa-window-restore' = 'fas fa-window-restore',
  'far fa-window-restore' = 'far fa-window-restore',
  'fab fa-accessible-icon' = 'fab fa-accessible-icon',
  'fab fa-accusoft' = 'fab fa-accusoft',

  'fas fa-adjust' = 'fas fa-adjust',
  'fab fa-adn' = 'fab fa-adn',
  'fab fa-adversal' = 'fab fa-adversal',
  'fab fa-affiliatetheme' = 'fab fa-affiliatetheme',
  'fab fa-algolia' = 'fab fa-algolia',
  'fas fa-align-center' = 'fas fa-align-center',
  'fas fa-align-justify' = 'fas fa-align-justify',
  'fas fa-align-left' = 'fas fa-align-left',
  'fas fa-align-right' = 'fas fa-align-right',
  'fab fa-amazon' = 'fab fa-amazon',
  'fas fa-ambulance' = 'fas fa-ambulance',
  'fas fa-american-sign-language-interpreting' = 'fas fa-american-sign-language-interpreting',
  'fab fa-amilia' = 'fab fa-amilia',
  'fas fa-anchor' = 'fas fa-anchor',
  'fab fa-android' = 'fab fa-android',
  'fab fa-angellist' = 'fab fa-angellist',
  'fas fa-angle-double-down' = 'fas fa-angle-double-down',
  'fas fa-angle-double-left' = 'fas fa-angle-double-left',
  'fas fa-angle-double-right' = 'fas fa-angle-double-right',
  'fas fa-angle-double-up' = 'fas fa-angle-double-up',
  'fas fa-angle-down' = 'fas fa-angle-down',
  'fas fa-angle-left' = 'fas fa-angle-left',
  'fas fa-angle-right' = 'fas fa-angle-right',
  'fas fa-angle-up' = 'fas fa-angle-up',
  'fab fa-angrycreative' = 'fab fa-angrycreative',
  'fab fa-angular' = 'fab fa-angular',
  'fab fa-app-store' = 'fab fa-app-store',
  'fab fa-app-store-ios' = 'fab fa-app-store-ios',
  'fab fa-apper' = 'fab fa-apper',
  'fab fa-apple' = 'fab fa-apple',
  'fab fa-apple-pay' = 'fab fa-apple-pay',
  'fas fa-archive' = 'fas fa-archive',

  'fas fa-arrow-circle-down' = 'fas fa-arrow-circle-down',
  'fas fa-arrow-circle-left' = 'fas fa-arrow-circle-left',
  'fas fa-arrow-circle-right' = 'fas fa-arrow-circle-right',
  'fas fa-arrow-circle-up' = 'fas fa-arrow-circle-up',
  'fas fa-arrow-down' = 'fas fa-arrow-down',
  'fas fa-arrow-left' = 'fas fa-arrow-left',
  'fas fa-arrow-right' = 'fas fa-arrow-right',
  'fas fa-arrow-up' = 'fas fa-arrow-up',
  'fas fa-arrows-alt' = 'fas fa-arrows-alt',
  'fas fa-arrows-alt-h' = 'fas fa-arrows-alt-h',
  'fas fa-arrows-alt-v' = 'fas fa-arrows-alt-v',
  'fas fa-assistive-listening-systems' = 'fas fa-assistive-listening-systems',
  'fas fa-asterisk' = 'fas fa-asterisk',
  'fab fa-asymmetrik' = 'fab fa-asymmetrik',
  'fas fa-at' = 'fas fa-at',
  'fab fa-audible' = 'fab fa-audible',
  'fas fa-audio-description' = 'fas fa-audio-description',
  'fab fa-autoprefixer' = 'fab fa-autoprefixer',
  'fab fa-avianex' = 'fab fa-avianex',
  'fab fa-aviato' = 'fab fa-aviato',
  'fab fa-aws' = 'fab fa-aws',
  'fas fa-backward' = 'fas fa-backward',
  'fas fa-balance-scale' = 'fas fa-balance-scale',
  'fas fa-ban' = 'fas fa-ban',
  'fab fa-bandcamp' = 'fab fa-bandcamp',
  'fas fa-barcode' = 'fas fa-barcode',
  'fas fa-bars' = 'fas fa-bars',
  'fas fa-bath' = 'fas fa-bath',
  'fas fa-battery-empty' = 'fas fa-battery-empty',
  'fas fa-battery-full' = 'fas fa-battery-full',
  'fas fa-battery-half' = 'fas fa-battery-half',
  'fas fa-battery-quarter' = 'fas fa-battery-quarter',
  'fas fa-battery-three-quarters' = 'fas fa-battery-three-quarters',
  'fas fa-bed' = 'fas fa-bed',
  'fas fa-beer' = 'fas fa-beer',
  'fab fa-behance' = 'fab fa-behance',
  'fab fa-behance-square' = 'fab fa-behance-square',

  'fas fa-bicycle' = 'fas fa-bicycle',
  'fab fa-bimobject' = 'fab fa-bimobject',
  'fas fa-binoculars' = 'fas fa-binoculars',
  'fas fa-birthday-cake' = 'fas fa-birthday-cake',
  'fab fa-bitbucket' = 'fab fa-bitbucket',
  'fab fa-bitcoin' = 'fab fa-bitcoin',
  'fab fa-bity' = 'fab fa-bity',
  'fab fa-black-tie' = 'fab fa-black-tie',
  'fab fa-blackberry' = 'fab fa-blackberry',
  'fas fa-blind' = 'fas fa-blind',
  'fab fa-blogger' = 'fab fa-blogger',
  'fab fa-blogger-b' = 'fab fa-blogger-b',
  'fab fa-bluetooth' = 'fab fa-bluetooth',
  'fab fa-bluetooth-b' = 'fab fa-bluetooth-b',
  'fas fa-bold' = 'fas fa-bold',
  'fas fa-bolt' = 'fas fa-bolt',
  'fas fa-bomb' = 'fas fa-bomb',
  'fas fa-book' = 'fas fa-book',

  'fas fa-braille' = 'fas fa-braille',
  'fas fa-briefcase' = 'fas fa-briefcase',
  'fab fa-btc' = 'fab fa-btc',
  'fas fa-bug' = 'fas fa-bug',

  'fas fa-bullhorn' = 'fas fa-bullhorn',
  'fas fa-bullseye' = 'fas fa-bullseye',
  'fab fa-buromobelexperte' = 'fab fa-buromobelexperte',
  'fas fa-bus' = 'fas fa-bus',
  'fab fa-buysellads' = 'fab fa-buysellads',
  'fas fa-calculator' = 'fas fa-calculator',

  'fas fa-camera' = 'fas fa-camera',
  'fas fa-camera-retro' = 'fas fa-camera-retro',
  'fas fa-car' = 'fas fa-car',
  'fas fa-caret-down' = 'fas fa-caret-down',
  'fas fa-caret-left' = 'fas fa-caret-left',
  'fas fa-caret-right' = 'fas fa-caret-right',

  'fas fa-caret-up' = 'fas fa-caret-up',
  'fas fa-cart-arrow-down' = 'fas fa-cart-arrow-down',
  'fas fa-cart-plus' = 'fas fa-cart-plus',
  'fab fa-cc-amex' = 'fab fa-cc-amex',
  'fab fa-cc-apple-pay' = 'fab fa-cc-apple-pay',
  'fab fa-cc-diners-club' = 'fab fa-cc-diners-club',
  'fab fa-cc-discover' = 'fab fa-cc-discover',
  'fab fa-cc-jcb' = 'fab fa-cc-jcb',
  'fab fa-cc-mastercard' = 'fab fa-cc-mastercard',
  'fab fa-cc-paypal' = 'fab fa-cc-paypal',
  'fab fa-cc-stripe' = 'fab fa-cc-stripe',
  'fab fa-cc-visa' = 'fab fa-cc-visa',
  'fab fa-centercode' = 'fab fa-centercode',
  'fas fa-certificate' = 'fas fa-certificate',
  'fas fa-chart-area' = 'fas fa-chart-area',

  'fas fa-chart-line' = 'fas fa-chart-line',
  'fas fa-chart-pie' = 'fas fa-chart-pie',
  'fas fa-check' = 'fas fa-check',

  'fas fa-chevron-circle-down' = 'fas fa-chevron-circle-down',
  'fas fa-chevron-circle-left' = 'fas fa-chevron-circle-left',
  'fas fa-chevron-circle-right' = 'fas fa-chevron-circle-right',
  'fas fa-chevron-circle-up' = 'fas fa-chevron-circle-up',
  'fas fa-chevron-down' = 'fas fa-chevron-down',
  'fas fa-chevron-left' = 'fas fa-chevron-left',
  'fas fa-chevron-right' = 'fas fa-chevron-right',
  'fas fa-chevron-up' = 'fas fa-chevron-up',
  'fas fa-child' = 'fas fa-child',
  'fab fa-chrome' = 'fab fa-chrome',

  'fas fa-circle-notch' = 'fas fa-circle-notch',

  'fas fa-cloud' = 'fas fa-cloud',
  'fas fa-cloud-download-alt' = 'fas fa-cloud-download-alt',
  'fas fa-cloud-upload-alt' = 'fas fa-cloud-upload-alt',
  'fab fa-cloudscale' = 'fab fa-cloudscale',
  'fab fa-cloudsmith' = 'fab fa-cloudsmith',
  'fab fa-cloudversify' = 'fab fa-cloudversify',
  'fas fa-code' = 'fas fa-code',
  'fas fa-code-branch' = 'fas fa-code-branch',
  'fab fa-codepen' = 'fab fa-codepen',
  'fab fa-codiepie' = 'fab fa-codiepie',
  'fas fa-coffee' = 'fas fa-coffee',
  'fas fa-cog' = 'fas fa-cog',
  'fas fa-cogs' = 'fas fa-cogs',
  'fas fa-columns' = 'fas fa-columns',

  'fas fa-compress' = 'fas fa-compress',
  'fab fa-connectdevelop' = 'fab fa-connectdevelop',
  'fab fa-contao' = 'fab fa-contao',

  'fab fa-cpanel' = 'fab fa-cpanel',
  'fab fa-creative-commons' = 'fab fa-creative-commons',

  'fas fa-crop' = 'fas fa-crop',
  'fas fa-crosshairs' = 'fas fa-crosshairs',
  'fab fa-css3' = 'fab fa-css3',
  'fab fa-css3-alt' = 'fab fa-css3-alt',
  'fas fa-cube' = 'fas fa-cube',
  'fas fa-cubes' = 'fas fa-cubes',
  'fas fa-cut' = 'fas fa-cut',
  'fab fa-cuttlefish' = 'fab fa-cuttlefish',
  'fab fa-d-and-d' = 'fab fa-d-and-d',
  'fab fa-dashcube' = 'fab fa-dashcube',
  'fas fa-database' = 'fas fa-database',
  'fas fa-deaf' = 'fas fa-deaf',
  'fab fa-delicious' = 'fab fa-delicious',
  'fab fa-deploydog' = 'fab fa-deploydog',
  'fab fa-deskpro' = 'fab fa-deskpro',
  'fas fa-desktop' = 'fas fa-desktop',
  'fab fa-deviantart' = 'fab fa-deviantart',
  'fab fa-digg' = 'fab fa-digg',
  'fab fa-digital-ocean' = 'fab fa-digital-ocean',
  'fab fa-discord' = 'fab fa-discord',
  'fab fa-discourse' = 'fab fa-discourse',
  'fab fa-dochub' = 'fab fa-dochub',
  'fab fa-docker' = 'fab fa-docker',
  'fas fa-dollar-sign' = 'fas fa-dollar-sign',

  'fas fa-download' = 'fas fa-download',
  'fab fa-draft2digital' = 'fab fa-draft2digital',
  'fab fa-dribbble' = 'fab fa-dribbble',
  'fab fa-dribbble-square' = 'fab fa-dribbble-square',
  'fab fa-dropbox' = 'fab fa-dropbox',
  'fab fa-drupal' = 'fab fa-drupal',
  'fab fa-dyalog' = 'fab fa-dyalog',
  'fab fa-earlybirds' = 'fab fa-earlybirds',
  'fab fa-edge' = 'fab fa-edge',

  'fas fa-eject' = 'fas fa-eject',
  'fas fa-ellipsis-h' = 'fas fa-ellipsis-h',
  'fas fa-ellipsis-v' = 'fas fa-ellipsis-v',
  'fab fa-ember' = 'fab fa-ember',
  'fab fa-empire' = 'fab fa-empire',

  'fas fa-envelope-square' = 'fas fa-envelope-square',
  'fab fa-envira' = 'fab fa-envira',
  'fas fa-eraser' = 'fas fa-eraser',
  'fab fa-erlang' = 'fab fa-erlang',
  'fab fa-etsy' = 'fab fa-etsy',
  'fas fa-euro-sign' = 'fas fa-euro-sign',
  'fas fa-exchange-alt' = 'fas fa-exchange-alt',
  'fas fa-exclamation' = 'fas fa-exclamation',
  'fas fa-exclamation-circle' = 'fas fa-exclamation-circle',
  'fas fa-exclamation-triangle' = 'fas fa-exclamation-triangle',
  'fas fa-expand' = 'fas fa-expand',
  'fas fa-expand-arrows-alt' = 'fas fa-expand-arrows-alt',
  'fab fa-expeditedssl' = 'fab fa-expeditedssl',
  'fas fa-external-link-alt' = 'fas fa-external-link-alt',
  'fas fa-external-link-square-alt' = 'fas fa-external-link-square-alt',
  'fas fa-eye' = 'fas fa-eye',
  'fas fa-eye-dropper' = 'fas fa-eye-dropper',

  'fab fa-facebook' = 'fab fa-facebook',
  'fab fa-facebook-f' = 'fab fa-facebook-f',
  'fab fa-facebook-messenger' = 'fab fa-facebook-messenger',
  'fab fa-facebook-square' = 'fab fa-facebook-square',
  'fas fa-fast-backward' = 'fas fa-fast-backward',
  'fas fa-fast-forward' = 'fas fa-fast-forward',
  'fas fa-fax' = 'fas fa-fax',
  'fas fa-female' = 'fas fa-female',
  'fas fa-fighter-jet' = 'fas fa-fighter-jet',

  'fas fa-film' = 'fas fa-film',
  'fas fa-filter' = 'fas fa-filter',
  'fas fa-fire' = 'fas fa-fire',
  'fas fa-fire-extinguisher' = 'fas fa-fire-extinguisher',
  'fab fa-firefox' = 'fab fa-firefox',
  'fab fa-first-order' = 'fab fa-first-order',
  'fab fa-firstdraft' = 'fab fa-firstdraft',

  'fas fa-flag-checkered' = 'fas fa-flag-checkered',
  'fas fa-flask' = 'fas fa-flask',
  'fab fa-flickr' = 'fab fa-flickr',
  'fab fa-fly' = 'fab fa-fly',

  'fas fa-font' = 'fas fa-font',
  'fab fa-font-awesome' = 'fab fa-font-awesome',
  'fab fa-font-awesome-alt' = 'fab fa-font-awesome-alt',
  'fab fa-font-awesome-flag' = 'fab fa-font-awesome-flag',
  'fab fa-fonticons' = 'fab fa-fonticons',
  'fab fa-fonticons-fi' = 'fab fa-fonticons-fi',
  'fab fa-fort-awesome' = 'fab fa-fort-awesome',
  'fab fa-fort-awesome-alt' = 'fab fa-fort-awesome-alt',
  'fab fa-forumbee' = 'fab fa-forumbee',
  'fas fa-forward' = 'fas fa-forward',
  'fab fa-foursquare' = 'fab fa-foursquare',
  'fab fa-free-code-camp' = 'fab fa-free-code-camp',
  'fab fa-freebsd' = 'fab fa-freebsd',

  'fas fa-gamepad' = 'fas fa-gamepad',
  'fas fa-gavel' = 'fas fa-gavel',

  'fas fa-genderless' = 'fas fa-genderless',
  'fab fa-get-pocket' = 'fab fa-get-pocket',
  'fab fa-gg' = 'fab fa-gg',
  'fab fa-gg-circle' = 'fab fa-gg-circle',
  'fas fa-gift' = 'fas fa-gift',
  'fab fa-git' = 'fab fa-git',
  'fab fa-git-square' = 'fab fa-git-square',
  'fab fa-github' = 'fab fa-github',
  'fab fa-github-alt' = 'fab fa-github-alt',
  'fab fa-github-square' = 'fab fa-github-square',
  'fab fa-gitkraken' = 'fab fa-gitkraken',
  'fab fa-gitlab' = 'fab fa-gitlab',
  'fab fa-gitter' = 'fab fa-gitter',
  'fas fa-glass-martini' = 'fas fa-glass-martini',
  'fab fa-glide' = 'fab fa-glide',
  'fab fa-glide-g' = 'fab fa-glide-g',
  'fas fa-globe' = 'fas fa-globe',
  'fab fa-gofore' = 'fab fa-gofore',
  'fab fa-goodreads' = 'fab fa-goodreads',
  'fab fa-goodreads-g' = 'fab fa-goodreads-g',
  'fab fa-google' = 'fab fa-google',
  'fab fa-google-drive' = 'fab fa-google-drive',
  'fab fa-google-play' = 'fab fa-google-play',
  'fab fa-google-plus' = 'fab fa-google-plus',
  'fab fa-google-plus-g' = 'fab fa-google-plus-g',
  'fab fa-google-plus-square' = 'fab fa-google-plus-square',
  'fab fa-google-wallet' = 'fab fa-google-wallet',
  'fas fa-graduation-cap' = 'fas fa-graduation-cap',
  'fab fa-gratipay' = 'fab fa-gratipay',
  'fab fa-grav' = 'fab fa-grav',
  'fab fa-gripfire' = 'fab fa-gripfire',
  'fab fa-grunt' = 'fab fa-grunt',
  'fab fa-gulp' = 'fab fa-gulp',
  'fas fa-h-square' = 'fas fa-h-square',
  'fab fa-hacker-news' = 'fab fa-hacker-news',
  'fab fa-hacker-news-square' = 'fab fa-hacker-news-square',

  'fas fa-hashtag' = 'fas fa-hashtag',

  'fas fa-heading' = 'fas fa-heading',
  'fas fa-headphones' = 'fas fa-headphones',

  'fas fa-heartbeat' = 'fas fa-heartbeat',
  'fab fa-hire-a-helper' = 'fab fa-hire-a-helper',
  'fas fa-history' = 'fas fa-history',
  'fas fa-home' = 'fas fa-home',
  'fab fa-hooli' = 'fab fa-hooli',

  'fab fa-hotjar' = 'fab fa-hotjar',

  'fas fa-hourglass-end' = 'fas fa-hourglass-end',
  'fas fa-hourglass-half' = 'fas fa-hourglass-half',
  'fas fa-hourglass-start' = 'fas fa-hourglass-start',
  'fab fa-houzz' = 'fab fa-houzz',
  'fab fa-html5' = 'fab fa-html5',
  'fab fa-hubspot' = 'fab fa-hubspot',
  'fas fa-i-cursor' = 'fas fa-i-cursor',

  'fab fa-imdb' = 'fab fa-imdb',
  'fas fa-inbox' = 'fas fa-inbox',
  'fas fa-indent' = 'fas fa-indent',
  'fas fa-industry' = 'fas fa-industry',
  'fas fa-info' = 'fas fa-info',
  'fas fa-info-circle' = 'fas fa-info-circle',
  'fab fa-instagram' = 'fab fa-instagram',
  'fab fa-internet-explorer' = 'fab fa-internet-explorer',
  'fab fa-ioxhost' = 'fab fa-ioxhost',
  'fas fa-italic' = 'fas fa-italic',
  'fab fa-itunes' = 'fab fa-itunes',
  'fab fa-itunes-note' = 'fab fa-itunes-note',
  'fab fa-jenkins' = 'fab fa-jenkins',
  'fab fa-joget' = 'fab fa-joget',
  'fab fa-joomla' = 'fab fa-joomla',
  'fab fa-js' = 'fab fa-js',
  'fab fa-js-square' = 'fab fa-js-square',
  'fab fa-jsfiddle' = 'fab fa-jsfiddle',
  'fas fa-key' = 'fas fa-key',

  'fab fa-keycdn' = 'fab fa-keycdn',
  'fab fa-kickstarter' = 'fab fa-kickstarter',
  'fab fa-kickstarter-k' = 'fab fa-kickstarter-k',
  'fas fa-language' = 'fas fa-language',
  'fas fa-laptop' = 'fas fa-laptop',
  'fab fa-laravel' = 'fab fa-laravel',
  'fab fa-lastfm' = 'fab fa-lastfm',
  'fab fa-lastfm-square' = 'fab fa-lastfm-square',
  'fas fa-leaf' = 'fas fa-leaf',
  'fab fa-leanpub' = 'fab fa-leanpub',

  'fab fa-less' = 'fab fa-less',
  'fas fa-level-down-alt' = 'fas fa-level-down-alt',
  'fas fa-level-up-alt' = 'fas fa-level-up-alt',

  'fab fa-line' = 'fab fa-line',
  'fas fa-link' = 'fas fa-link',
  'fab fa-linkedin' = 'fab fa-linkedin',
  'fab fa-linkedin-in' = 'fab fa-linkedin-in',
  'fab fa-linode' = 'fab fa-linode',
  'fab fa-linux' = 'fab fa-linux',
  'fas fa-lira-sign' = 'fas fa-lira-sign',
  'fas fa-list' = 'fas fa-list',

  'fas fa-list-ol' = 'fas fa-list-ol',
  'fas fa-list-ul' = 'fas fa-list-ul',
  'fas fa-location-arrow' = 'fas fa-location-arrow',
  'fas fa-lock' = 'fas fa-lock',
  'fas fa-lock-open' = 'fas fa-lock-open',
  'fas fa-long-arrow-alt-down' = 'fas fa-long-arrow-alt-down',
  'fas fa-long-arrow-alt-left' = 'fas fa-long-arrow-alt-left',
  'fas fa-long-arrow-alt-right' = 'fas fa-long-arrow-alt-right',
  'fas fa-long-arrow-alt-up' = 'fas fa-long-arrow-alt-up',
  'fas fa-low-vision' = 'fas fa-low-vision',
  'fab fa-lyft' = 'fab fa-lyft',
  'fab fa-magento' = 'fab fa-magento',
  'fas fa-magic' = 'fas fa-magic',
  'fas fa-magnet' = 'fas fa-magnet',
  'fas fa-male' = 'fas fa-male',

  'fas fa-map-marker' = 'fas fa-map-marker',
  'fas fa-map-marker-alt' = 'fas fa-map-marker-alt',
  'fas fa-map-pin' = 'fas fa-map-pin',
  'fas fa-map-signs' = 'fas fa-map-signs',
  'fas fa-mars' = 'fas fa-mars',
  'fas fa-mars-double' = 'fas fa-mars-double',
  'fas fa-mars-stroke' = 'fas fa-mars-stroke',
  'fas fa-mars-stroke-h' = 'fas fa-mars-stroke-h',
  'fas fa-mars-stroke-v' = 'fas fa-mars-stroke-v',
  'fab fa-maxcdn' = 'fab fa-maxcdn',
  'fab fa-medapps' = 'fab fa-medapps',
  'fab fa-medium' = 'fab fa-medium',
  'fab fa-medium-m' = 'fab fa-medium-m',
  'fas fa-medkit' = 'fas fa-medkit',
  'fab fa-medrt' = 'fab fa-medrt',
  'fab fa-meetup' = 'fab fa-meetup',

  'fas fa-mercury' = 'fas fa-mercury',
  'fas fa-microchip' = 'fas fa-microchip',
  'fas fa-microphone' = 'fas fa-microphone',
  'fas fa-microphone-slash' = 'fas fa-microphone-slash',
  'fab fa-microsoft' = 'fab fa-microsoft',
  'fas fa-minus' = 'fas fa-minus',
  'fas fa-minus-circle' = 'fas fa-minus-circle',

  'fab fa-mix' = 'fab fa-mix',
  'fab fa-mixcloud' = 'fab fa-mixcloud',
  'fab fa-mizuni' = 'fab fa-mizuni',
  'fas fa-mobile' = 'fas fa-mobile',
  'fas fa-mobile-alt' = 'fas fa-mobile-alt',
  'fab fa-modx' = 'fab fa-modx',
  'fab fa-monero' = 'fab fa-monero',

  'fas fa-motorcycle' = 'fas fa-motorcycle',
  'fas fa-mouse-pointer' = 'fas fa-mouse-pointer',
  'fas fa-music' = 'fas fa-music',
  'fab fa-napster' = 'fab fa-napster',
  'fas fa-neuter' = 'fas fa-neuter',

  'fab fa-nintendo-switch' = 'fab fa-nintendo-switch',
  'fab fa-node' = 'fab fa-node',
  'fab fa-node-js' = 'fab fa-node-js',
  'fab fa-npm' = 'fab fa-npm',
  'fab fa-ns8' = 'fab fa-ns8',
  'fab fa-nutritionix' = 'fab fa-nutritionix',

  'fab fa-odnoklassniki' = 'fab fa-odnoklassniki',
  'fab fa-odnoklassniki-square' = 'fab fa-odnoklassniki-square',
  'fab fa-opencart' = 'fab fa-opencart',
  'fab fa-openid' = 'fab fa-openid',
  'fab fa-opera' = 'fab fa-opera',
  'fab fa-optin-monster' = 'fab fa-optin-monster',
  'fab fa-osi' = 'fab fa-osi',
  'fas fa-outdent' = 'fas fa-outdent',
  'fab fa-page4' = 'fab fa-page4',
  'fab fa-pagelines' = 'fab fa-pagelines',
  'fas fa-paint-brush' = 'fas fa-paint-brush',
  'fab fa-palfed' = 'fab fa-palfed',

  'fas fa-paperclip' = 'fas fa-paperclip',
  'fas fa-paragraph' = 'fas fa-paragraph',
  'fas fa-paste' = 'fas fa-paste',
  'fab fa-patreon' = 'fab fa-patreon',
  'fas fa-pause' = 'fas fa-pause',

  'fas fa-paw' = 'fas fa-paw',
  'fab fa-paypal' = 'fab fa-paypal',
  'fas fa-pen-square' = 'fas fa-pen-square',
  'fas fa-pencil-alt' = 'fas fa-pencil-alt',
  'fas fa-percent' = 'fas fa-percent',
  'fab fa-periscope' = 'fab fa-periscope',
  'fab fa-phabricator' = 'fab fa-phabricator',
  'fab fa-phoenix-framework' = 'fab fa-phoenix-framework',
  'fas fa-phone' = 'fas fa-phone',
  'fas fa-phone-square' = 'fas fa-phone-square',
  'fas fa-phone-volume' = 'fas fa-phone-volume',
  'fab fa-pied-piper' = 'fab fa-pied-piper',
  'fab fa-pied-piper-alt' = 'fab fa-pied-piper-alt',
  'fab fa-pied-piper-pp' = 'fab fa-pied-piper-pp',
  'fab fa-pinterest' = 'fab fa-pinterest',
  'fab fa-pinterest-p' = 'fab fa-pinterest-p',
  'fab fa-pinterest-square' = 'fab fa-pinterest-square',
  'fas fa-plane' = 'fas fa-plane',
  'fas fa-play' = 'fas fa-play',

  'fab fa-playstation' = 'fab fa-playstation',
  'fas fa-plug' = 'fas fa-plug',
  'fas fa-plus' = 'fas fa-plus',
  'fas fa-plus-circle' = 'fas fa-plus-circle',

  'fas fa-podcast' = 'fas fa-podcast',
  'fas fa-pound-sign' = 'fas fa-pound-sign',
  'fas fa-power-off' = 'fas fa-power-off',
  'fas fa-print' = 'fas fa-print',
  'fab fa-product-hunt' = 'fab fa-product-hunt',
  'fab fa-pushed' = 'fab fa-pushed',
  'fas fa-puzzle-piece' = 'fas fa-puzzle-piece',
  'fab fa-python' = 'fab fa-python',
  'fab fa-qq' = 'fab fa-qq',
  'fas fa-qrcode' = 'fas fa-qrcode',
  'fas fa-question' = 'fas fa-question',

  'fab fa-quora' = 'fab fa-quora',
  'fas fa-quote-left' = 'fas fa-quote-left',
  'fas fa-quote-right' = 'fas fa-quote-right',
  'fas fa-random' = 'fas fa-random',
  'fab fa-ravelry' = 'fab fa-ravelry',
  'fab fa-react' = 'fab fa-react',
  'fab fa-rebel' = 'fab fa-rebel',
  'fas fa-recycle' = 'fas fa-recycle',
  'fab fa-red-river' = 'fab fa-red-river',
  'fab fa-reddit' = 'fab fa-reddit',
  'fab fa-reddit-alien' = 'fab fa-reddit-alien',
  'fab fa-reddit-square' = 'fab fa-reddit-square',
  'fas fa-redo' = 'fas fa-redo',
  'fas fa-redo-alt' = 'fas fa-redo-alt',

  'fab fa-rendact' = 'fab fa-rendact',
  'fab fa-renren' = 'fab fa-renren',
  'fas fa-reply' = 'fas fa-reply',
  'fas fa-reply-all' = 'fas fa-reply-all',
  'fab fa-replyd' = 'fab fa-replyd',
  'fab fa-resolving' = 'fab fa-resolving',
  'fas fa-retweet' = 'fas fa-retweet',
  'fas fa-road' = 'fas fa-road',
  'fas fa-rocket' = 'fas fa-rocket',
  'fab fa-rocketchat' = 'fab fa-rocketchat',
  'fab fa-rockrms' = 'fab fa-rockrms',
  'fas fa-rss' = 'fas fa-rss',
  'fas fa-rss-square' = 'fas fa-rss-square',
  'fas fa-ruble-sign' = 'fas fa-ruble-sign',
  'fas fa-rupee-sign' = 'fas fa-rupee-sign',
  'fab fa-safari' = 'fab fa-safari',
  'fab fa-sass' = 'fab fa-sass',

  'fab fa-schlix' = 'fab fa-schlix',
  'fab fa-scribd' = 'fab fa-scribd',
  'fas fa-search' = 'fas fa-search',
  'fas fa-search-minus' = 'fas fa-search-minus',
  'fas fa-search-plus' = 'fas fa-search-plus',
  'fab fa-searchengin' = 'fab fa-searchengin',
  'fab fa-sellcast' = 'fab fa-sellcast',
  'fab fa-sellsy' = 'fab fa-sellsy',
  'fas fa-server' = 'fas fa-server',
  'fab fa-servicestack' = 'fab fa-servicestack',
  'fas fa-share' = 'fas fa-share',
  'fas fa-share-alt' = 'fas fa-share-alt',
  'fas fa-share-alt-square' = 'fas fa-share-alt-square',

  'fas fa-shekel-sign' = 'fas fa-shekel-sign',
  'fas fa-shield-alt' = 'fas fa-shield-alt',
  'fas fa-ship' = 'fas fa-ship',
  'fab fa-shirtsinbulk' = 'fab fa-shirtsinbulk',
  'fas fa-shopping-bag' = 'fas fa-shopping-bag',
  'fas fa-shopping-basket' = 'fas fa-shopping-basket',
  'fas fa-shopping-cart' = 'fas fa-shopping-cart',
  'fas fa-shower' = 'fas fa-shower',
  'fas fa-sign-in-alt' = 'fas fa-sign-in-alt',
  'fas fa-sign-language' = 'fas fa-sign-language',
  'fas fa-sign-out-alt' = 'fas fa-sign-out-alt',
  'fas fa-signal' = 'fas fa-signal',
  'fab fa-simplybuilt' = 'fab fa-simplybuilt',
  'fab fa-sistrix' = 'fab fa-sistrix',
  'fas fa-sitemap' = 'fas fa-sitemap',
  'fab fa-skyatlas' = 'fab fa-skyatlas',
  'fab fa-skype' = 'fab fa-skype',
  'fab fa-slack' = 'fab fa-slack',
  'fab fa-slack-hash' = 'fab fa-slack-hash',
  'fas fa-sliders-h' = 'fas fa-sliders-h',
  'fab fa-slideshare' = 'fab fa-slideshare',

  'fab fa-snapchat' = 'fab fa-snapchat',
  'fab fa-snapchat-ghost' = 'fab fa-snapchat-ghost',
  'fab fa-snapchat-square' = 'fab fa-snapchat-square',

  'fas fa-sort' = 'fas fa-sort',
  'fas fa-sort-alpha-down' = 'fas fa-sort-alpha-down',
  'fas fa-sort-alpha-up' = 'fas fa-sort-alpha-up',
  'fas fa-sort-amount-down' = 'fas fa-sort-amount-down',
  'fas fa-sort-amount-up' = 'fas fa-sort-amount-up',
  'fas fa-sort-down' = 'fas fa-sort-down',
  'fas fa-sort-numeric-down' = 'fas fa-sort-numeric-down',
  'fas fa-sort-numeric-up' = 'fas fa-sort-numeric-up',
  'fas fa-sort-up' = 'fas fa-sort-up',
  'fab fa-soundcloud' = 'fab fa-soundcloud',
  'fas fa-space-shuttle' = 'fas fa-space-shuttle',
  'fab fa-speakap' = 'fab fa-speakap',
  'fas fa-spinner' = 'fas fa-spinner',
  'fab fa-spotify' = 'fab fa-spotify',

  'fab fa-stack-exchange' = 'fab fa-stack-exchange',
  'fab fa-stack-overflow' = 'fab fa-stack-overflow',

  'fab fa-staylinked' = 'fab fa-staylinked',
  'fab fa-steam' = 'fab fa-steam',
  'fab fa-steam-square' = 'fab fa-steam-square',
  'fab fa-steam-symbol' = 'fab fa-steam-symbol',
  'fas fa-step-backward' = 'fas fa-step-backward',
  'fas fa-step-forward' = 'fas fa-step-forward',
  'fas fa-stethoscope' = 'fas fa-stethoscope',
  'fab fa-sticker-mule' = 'fab fa-sticker-mule',

  'fas fa-stop' = 'fas fa-stop',

  'fab fa-strava' = 'fab fa-strava',
  'fas fa-street-view' = 'fas fa-street-view',
  'fas fa-strikethrough' = 'fas fa-strikethrough',
  'fab fa-stripe' = 'fab fa-stripe',
  'fab fa-stripe-s' = 'fab fa-stripe-s',
  'fab fa-studiovinari' = 'fab fa-studiovinari',
  'fab fa-stumbleupon' = 'fab fa-stumbleupon',
  'fab fa-stumbleupon-circle' = 'fab fa-stumbleupon-circle',
  'fas fa-subscript' = 'fas fa-subscript',
  'fas fa-subway' = 'fas fa-subway',
  'fas fa-suitcase' = 'fas fa-suitcase',

  'fab fa-superpowers' = 'fab fa-superpowers',
  'fas fa-superscript' = 'fas fa-superscript',
  'fab fa-supple' = 'fab fa-supple',
  'fas fa-sync' = 'fas fa-sync',
  'fas fa-sync-alt' = 'fas fa-sync-alt',
  'fas fa-table' = 'fas fa-table',
  'fas fa-tablet' = 'fas fa-tablet',
  'fas fa-tablet-alt' = 'fas fa-tablet-alt',
  'fas fa-tachometer-alt' = 'fas fa-tachometer-alt',
  'fas fa-tag' = 'fas fa-tag',
  'fas fa-tags' = 'fas fa-tags',
  'fas fa-tasks' = 'fas fa-tasks',
  'fas fa-taxi' = 'fas fa-taxi',
  'fab fa-telegram' = 'fab fa-telegram',
  'fab fa-telegram-plane' = 'fab fa-telegram-plane',
  'fab fa-tencent-weibo' = 'fab fa-tencent-weibo',
  'fas fa-terminal' = 'fas fa-terminal',
  'fas fa-text-height' = 'fas fa-text-height',
  'fas fa-text-width' = 'fas fa-text-width',
  'fas fa-th' = 'fas fa-th',
  'fas fa-th-large' = 'fas fa-th-large',
  'fas fa-th-list' = 'fas fa-th-list',
  'fab fa-themeisle' = 'fab fa-themeisle',
  'fas fa-thermometer-empty' = 'fas fa-thermometer-empty',
  'fas fa-thermometer-full' = 'fas fa-thermometer-full',
  'fas fa-thermometer-half' = 'fas fa-thermometer-half',
  'fas fa-thermometer-quarter' = 'fas fa-thermometer-quarter',
  'fas fa-thermometer-three-quarters' = 'fas fa-thermometer-three-quarters',

  'fas fa-thumbtack' = 'fas fa-thumbtack',
  'fas fa-ticket-alt' = 'fas fa-ticket-alt',
  'fas fa-times' = 'fas fa-times',

  'fas fa-tint' = 'fas fa-tint',
  'fas fa-toggle-off' = 'fas fa-toggle-off',
  'fas fa-toggle-on' = 'fas fa-toggle-on',
  'fas fa-trademark' = 'fas fa-trademark',
  'fas fa-train' = 'fas fa-train',
  'fas fa-transgender' = 'fas fa-transgender',
  'fas fa-transgender-alt' = 'fas fa-transgender-alt',
  'fas fa-trash' = 'fas fa-trash',

  'fas fa-tree' = 'fas fa-tree',
  'fab fa-trello' = 'fab fa-trello',
  'fab fa-tripadvisor' = 'fab fa-tripadvisor',
  'fas fa-trophy' = 'fas fa-trophy',
  'fas fa-truck' = 'fas fa-truck',
  'fas fa-tty' = 'fas fa-tty',
  'fab fa-tumblr' = 'fab fa-tumblr',
  'fab fa-tumblr-square' = 'fab fa-tumblr-square',
  'fas fa-tv' = 'fas fa-tv',
  'fab fa-twitch' = 'fab fa-twitch',
  'fab fa-twitter' = 'fab fa-twitter',
  'fab fa-twitter-square' = 'fab fa-twitter-square',
  'fab fa-typo3' = 'fab fa-typo3',
  'fab fa-uber' = 'fab fa-uber',
  'fab fa-uikit' = 'fab fa-uikit',
  'fas fa-umbrella' = 'fas fa-umbrella',
  'fas fa-underline' = 'fas fa-underline',
  'fas fa-undo' = 'fas fa-undo',
  'fas fa-undo-alt' = 'fas fa-undo-alt',
  'fab fa-uniregistry' = 'fab fa-uniregistry',
  'fas fa-universal-access' = 'fas fa-universal-access',
  'fas fa-university' = 'fas fa-university',
  'fas fa-unlink' = 'fas fa-unlink',
  'fas fa-unlock' = 'fas fa-unlock',
  'fas fa-unlock-alt' = 'fas fa-unlock-alt',
  'fab fa-untappd' = 'fab fa-untappd',
  'fas fa-upload' = 'fas fa-upload',
  'fab fa-usb' = 'fab fa-usb',

  'fas fa-user-md' = 'fas fa-user-md',
  'fas fa-user-plus' = 'fas fa-user-plus',
  'fas fa-user-secret' = 'fas fa-user-secret',
  'fas fa-user-times' = 'fas fa-user-times',
  'fas fa-users' = 'fas fa-users',
  'fab fa-ussunnah' = 'fab fa-ussunnah',
  'fas fa-utensil-spoon' = 'fas fa-utensil-spoon',
  'fas fa-utensils' = 'fas fa-utensils',
  'fab fa-vaadin' = 'fab fa-vaadin',
  'fas fa-venus' = 'fas fa-venus',
  'fas fa-venus-double' = 'fas fa-venus-double',
  'fas fa-venus-mars' = 'fas fa-venus-mars',
  'fab fa-viacoin' = 'fab fa-viacoin',
  'fab fa-viadeo' = 'fab fa-viadeo',
  'fab fa-viadeo-square' = 'fab fa-viadeo-square',
  'fab fa-viber' = 'fab fa-viber',
  'fas fa-video' = 'fas fa-video',
  'fab fa-vimeo' = 'fab fa-vimeo',
  'fab fa-vimeo-square' = 'fab fa-vimeo-square',
  'fab fa-vimeo-v' = 'fab fa-vimeo-v',
  'fab fa-vine' = 'fab fa-vine',
  'fab fa-vk' = 'fab fa-vk',
  'fab fa-vnv' = 'fab fa-vnv',
  'fas fa-volume-down' = 'fas fa-volume-down',
  'fas fa-volume-off' = 'fas fa-volume-off',
  'fas fa-volume-up' = 'fas fa-volume-up',
  'fab fa-vuejs' = 'fab fa-vuejs',
  'fab fa-weibo' = 'fab fa-weibo',
  'fab fa-weixin' = 'fab fa-weixin',
  'fab fa-whatsapp' = 'fab fa-whatsapp',
  'fab fa-whatsapp-square' = 'fab fa-whatsapp-square',
  'fas fa-wheelchair' = 'fas fa-wheelchair',
  'fab fa-whmcs' = 'fab fa-whmcs',
  'fas fa-wifi' = 'fas fa-wifi',
  'fab fa-wikipedia-w' = 'fab fa-wikipedia-w',

  'fas fa-window-minimize' = 'fas fa-window-minimize',

  'fab fa-windows' = 'fab fa-windows',
  'fas fa-won-sign' = 'fas fa-won-sign',
  'fab fa-wordpress' = 'fab fa-wordpress',
  'fab fa-wordpress-simple' = 'fab fa-wordpress-simple',
  'fab fa-wpbeginner' = 'fab fa-wpbeginner',
  'fab fa-wpexplorer' = 'fab fa-wpexplorer',
  'fab fa-wpforms' = 'fab fa-wpforms',
  'fas fa-wrench' = 'fas fa-wrench',
  'fab fa-xbox' = 'fab fa-xbox',
  'fab fa-xing' = 'fab fa-xing',
  'fab fa-xing-square' = 'fab fa-xing-square',
  'fab fa-y-combinator' = 'fab fa-y-combinator',
  'fab fa-yahoo' = 'fab fa-yahoo',
  'fab fa-yandex' = 'fab fa-yandex',
  'fab fa-yandex-international' = 'fab fa-yandex-international',
  'fab fa-yelp' = 'fab fa-yelp',
  'fas fa-yen-sign' = 'fas fa-yen-sign',
  'fab fa-yoast' = 'fab fa-yoast',
  'fab fa-youtube' = 'fab fa-youtube',
}
