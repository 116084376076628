import { useEffect, useState } from 'react';
import DataBox from '../form/DataBox';
import clsx from 'clsx';
import MerchantService from '../../services/MerchantService';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import CopyToClipboard from 'react-copy-to-clipboard';
import { Trans } from '@lingui/macro';
import { useQuery } from 'react-query';

const ApiKeySetting = () => {
  const [copy, setCopy] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ['merchant-api-key'],
    queryFn: () => MerchantService.getApikey(),
  });

  useEffect(() => {
    const timeout = setTimeout(() => setCopy(false), 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <DataBox value={data?.apiKey} secret={true} loadData={refetch}>
      <OverlayTrigger
        overlay={
          <Tooltip id="tooltip-copy-to-clipboard">
            <Trans>Copy</Trans>
          </Tooltip>
        }
      >
        <CopyToClipboard
          text={data?.apiKey}
          onCopy={() => {
            refetch();
            setCopy(true);
          }}
        >
          <Button variant="secondary">
            <i
              className={clsx('cursor-pointer fs-4 mw-15px', {
                'fas fa-check text-success': copy,
                'far fa-copy': !copy,
              })}
            />
          </Button>
        </CopyToClipboard>
      </OverlayTrigger>
    </DataBox>
  );
};

export default ApiKeySetting;
