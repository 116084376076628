export enum Chains {
  BINANCE = 'BINANCE',
  POLYGON = 'POLYGON',
  VCHAIN = 'VCHAIN',
  TRON = 'TRON',
}

export enum Contracts {
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
  VIC = 'VIC',
}

export interface INetworks {
  BINANCE: INetwork;
  POLYGON: INetwork;
}
export interface Token {
  address: string;
  chainId: number;
  chainName: string;
  decimals: number;
  name: string;
  native: boolean;
  owner: boolean;
  symbol: string;
  type: string;
}

export interface ISelectToken {
  selectToken: Token;
  onSelected(token: Token): void;
  isshow?: boolean;
  chainId: number;
  markTokenSymbol?: string;
}

export interface INetwork {
  active: boolean;
  chainId: number;
  provider: Provider;
  contracts: {
    PAYMENT_GATEWAY: {
      address: string;
    };
    VIC: {
      address: string;
    };
  };
  tokens: Token[];
}

interface Provider {
  endpoint: string;
  eip1559Supported: boolean;
  explorer: string;
  apiEndpoint: string;
  coinSymbol: string;
  coinDecimals: number;
}

interface Chain {
  apiEndpoint: string;
  code: string;
  coinDecimals: number;
  coinName: string;
  coinSymbol: string;
  eip1559Supported: boolean;
  endpoint: string;
  publicEndpoint: string;
  explorer: string;
  id: number;
  logoUrl: string;
  name: string;
  platform: string;
}

export interface IChain extends Chain {
  tokens: Token[];
}

export interface ITokenChain extends Chain {
  token: Token;
}
